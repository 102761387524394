import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MainSectionProps } from ".."
import useManageVendorCustomer from "../../../../utility/custom-hooks/useManageVendorCustomer";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../../context/editVendorCustomerContext";
import _ from "lodash";
import { Box, Checkbox, IconButton, InputAdornment, LinearProgress, Link, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import SearchIcon from '@mui/icons-material/Search';
import { DragIndicator } from "@mui/icons-material";
import NewTag from "../../../../components/common/new-tag";
import ConfirmModal from "../../../../components/modals/confirm-modal";
import VendorRow from "./vendor-row";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import { clearNewVendorsByIds } from "../../../../utility/helper";
import NoDataPlaceholder from "../../../../components/common/no-data-placeholder";

// Interfaces
export interface VendorsSectionProps extends MainSectionProps {

}

// Constants
const searchFilters = ['All', 'New'];

const VendorsSection = (props: VendorsSectionProps) => {
  // Contexts
  const {
    setToaster
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const {
    clearNewVendorsInCustomersList,
    clearNewVendorsInVendorsList
  } = useManageVendorCustomer();

  const {
    customersList,
    setCustomersList,
    filteredCustomersList,
    setFilteredCustomersList,
    vendorsList,
    setVendorsList,
    filteredVendorsList,
    setFilteredVendorsList,
    isFetching,
    triggerResetSearch,
    triggerResetSelection,
    setTriggerResetSelection,
    setSelectedVendors,
    initialVendors,
    setInitialVendors
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // States
  const [selectedSearch, setSelectedSearch] = useState<string>(searchFilters[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [triggerSelectAll, setTriggerSelectAll] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);
  
  // Effects
  useEffect(() => {
    setSelectedSearch(searchFilters[0]);
    setSearchValue('');
    setPage(0);
    setIsSelectedAll(false);
  }, [triggerResetSearch]);

  // Memos
  const rowsPerPage = useMemo(() => 10, []);

  const hasMore = useMemo(() => {
    return filteredVendorsList.slice(0, page * rowsPerPage + rowsPerPage).length < filteredVendorsList.length;
  }, [filteredVendorsList, page, rowsPerPage]);

  // Callbacks
  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    // Current implementation does not cover this branch on any cases
    // Will comment out for future references:
    // if (isFetching) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [hasMore]); // remove isFetching from dependency

  // Functions
  const triggerSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleResetSelection();
    setSearchValue(event.target.value);

    let debounce = _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleSearch(selectedSearch, event.target.value);
    }, 500);
    debounce(event);
  };

  const handleSearch = (selectedSearch: string, searchValue: string) => {
    const loweredSearchValue = searchValue.toLowerCase().trim();
    if (loweredSearchValue === '') {
      if (selectedSearch === 'All') {
        setFilteredVendorsList(vendorsList);
      } else {
        const filtered = vendorsList.filter(vendor => vendor.isNew);
        setFilteredVendorsList(filtered);
      }
    } else {
      const filtered = filterVendors(selectedSearch, loweredSearchValue);
      setFilteredVendorsList(filtered);
    }
  }

  const filterVendors = (selectedSearch: string, loweredSearchValue: string) => {
    if (selectedSearch === 'All') {
      return vendorsList.filter(vendor => 
        vendor.vendorName?.toLowerCase().includes(loweredSearchValue) ||
        vendor.vendorSrcId?.toLowerCase().includes(loweredSearchValue)
      )
    } else {
      return vendorsList.filter(vendor => vendor.isNew && (
        vendor.vendorName?.toLowerCase().includes(loweredSearchValue) ||
        vendor.vendorSrcId?.toLowerCase().includes(loweredSearchValue)
      ))
    }
  }

  const handleChangeFilter = (event: SelectChangeEvent<string>) => {
    handleResetSelection();
    const selected = event.target.value;
    setSelectedSearch(selected);
    handleSearch(selected, searchValue);
  }

  const handleSelectAll = () => {
    // isSelectedAll is the current value
    // Remove the selection if previously selected before onClick

    if (isSelectedAll) {
      // Remove vendors
      setSelectedVendors([]);
    } else {
      // Add all vendors
      setSelectedVendors(filteredVendorsList);
    }
  }

  const handleClearNewVendors = async () => {
    try {
      const allVendorIds: number[] = initialVendors.map(vendor => vendor.recordId!);
      await clearNewVendorsByIds(allVendorIds);

      const clearedVendors = initialVendors.map(vendor => ({
        ...vendor,
        isNew: false,
      }))

      setInitialVendors(clearedVendors);
      setToaster({ open: true, message: 'Successfully cleared all vendors!', severity: 'success' });
      
      // Updating the display
      // Customers List
      setCustomersList(clearNewVendorsInCustomersList(customersList));
      setFilteredCustomersList(clearNewVendorsInCustomersList(filteredCustomersList));

      // Vendors List
      setVendorsList(clearNewVendorsInVendorsList(vendorsList));
      setFilteredVendorsList(clearNewVendorsInVendorsList(filteredVendorsList));
    } catch (error) {
      console.log('CLEAR ALL VENDORS ERROR: ', error);
      setToaster({ open: true, message: 'Failed to clear new vendors!', severity: 'error' });
    }
  }

  const handleResetSelection = () => {
    setIsSelectedAll(false);
    setTriggerResetSelection(!triggerResetSelection);
    setSelectedVendors([]);
  }

  // Rendering the component
  const getTableBody = () => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell
            sx={styles.linearProgressCell}
            colSpan={4}
          >
            <LinearProgress />
          </TableCell>
        </TableRow>
      )
    } else if (filteredVendorsList.length) {
      return (
        [...filteredVendorsList]
          .sort((a, b) =>
            a.vendorName!.toLowerCase().localeCompare(b.vendorName!.toLowerCase())
          )
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((value, index, array) => {
            const lastRow = array.length === index + 1;
            
            return (
              <VendorRow
                {...props}
                key={value.vendorSrcId}
                index={index}
                unlinkedVendor={value}
                isSelectedAll={isSelectedAll}
                setIsSelectedAll={setIsSelectedAll}
                triggerSelectAll={triggerSelectAll}
                lastRow={lastRow}
                lastRowElementRef={lastRowElementRef}
              />
            )
          })
      )
    } else {
      return (
        <TableRow>
          <TableCell
            sx={styles.emptyVerbiageCell}
            colSpan={4}
          >
            <NoDataPlaceholder
              messageText='No Vendor List'
              messageContainerStyle={styles.emptyVerbiageContainer}
            />
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <Box
      component={Paper}
      sx={styles.orphansSectionContainer}
    >
      <Box sx={styles.clearButtonBox}>
        <Link 
          component='button'
          type='button'
          sx={styles.clearButton}
          onClick={() => {
            setClearModalOpen(true);
          }}
        >
          Clear All New Vendors
        </Link>
      </Box>
      
      {/* Search bar */}
      <Box sx={styles.searchContainer}>
        <Select
          id='filter-dropdown-vendor-list'
          value={selectedSearch}
          onChange={handleChangeFilter}
          sx={styles.filterSelector}
          inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
          data-testid='search-dropdown-vendor-list'
        >
          {searchFilters.map(filter => (
            <MenuItem
              key={filter}
              value={filter}
            >
              {filter}
            </MenuItem>
          ))}
        </Select>
        <TextField
          inputProps={{ 'aria-label': 'Search-Textfield' }}
          id='search-vendor-list'
          data-testid='search-field-vendor-list'
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                tabIndex={0}
                aria-label='Search icon'
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={triggerSearch}
          placeholder='Search'
          size='small'
          sx={styles.searchField}
        />
      </Box>

      {/* Main Table */}
      <TableContainer
        sx={styles.sectionTableContainer}
      >
        <Table stickyHeader>

          {/* Headers */}
          <TableHead sx={styles.sectionTableHead}>
            <TableRow sx={styles.sectionTableHeaderRow}>
              <TableCell sx={styles.selectAndActionTableCell}>
                <Box sx={styles.selectAndActionContainer}>
                  <IconButton
                    draggable
                    edge='end'
                    aria-label='Drag and drop icon'
                    sx={styles.dragButton}
                  >
                    <DragIndicator/>
                  </IconButton>
                  <Tooltip title='Select All'>
                    <Checkbox
                      id='checkbox'
                      data-testid='select-all-checkbox'
                      aria-label='Checkbox'
                      aria-labelledby='checkbox'
                      edge='end'
                      inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
                      checked={isSelectedAll && filteredVendorsList.length > 0}
                      disableRipple
                      onClick={() => {
                        setIsSelectedAll(!isSelectedAll);
                        setTriggerSelectAll(!triggerSelectAll);
                        handleSelectAll();
                      }}
                      sx={styles.checkbox}
                    />
                  </Tooltip>
                </Box>
              </TableCell>

              
              <TableCell sx={styles.orphanNameHeaderCell}>
                <Box sx={styles.nameAndTagContainer}>
                  <NewTag additionalStyles={{ visibility: 'hidden'}}/>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                    Vendor Name
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={styles.orphanArAmountHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  AP Amount
                </Typography>
              </TableCell>
              <TableCell sx={styles.dateCreatedTableCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'center' }}>
                  Date Created
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Spacer */}
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>

          {/* Orphan List */}
          <TableBody>
            {getTableBody()}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmModal
        title='Clear All New Vendors'
        description='Are you sure you want to clear all new added vendors?'
        open={clearModalOpen}
        onClose={() => {
          setClearModalOpen(false);
        }}
        onConfirm={() => {
          handleClearNewVendors();
        }}
        yesButtonText='Clear'
        noButtonText='Cancel'
      />
    </Box>
  )
}

export default VendorsSection