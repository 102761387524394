import colors from "../../../../../../utility/colors";

const styles = {
  tableDataDragAndActionCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
  },
  tableDataDragContainer: {
    display: 'flex'
  },
  noTooltip: {
    zIndex: -99999,
    visibility: 'hidden',
  },
  dragButton: {
    p: '0.1rem',
  },
  tableDataNameCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'left'
  },
  tableDataNameBox: {
    display: 'flex', 
    flexDirection: 'column',
  },
  tableDataArAmountCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'right',
  },
  tableDataTypography: {
    fontSize: '14px',
    color: 'inherit', 
  },
  removeButton: {
    p: '0.2rem',
    color: colors.PRIMARY
  },
  ghostImageContainer: {
    position: 'absolute',
    top: '-1000px',
    left: '-1000px',
    zIndex: '9999',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    gap: '2px',
  },
  ghostImage: {
    backgroundColor: colors.PRIMARY,
    borderRadius: '20px',
    padding: '4px 8px',
    color: '#fff',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    maxWidth: '180px',
  }
};
export default styles;