import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import styles from './styles';
import { formatCurrency, isOddNumber } from '../../../../../utility/helper';
import { useContext, useEffect, useMemo, useState, KeyboardEvent, FocusEvent, useRef } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { IParentWithChild, ISelectedParentAndChild } from '../../../../../interfaces/editParentChildInterface';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChildRow from './child-row';
import { ParentsSectionProps } from '..';
import { IARCustomer } from '../../../../../interfaces';
import { EditParentChildContext, IEditParentChildContext } from '../../../../../context/editParentChildContext';
import useManageParentChild from '../../../../../utility/custom-hooks/useManageParentChild';
import { FormikProps } from 'formik';

// Interfaces
export interface ParentRowProps extends ParentsSectionProps {
  index: number;
  parentWithChild: IParentWithChild;
  lastRow: boolean;
  lastRowElementRef: (node: any) => void;
  createUpdatedCustomerList: (
    initialCustomers: IARCustomer[],
    formik: FormikProps<{
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }>
  ) => void;
}

// Functions
const getParentNameToolTip = (parentWithChild: IParentWithChild) => {
  const custName = parentWithChild.custName;
  const custSrcId = parentWithChild.custSrcId;

  if (custName.length >= 37) {
    return (
      <Tooltip
        title={custName}
        placement='bottom-start'
      >
        <>
        <Box sx={styles.parentNameContainer}>
          <Typography
            tabIndex={0}
            id={custSrcId}
            sx={styles.custName}
          >
            {custName}
          </Typography>
          <Typography
            tabIndex={0}
            sx={styles.custName}
          >
            {` (${parentWithChild.childCustomers.length})`}
          </Typography>
        </Box>
        <Typography
          tabIndex={0}
          sx={styles.custSrcId}
        >
          {`Customer ID: ${custSrcId}`}
        </Typography>
        </>
      </Tooltip>
    );
  } else {
    return (
      <>
      <Box sx={styles.parentNameContainer}>
        <Typography
          tabIndex={0}
          id={custSrcId}
          sx={styles.custName}
        >
          {custName}
        </Typography>
        <Typography
          tabIndex={0}
          sx={styles.custName}
        >
          {` (${parentWithChild.childCustomers.length})`}
        </Typography>
      </Box>
      <Typography
        tabIndex={0}
        sx={styles.custSrcId}
      >
        {`Customer ID: ${custSrcId}`}
      </Typography>
      </>
    )
  }
}

const ParentRow = (props: ParentRowProps) => {
  const {
    formik,
    index,
    parentWithChild,
    lastRow,
    lastRowElementRef,
    createUpdatedCustomerList,
    isUltimateParent
  } = props;

  // Contexts
  const {
    initialCustomers,
    parentsList,
    setParentsList,
    filteredParentsList,
    setFilteredParentsList,
    orphansList,
    setOrphansList,
    filteredOrphansList,
    setFilteredOrphansList,
    selectedParentAndChild,
    setVendorConfirmModalOpen,
    setLastChildConfirmModalOpen,
    setDroppedInParent,
    setSelectedCustomers,
    setDeleteParentModalOpen,
    setSelectedParentAndChild,
    triggerResetSearch,
    updatedCustomerList,
    setUpdatedCustomerList,
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  // Refs
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // Hooks
  const { addChild, removeOrphans, replaceChild, renameParent } = useManageParentChild();

  // States
  const [openChildList, setOpenChildList] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [editParentName, setEditParentName] = useState<boolean>(false);
  const [isWhiteSpace, setIsWhiteSpace] = useState<boolean>(false);
  const [isUnique, setIsUnique] = useState<boolean>(true);
  
  // Memos
  const totalArAmount = useMemo(() => 
    parentWithChild.childCustomers.reduce((prevValue, customer) =>
      prevValue + (customer.arAmount ?? 0), 0.0)
  , [parentWithChild.childCustomers]);

  // Effects
  useEffect(() => {
    setEditParentName(false);
    setOpenChildList(false);
  }, [triggerResetSearch])

  useEffect(() => {
    if (editParentName) {
      createUpdatedCustomerList(initialCustomers, formik);
    } else {
      setUpdatedCustomerList([]);
    }
  }, [editParentName])

  useEffect(() => {
    if (editParentName && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [editParentName]);

  // Functions
  const handleOnDrop = () => {
    if (selectedParentAndChild === null) return;
    const { selectedParent, selectedChildren } = selectedParentAndChild;
    const newParent = parentWithChild;

    if (selectedParent === null) {
      handleOrphanDrop(selectedChildren, newParent, selectedParentAndChild);
    } else if (selectedParent.custSrcId !== newParent.custSrcId) {
      handleChildDrop(selectedParent, selectedChildren, newParent, selectedParentAndChild)
    } else {
      return;
    }
  }

  const handleOrphanDrop = (
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild,
    selectedParentAndChild: ISelectedParentAndChild,
  ) => {
    // Check if some children has vendorsLinked
    if (selectedChildren.some(child => child.hasVendorsLinked)) {
      setDroppedInParent(newParent);
      setVendorConfirmModalOpen(true);
    } else {
      // Add orphan to customersToEdit
      const updatedCustomers = updateCustomersToEdit(selectedChildren, newParent, formik.values.customersToEdit);
      formik.setFieldValue('customersToEdit', updatedCustomers);

      // Update the parent display
      setParentsList(addChild(parentsList, newParent, selectedParentAndChild));
      setFilteredParentsList(addChild(filteredParentsList, newParent, selectedParentAndChild));

      // Update the orphan display
      setOrphansList(removeOrphans(orphansList, selectedParentAndChild));
      setFilteredOrphansList(removeOrphans(filteredOrphansList, selectedParentAndChild));
      setSelectedCustomers([]);
    }
  }

  const handleChildDrop = (
    selectedParent: IParentWithChild,
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild,
    selectedParentAndChild: ISelectedParentAndChild,
  ) => {
    // Check if last child
    if (selectedParent.childCustomers.length < 2) {
      setDroppedInParent(newParent);
      setLastChildConfirmModalOpen(true);
    } else if (selectedChildren.some(child => child.hasVendorsLinked)) {
      setDroppedInParent(newParent);
      setVendorConfirmModalOpen(true);
    } else {
      // Updating customersToEdit
      const updatedCustomers = updateCustomersToEdit(selectedChildren, newParent, formik.values.customersToEdit);
      formik.setFieldValue('customersToEdit', updatedCustomers);

      // Updating the parentsList
      setParentsList(replaceChild(parentsList, newParent, selectedParentAndChild));
      
      // Updating the filteredParentsList
      setFilteredParentsList(replaceChild(filteredParentsList, newParent, selectedParentAndChild));
    }
  }

  const updateCustomersToEdit = (
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild,
    currentCustomersToEdit: IARCustomer[],
  ) => {
    const updatedCustomers = [...currentCustomersToEdit];

    for (const selectedChild of selectedChildren) {
      const existingCustomerIndex = updatedCustomers.findIndex(
        (customer) => customer.recordId === selectedChild.recordId
      );

      if (existingCustomerIndex !== -1) {
        updatedCustomers[existingCustomerIndex] = {
          ...updatedCustomers[existingCustomerIndex],
          parentARCustomerId: undefined,
          parentCustSrcId: isUltimateParent ? undefined : newParent.custSrcId,
          parentCustName: isUltimateParent ? undefined : newParent.custName,
          upcParentCustId: undefined,
          upcParentCustSrcId: isUltimateParent ? newParent.custSrcId : undefined,
          upcParentCustName: isUltimateParent ? newParent.custName : undefined,
        };
      } else {
        const addedCustomer: IARCustomer = {
          ...selectedChild,
          parentARCustomerId: undefined,
          parentCustSrcId: isUltimateParent ? undefined : newParent.custSrcId,
          parentCustName: isUltimateParent ? undefined : newParent.custName,
          upcParentCustId: undefined,
          upcParentCustSrcId: isUltimateParent ? newParent.custSrcId : undefined,
          upcParentCustName: isUltimateParent ? newParent.custName : undefined,
        };
        updatedCustomers.push(addedCustomer);
      }
    }

    return updatedCustomers;
  }

  const getRenameParentComponent = () => {
    return (
      <TextField
        inputRef={textFieldRef}
        inputProps={{
          'aria-label': 'parent-name-textfield',
          'data-testid': `parent-name-textfield-${parentWithChild.custName.toLowerCase()}`
        }}
        defaultValue={parentWithChild.custName}
        placeholder='Parent Name'
        size='small'
        onChange={(event) => {
          const value = event.target.value;

          if (!value || value.trim() === '') {
            setIsWhiteSpace(true);
          } else if (checkForDuplicateNames(value, updatedCustomerList)) {
            setIsWhiteSpace(false);
            setIsUnique(false);
          } else {
            setIsWhiteSpace(false);
            setIsUnique(true);
          }
        }}
        onKeyDown={(event) => {
          handleParentFieldKeyDown(event);
        }}
        onBlur={(event) => {
          handleBlur(event);
        }}
        sx={styles.renameParent}
        error={!isUnique || isWhiteSpace}
        helperText={getHelperText()}
      />
    )
  }

  const getHelperText = () => {
    if (isWhiteSpace) {
      return 'Parent name should not be empty';
    } else if (!isUnique) {
      return 'Parent name already exist';
    } else {
      return undefined;
    }
  }

  const checkForDuplicateNames = (value: string, updatedCustomerList: IARCustomer[]) => {
    return updatedCustomerList.some(customer =>
      customer.custName?.toLowerCase() === value.trim().toLowerCase() &&
      customer.custName !== parentWithChild.custName
    );
  }

  const handleParentFieldKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape') {
      const value = (event.target as HTMLInputElement).value.trim();
      const defaultValue = (event.target as HTMLInputElement).defaultValue;
      checkValidityOfName(value, defaultValue);
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const value = (event.target as HTMLInputElement).value.trim();
    const defaultValue = (event.target as HTMLInputElement).defaultValue;
    checkValidityOfName(value, defaultValue);
  }

  const checkValidityOfName = (value: string, defaultValue: string) => {
    const sameValue = value === defaultValue;

    if (sameValue || isWhiteSpace || !isUnique) {
      setEditParentName(false);
      setIsWhiteSpace(false);
      setIsUnique(true);
      setSelectedParentAndChild(null);
    } else {
      handleRenameParent(value.trim());
      setEditParentName(false);
      setIsWhiteSpace(false);
      setIsUnique(true);
      setSelectedParentAndChild(null);
    }
  }

  const handleRenameParent = (newParentName: string) => {
    const { selectedParent, selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;
    
    const currentParentCustomersToSave = [...formik.values.parentCustomersToSave];
    const currentCustomersToEdit = [...formik.values.customersToEdit];

    const existingParentToSaveIndex = currentParentCustomersToSave.findIndex(customer =>
      customer.custSrcId === selectedParent?.custSrcId
    );

    const renamedParent: IParentWithChild = {
      ...selectedParent!,
      custName: newParentName,
    }

    if (existingParentToSaveIndex !== -1) {
      // Rename Parent
      currentParentCustomersToSave[existingParentToSaveIndex] = {
        ...currentParentCustomersToSave[existingParentToSaveIndex],
        custName: newParentName
      };
    } else {
      const existingParentToEditIndex = currentCustomersToEdit.findIndex(customer =>
        customer.custSrcId === selectedParent?.custSrcId
      );

      if (existingParentToEditIndex !== -1) {
        // Rename Parent
        currentCustomersToEdit[existingParentToEditIndex] = {
          ...currentCustomersToEdit[existingParentToEditIndex],
          custName: newParentName
        };
      } else {
        // Add Parent to Customers To Edit
        const customerRecordOfParent = initialCustomers.find(customer =>
          customer.custSrcId === renamedParent.custSrcId
        );

        if (customerRecordOfParent) {
          currentCustomersToEdit.push({
            ...customerRecordOfParent,
            custName: newParentName
          });
        }
      }
    }
    
    
    const updatedCustomers = updateCustomersToEdit(
      selectedChildren,
      renamedParent,
      currentCustomersToEdit
    );

    formik.setFieldValue('customersToEdit', updatedCustomers);
    formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSave);

    // Updating the Display
    setParentsList(renameParent(parentsList, renamedParent, selectedParent));
    setFilteredParentsList(renameParent(filteredParentsList, renamedParent, selectedParent));
  }

  // Rendering the Component
  return (
    <>
    {/* Parent Row */}
    <TableRow
      sx={{
        ...styles.parentRow,
        bgcolor: isOddNumber(index) ?
          'background.paper' :
          '#F7F7F7'
      }}
      onClick={() => setOpenChildList(!openChildList)}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`parent-row-${index}`}
      ref={lastRow ? lastRowElementRef : undefined}
    >
      <TableCell sx={styles.parentSelectAndActionCell}>
        <Box sx={styles.arrowContainer}>
          {openChildList ? 
            <KeyboardArrowUp sx={styles.arrow} /> :
            <KeyboardArrowDown sx={styles.arrow} />}
        </Box>
      </TableCell>
      <TableCell sx={styles.parentNameCell}>
        {editParentName ? getRenameParentComponent() :
          getParentNameToolTip(parentWithChild)}
      </TableCell>
      <TableCell sx={styles.parentArAmountCell}>
        {totalArAmount ? formatCurrency(totalArAmount) : '-'}
      </TableCell>
      <TableCell sx={styles.parentSelectAndActionCell}>
        <IconButton
          disableRipple
          aria-label='Actions Icon'
          aria-controls='parent-menu'
          aria-haspopup='true'
          data-testid={`parent-menu-button-${parentWithChild.custSrcId}`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          sx={styles.iconButtonProperties}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='parent-menu'
          data-testid={`parent-menu-${parentWithChild.custSrcId}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            data-testid={`parent-menu-item-rename`} 
            onClick={(event) => {
              event.stopPropagation();
              setEditParentName(true);
              setSelectedParentAndChild({
                selectedParent: parentWithChild,
                selectedChildren: parentWithChild.childCustomers
              });
              setAnchorEl(null);
            }}
          >
            Rename
          </MenuItem>
          {parentWithChild.hasVendorsLinked ? 
          (
            <Tooltip title={'Cannot delete Parent Customer with associated Vendor/s'}>
              <span>
                <MenuItem 
                  data-testid='parent-menu-item-delete'
                  disabled
                >
                  Delete
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              data-testid='parent-menu-item-delete'
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(null);
                setSelectedParentAndChild({
                  selectedParent: parentWithChild,
                  selectedChildren: parentWithChild.childCustomers
                });
                setDeleteParentModalOpen(true);
              }}
              disabled={parentWithChild.hasVendorsLinked}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>

    {/* Child Table Row Component */}
    {openChildList &&
      <>
      <TableRow>
        <TableCell sx={styles.childrenHeaderSelectAndActionCell}></TableCell>
        <TableCell sx={styles.childrenHeaderNameCell}>
          <Typography sx={styles.childrenHeaderTypography}>
            Accounts Name
          </Typography>
        </TableCell>
        <TableCell sx={styles.childrenHeaderArAmountCell}>
          <Typography sx={styles.childrenHeaderTypography}>
            Accounts Amount
          </Typography>
        </TableCell>
        <TableCell sx={styles.childrenHeaderSelectAndActionCell}>
        </TableCell>
      </TableRow>
      {[...parentWithChild.childCustomers]
        .sort((a, b) =>
          (a.custName as string).toLowerCase().localeCompare((b.custName as string).toLowerCase())
        )
        .map((value, index) => (
          <ChildRow
            {...props}
            key={value.custSrcId}
            index={index}
            childCustomer={value}
            handleOnDrop={handleOnDrop}
          />
        ))}
      </>
    }
    </>
  )
}

export default ParentRow;