import * as Contract from '@tableau/api-external-contract-js';
import { DataType, FieldAggregationType, FieldRoleType } from '@tableau/api-external-contract-js';
import * as InternalContract from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';

export class FieldImpl {
  public constructor(private _fieldInfo: InternalContract.Field, private _parentDataSource?: Contract.DataSource) {}

  public get name(): string {
    return this._fieldInfo.name;
  }

  public get id(): string {
    return this._fieldInfo.id;
  }

  public get description(): string | undefined {
    return this._fieldInfo.description;
  }

  public get dataType(): DataType {
    return this._fieldInfo.dataType ?? DataType.Unknown;
  }

  public get aggregation(): FieldAggregationType {
    return InternalToExternalEnumMappings.fieldAggregationType.convert(this._fieldInfo.aggregation);
  }

  public get dataSource(): Contract.DataSource {
    if (!this._parentDataSource)
      throw new Error(
        `Data source information is not available for this field. Please use getFieldAsync method to get field's data source information`,
      );
    return this._parentDataSource;
  }

  public get role(): FieldRoleType {
    return InternalToExternalEnumMappings.fieldRoleType.convert(this._fieldInfo.role);
  }

  public get isHidden(): boolean {
    return this._fieldInfo.isHidden;
  }

  public get isGenerated(): boolean {
    return this._fieldInfo.isGenerated;
  }

  public get isCalculatedField(): boolean {
    return this._fieldInfo.isCalculatedField;
  }

  public get isCombinedField(): boolean {
    return this._fieldInfo.isCombinedField;
  }
}
