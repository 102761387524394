import colors from "../../../../utility/colors";

const styles = {
  orphansSectionContainer: {
    width: '50%',
    height: '38rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  clearButton: {
    color: colors.PRIMARY_LIGHT,
    fontSize: '14px',
  },
  clearButtonBox: {
    height: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  searchContainer: {
    display: 'flex',
  },
  filterSelector: {
    width: '30%',
    borderRadius: "4px 0px 0px 4px",
    backgroundColor: colors.WHITE,
    height: '2.5rem'
  },
  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
      borderRadius: "0px 4px 4px 0px",
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '2.5rem',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width: '70%',
  },
  sectionTableContainer: {
    maxHeight: '85%',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
    marginTop: '1rem',
  },
  sectionTableHead: {
    marginBottom: '1rem',
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F7F7',
    }
  },
  sectionTableHeaderRow: {
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7',
  },
  selectAndActionTableCell: {
    width: '10%',
  },
  orphanNameHeaderCell: {
    width: '35%',
  },
  orphanArAmountHeaderCell: {
    width: '35%',
  },
  dateCreatedTableCell: {
    width: '20%',
  },
  sectionTableHeaderText: {
    color: 'inherit', 
    fontWeight: 'bold', 
    fontSize: '0.875rem',
    whiteSpace: 'nowrap'
  },
  tableSpacer: {
    height: '1rem',
  },
  checkbox: {
    p: 0
  },
  dragButton: {
    visibility: 'hidden',
    p: '0.1rem',
  },
  selectAndActionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '1rem'
  },
  tooltip: {
    visibility: 'hidden'
  },
  nameAndTagContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linearProgressCell: {
    padding: 0,
    borderBottom: 0,
  },
  emptyVerbiageCell: {
    padding: 0,
    borderBottom: 0,
  },
  emptyVerbiageContainer: {
    paddingY: '13.1rem'
  },
};
export default styles;