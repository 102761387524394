import * as Yup from 'yup';
import { IARCustomer } from '../interfaces';

const customerSchema = (
  updatedCustomerList: IARCustomer[],
  createCustomer?: boolean,
  isUltimateParent?: boolean,
) => {
  const { usedCustNames, usedCustSrcIds } = updatedCustomerList.reduce((prevValue, customer) => {
    prevValue.usedCustNames.push(customer.custName!.toLowerCase());
    prevValue.usedCustSrcIds.push(customer.custSrcId!.toLowerCase());
    return prevValue;
  }, {
    usedCustNames: [] as string[],
    usedCustSrcIds: [] as string[],
  });

  const schemaForCreatingUpcParent = Yup.object({
    upcParentCustSrcId: Yup
      .string()
      .required('Parent ID is required')
      .trim()
      .test('unique', 'Parent ID must be unique', (value) =>
        value ? !usedCustSrcIds.includes(value.toLowerCase()) : true
      ),
    upcParentCustName: Yup
      .string()
      .required('Parent Name is required')
      .trim()
      .test('unique', 'Parent Name must be unique', (value) =>
        value ? !usedCustNames.includes(value.toLowerCase()) : true
      ),
  });

  const schemaForCreatingIndParent = Yup.object({
    parentCustSrcId: Yup
      .string()
      .required('Parent ID is required')
      .trim()
      .test('unique', 'Parent ID must be unique', (value) =>
        value ? !usedCustSrcIds.includes(value.toLowerCase()) : true
      ),
    parentCustName: Yup
      .string()
      .required('Parent Name is required')
      .trim()
      .test('unique', 'Parent Name must be unique', (value) =>
        value ? !usedCustNames.includes(value.toLowerCase()) : true
      ),
  });

  const schemaForEditingCustomer = Yup.object({
    custSrcId: Yup
      .string()
      .required('Customer ID is required')
      .trim()
      .test('unique', 'Customer ID must be unique', (value) =>
        value ? !usedCustSrcIds.includes(value.toLowerCase()) : true
      ),
    custName: Yup
      .string()
      .required('Customer Name is required')
      .trim()
      .test('unique', 'Customer Name must be unique', (value) =>
        value ? !usedCustNames.includes(value.toLowerCase()) : true
      ),
  });

  if (createCustomer) {
    if (isUltimateParent) {
      return schemaForCreatingUpcParent;
    } else {
      return schemaForCreatingIndParent;
    }
  } else {
    return schemaForEditingCustomer;
  }
}

export default customerSchema;
