import colors from "../../../utility/colors";

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    gap: '0.5rem',
    border: `1px solid ${colors.NEUTRAL}`,
    marginY: '1rem',
    overflow: 'scroll',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
  cancelButton: {
    textTransform: 'none',
    height: '2rem',
  },
  saveButton: {
    textTransform: 'none',
    height: '2rem',
    width: '10rem',
  },
}
export default styles;