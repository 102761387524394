import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MainSectionProps } from "..";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../../context/editVendorCustomerContext";
import useManageVendorCustomer from "../../../../utility/custom-hooks/useManageVendorCustomer";
import _ from "lodash";
import { IARCustomer, IARVendor } from "../../../../interfaces";
import { Box, InputAdornment, LinearProgress, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import styles from "./styles";
import SearchIcon from '@mui/icons-material/Search';
import ConfirmModal from "../../../../components/modals/confirm-modal";
import CustomerRow from "./customer-row";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import { ISelectedCustomerWithChildAndVendor } from "../../../../interfaces/editVendorCustomerInterface";
import { clearNewCustomersByIds } from "../../../../utility/helper";
import NoDataPlaceholder from "../../../../components/common/no-data-placeholder";

// Interfaces
export interface CustomersSectionProps extends MainSectionProps {

}

// Constants
const searchFilters = ['All', 'New', 'Parent', 'Child'];

const CustomersSection = (props: CustomersSectionProps) => {
  const { formik } = props;

  const { clearNewCustomers } = useManageVendorCustomer();

  // Contexts
  const {
    setToaster
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const {
    customersList,
    setCustomersList,
    filteredCustomersList,
    setFilteredCustomersList,
    vendorsList,
    setVendorsList,
    filteredVendorsList,
    setFilteredVendorsList,
    isFetching,
    selectedCustomerWithChildAndVendor,
    setSelectedCustomerWithChildAndVendor,
    vendorRemoveConfirmModalOpen,
    setVendorRemoveConfirmModalOpen,
    triggerResetSearch,
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // Hooks
  const { unlinkVendors } = useManageVendorCustomer();

  // States
  const [selectedSearch, setSelectedSearch] = useState<string>(searchFilters[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setSelectedSearch(searchFilters[0]);
    setSearchValue('');
    setPage(0);
  }, [triggerResetSearch])

  // Memos
  const rowsPerPage = useMemo(() => 10, []);

  const hasMore = useMemo(() => {
    return filteredCustomersList.slice(0, page * rowsPerPage + rowsPerPage).length < filteredCustomersList.length;
  }, [filteredCustomersList, page, rowsPerPage]);

  // Callbacks
  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    // Current implementation does not cover this branch on any cases
    // Will comment out for future references:
    // if (isFetching) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [hasMore]); // remove isFetching from dependency

  // Functions
  const triggerSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);

    let debounce = _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const filtered = handleSearch(event.target.value);
      setFilteredCustomersList(filtered);
    }, 500);
    debounce(event);
  };
  
  const handleSearch = (searchValue: string) => {
    const loweredSearchValue = searchValue.toLowerCase().trim();
    if (loweredSearchValue === '') return customersList;
    return filterCustomers(selectedSearch, loweredSearchValue);
  }

  const filterCustomers = (selectedSearch: string, loweredSearchValue: string) => {
    if (selectedSearch === 'All') {
      return customersList.filter(customer => 
        customer.custName.toLowerCase().includes(loweredSearchValue) ||
        customer.childCustomers.some(customer => 
          customer.custName?.toLowerCase().includes(loweredSearchValue) ||
          customer.custSrcId?.toLowerCase().includes(loweredSearchValue)
        ) ||
        customer.vendors.some(vendor =>
          vendor.vendorName?.toLowerCase().includes(loweredSearchValue) ||
          vendor.vendorSrcId?.toLowerCase().includes(loweredSearchValue)
        )
      )
    } else if (selectedSearch === 'Parent') {
      return customersList.filter(customer => customer.isParent &&
        customer.custName.toLowerCase().includes(loweredSearchValue)
      )
    } else if (selectedSearch === 'Child') {
      return customersList.filter(customer => {
        
        const filteredChildCustomers: IARCustomer[] = customer.childCustomers.filter(customer => 
          customer.custName?.toLowerCase().includes(loweredSearchValue) ||
          customer.custSrcId?.toLowerCase().includes(loweredSearchValue)
        );

        return filteredChildCustomers.length > 0;
      })
    } else {
      return customersList.filter(customer => customer.isNew &&
        (
          customer.custName.toLowerCase().includes(loweredSearchValue) ||
          customer.childCustomers.some(customer => 
            customer.custName?.toLowerCase().includes(loweredSearchValue) ||
            customer.custSrcId?.toLowerCase().includes(loweredSearchValue)
          ) ||
          customer.vendors.some(vendor =>
            vendor.vendorName?.toLowerCase().includes(loweredSearchValue) ||
            vendor.vendorSrcId?.toLowerCase().includes(loweredSearchValue)
          )
        )
      )
    }
  }

  const handleClearNewCustomers = async () => {
    try {
      const allCustomerIds = customersList.reduce((prevValue, customer) => {
        prevValue.push(customer.recordId!);

        if (customer.childCustomers.length > 0) {
          customer.childCustomers.forEach(child => {
            prevValue.push(child.recordId!);
          });
        }

        return prevValue;
      }, [] as number[]);
      await clearNewCustomersByIds(allCustomerIds);

      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' });
      
      // Updating the display
      // Customers List
      setCustomersList(clearNewCustomers(customersList));
      setFilteredCustomersList(clearNewCustomers(filteredCustomersList));
    } catch (error) {
      console.log('CLEAR ALL CUSTOMERS ERROR: ', error);
      setToaster({ open: true, message: 'Failed to clear new customers!', severity: 'error' });
    }
  }

  // ------------------------ UNLINK VENDOR -------------------------
  const handleConfirmRemoveVendor = () => {
    const { selectedVendors } = selectedCustomerWithChildAndVendor as ISelectedCustomerWithChildAndVendor;

    const {
      currentVendorsToEdit,
      unlinkedVendors
    } = getVendorsToEditAndUnlinked(selectedVendors);

    formik.setFieldValue('vendorsToEdit', currentVendorsToEdit);

    // Updating the customersList
    setCustomersList(unlinkVendors(customersList, selectedCustomerWithChildAndVendor!));
    setFilteredCustomersList(unlinkVendors(filteredCustomersList, selectedCustomerWithChildAndVendor!));

    // Updating the vendorsList
    setVendorsList([...vendorsList, ...unlinkedVendors]);
    setFilteredVendorsList([...filteredVendorsList, ...unlinkedVendors]);
  }

  const getVendorsToEditAndUnlinked = (selectedVendors: IARVendor[]) => {
    // Updating customersToEdit
    const currentVendorsToEdit = [...formik.values.vendorsToEdit];
    const unlinkedVendors: IARVendor[] = [];

    for (const selectedVendor of selectedVendors) {
      const unlinked: IARVendor = {
        ...selectedVendor,
        arCustomerId: undefined
      }
      unlinkedVendors.push(unlinked);

      const existingVendorIndex = currentVendorsToEdit.findIndex(
        (vendor) => vendor.recordId === selectedVendor.recordId
      );

      if (existingVendorIndex !== -1) {
        currentVendorsToEdit[existingVendorIndex] = {
          ...currentVendorsToEdit[existingVendorIndex],
          arCustomerId: undefined
        };
      } else {
        currentVendorsToEdit.push(unlinked);
      }
    }

    return ({ currentVendorsToEdit, unlinkedVendors });
  }
  
  // Rendering the component
  const getTableBody = () => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell
            sx={styles.linearProgressCell}
            colSpan={4}
          >
            <LinearProgress />
          </TableCell>
        </TableRow>
      )
    } else if (filteredCustomersList.length) {
      return (
        [...filteredCustomersList]
          .sort((a, b) =>
            a.custName.toLowerCase().localeCompare(b.custName.toLowerCase())
          )
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((value, index, array) => {
            const lastRow = array.length === index + 1;

            return (
              <CustomerRow
                {...props}
                key={value.custSrcId}
                index={index}
                customerWithChildAndVendors={value}
                lastRow={lastRow}
                lastRowElementRef={lastRowElementRef}
              />
            )
          })
      )
    } else {
      return (
        <TableRow>
          <TableCell
            sx={styles.emptyVerbiageCell}
            colSpan={4}
          >
            <NoDataPlaceholder
              messageText='No Customer List'
              messageContainerStyle={styles.emptyVerbiageContainer}
            />
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <>
    <Box
      component={Paper}
      sx={styles.parentsSectionContainer}
    >
      <Box sx={styles.clearButtonBox}>
        <Link 
          component='button'
          type='button'
          sx={styles.clearButton}
          onClick={() => {
            setClearModalOpen(true);
          }}
        >
          Clear All New Customers
        </Link>
      </Box>
      
      {/* Search bar */}
      <Box sx={styles.searchContainer}>
        <Select
          id='filter-dropdown-customers-list'
          value={selectedSearch}
          // No filtering needed on change of this component since the child/vendor is always under the parent
          onChange={(event) => setSelectedSearch(event.target.value)}
          sx={styles.filterSelector}
          inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
          data-testid='search-dropdown-customers-list'
        >
          {searchFilters.map(filter => (
            <MenuItem
              key={filter}
              value={filter}
            >
              {filter}
            </MenuItem>
          ))}
        </Select>
        <TextField
          inputProps={{ 'aria-label': 'Search-Textfield' }}
          id='search-customers-list'
          data-testid='search-field-customers-list'
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                tabIndex={0}
                aria-label='Search icon'
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={triggerSearch}
          placeholder='Search'
          size='small'
          sx={styles.searchField}
        />
      </Box>

      {/* Main Table */}
      <TableContainer
        sx={styles.sectionTableContainer}
      >
        <Table stickyHeader>

          {/* Headers */}
          <TableHead sx={styles.sectionTableHead}>
            <TableRow sx={styles.sectionTableHeaderRow}>
              <TableCell sx={styles.selectAndActionTableCell} />
              <TableCell sx={styles.parentNameHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell sx={styles.parentArAmountHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  AR Amount
                </Typography>
              </TableCell>
              <TableCell sx={styles.selectAndActionTableCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'center' }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Spacer */}
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>

          {/* Parent List */}
          <TableBody>
            {getTableBody()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    <ConfirmModal
      title='Remove'
      description={
        'You are about to remove this vendor.'
        +'Are you sure?'
      }
      open={vendorRemoveConfirmModalOpen}
      onClose={() => {
        setSelectedCustomerWithChildAndVendor(null);
        setVendorRemoveConfirmModalOpen(false);
      }}
      onConfirm={() => {
        handleConfirmRemoveVendor();
      }}
      yesButtonText='Remove'
      noButtonText='Cancel'
      errorButton={false}
    />

    <ConfirmModal
      title='Clear All New Customers'
      description='Are you sure you want to clear all new added customers?'
      open={clearModalOpen}
      onClose={() => {
        setClearModalOpen(false);
      }}
      onConfirm={() => {
        handleClearNewCustomers();
      }}
      yesButtonText='Clear'
      noButtonText='Cancel'
    />
    </>
  )
}

export default CustomersSection;
