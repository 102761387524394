import { FormikProps } from "formik";
import { CustomerVendorModalProps } from "..";
import { IARVendor } from "../../../../interfaces";
import { useContext, useMemo } from "react";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import HelperTextComponent from "../../../../components/common/helper-text-component";
import { Box, TextField, Typography } from "@mui/material";
import styles from "./styles";
import { trimOnBlur } from "../../../../utility/helper";

interface VendorInfoProps extends CustomerVendorModalProps {
  formik: FormikProps<IARVendor>;
  isEditting: boolean;
  belowBreakPoint?: boolean;
}

const VendorInfo = (props: VendorInfoProps) => {
  const { formik, isEditting, belowBreakPoint } = props;

  const getHelperText = (formik: FormikProps<IARVendor>, value: string) => {
    const hasError = formik.touched[value] && formik.errors[value];
    return hasError && <HelperTextComponent text={formik.errors[value]}/>;
  }

  return (
    <>
    <Box sx={{
      ...styles.customerInfoContainer,
      ...(belowBreakPoint && { width: '80%' })
    }}>
      <Typography variant='body1' sx={styles.sectionTitle}>
        CUSTOMER INFORMATION
      </Typography>
      <Box sx={styles.customerInputsContainer}>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-customer-name' sx={styles.label}>
            Customer Name
          </Typography>
          <TextField
            id='text-field-customer-name'
            name='custName'
            value={formik.values.custName}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Customer Name',
              'aria-labelledby': 'custName',
              'data-testid': 'text-field-customer-name',
            }}
            disabled
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-customer-id' sx={styles.label}>
            Customer ID
          </Typography>
          <TextField
            id='text-field-customer-id'
            name='custSrcId'
            value={formik.values.custSrcId}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Customer ID',
              'aria-labelledby': 'custSrcId',
              'data-testid': 'text-field-customer-id',
            }}
            disabled
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendor-name' sx={styles.label}>
            Vendor Name<span style={styles.asterisk}> *</span>
          </Typography>
          <TextField
            id='text-field-vendor-name'
            name='vendorName'
            value={formik.values.vendorName}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorName', formik.values.vendorName);
            }}
            error={formik.touched.vendorName && Boolean(formik.errors.vendorName)}
            helperText={getHelperText(formik, 'vendorName')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Name',
              'aria-labelledby': 'vendorName',
              'data-testid': 'text-field-vendor-name',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendor-id' sx={styles.label}>
            Vendor ID<span style={styles.asterisk}> *</span>
          </Typography>
          <TextField
            id='text-field-vendor-id'
            name='vendorSrcId'
            value={formik.values.vendorSrcId}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorSrcId', formik.values.vendorSrcId);
            }}
            error={formik.touched.vendorSrcId && Boolean(formik.errors.vendorSrcId)}
            helperText={getHelperText(formik, 'vendorSrcId')}
            sx={styles.textfieldCustomerInfo}
            size='small'
            inputProps={{
              'aria-label': 'Vendor ID',
              'aria-labelledby': 'vendorSrcId',
              'data-testid': 'text-field-vendor-id',
            }}
            disabled={!isEditting}
          />
        </Box>

      </Box>
    </Box>
    <Box sx={{
      ...styles.contactInfoContainer,
      ...(belowBreakPoint && { width: '80%' })
    }}>
      <Typography variant='body1' sx={styles.sectionTitle}>
        CONTACT INFORMATION
      </Typography>
      <Box sx={styles.customerInputsContainer}>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorCountry' sx={styles.label}>
            Country
          </Typography>
          <TextField
            id='text-field-vendorCountry'
            name='vendorCountry'
            value={formik.values.vendorCountry}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorCountry', formik.values.vendorCountry);
            }}
            error={formik.touched.vendorCountry && Boolean(formik.errors.vendorCountry)}
            helperText={getHelperText(formik, 'vendorCountry')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Country',
              'aria-labelledby': 'vendorCountry',
              'data-testid': 'text-field-vendorCountry',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorAddress1' sx={styles.label}>
            Address 1
          </Typography>
          <TextField
            id='text-field-vendorAddress1'
            name='vendorAddress1'
            value={formik.values.vendorAddress1}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorAddress1', formik.values.vendorAddress1);
            }}
            error={formik.touched.vendorAddress1 && Boolean(formik.errors.vendorAddress1)}
            helperText={getHelperText(formik, 'vendorAddress1')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Address 1',
              'aria-labelledby': 'vendorAddress1',
              'data-testid': 'text-field-vendorAddress1',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorAddress2' sx={styles.label}>
            Address 2
          </Typography>
          <TextField
            id='text-field-vendorAddress2'
            name='vendorAddress2'
            value={formik.values.vendorAddress2}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorAddress2', formik.values.vendorAddress2);
            }}
            error={formik.touched.vendorAddress2 && Boolean(formik.errors.vendorAddress2)}
            helperText={getHelperText(formik, 'vendorAddress2')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Address 2',
              'aria-labelledby': 'vendorAddress2',
              'data-testid': 'text-field-vendorAddress2',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorCity' sx={styles.label}>
            City
          </Typography>
          <TextField
            id='text-field-vendorCity'
            name='vendorCity'
            value={formik.values.vendorCity}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorCity', formik.values.vendorCity);
            }}
            error={formik.touched.vendorCity && Boolean(formik.errors.vendorCity)}
            helperText={getHelperText(formik, 'vendorCity')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor City',
              'aria-labelledby': 'vendorCity',
              'data-testid': 'text-field-vendorCity',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.stateAndZipContainer}>
          <Box sx={styles.inputContainer}>
            <Typography component='label' htmlFor='text-field-vendorState' sx={styles.label}>
              State
            </Typography>
            <TextField
              id='text-field-vendorState'
              name='vendorState'
              value={formik.values.vendorState}
              onChange={(event) => formik.handleChange(event)}
              onBlur={(event) => {
                trimOnBlur(event, formik, 'vendorState', formik.values.vendorState);
              }}
              error={formik.touched.vendorState && Boolean(formik.errors.vendorState)}
              helperText={getHelperText(formik, 'vendorState')}
              sx={styles.textFieldContactSmall}
              size='small'
              inputProps={{
                'aria-label': 'Vendor State',
                'aria-labelledby': 'vendorState',
                'data-testid': 'text-field-vendorState',
              }}
              disabled={!isEditting}
            />
          </Box>
          <Box sx={styles.inputContainer}>
            <Typography component='label' htmlFor='text-field-vendorPostalCode' sx={styles.label}>
              Zip Code
            </Typography>
            <TextField
              id='text-field-vendorPostalCode'
              name='vendorPostalCode'
              value={formik.values.vendorPostalCode}
              onChange={(event) => formik.handleChange(event)}
              onBlur={(event) => {
                trimOnBlur(event, formik, 'vendorPostalCode', formik.values.vendorPostalCode);
              }}
              error={formik.touched.vendorPostalCode && Boolean(formik.errors.vendorPostalCode)}
              helperText={getHelperText(formik, 'vendorPostalCode')}
              sx={styles.textFieldContactSmall}
              size='small'
              inputProps={{
                'aria-label': 'Vendor Zip Code',
                'aria-labelledby': 'vendorPostalCode',
                'data-testid': 'text-field-vendorPostalCode',
              }}
              disabled={!isEditting}
            />
          </Box>
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorPhone' sx={styles.label}>
            Phone Number
          </Typography>
          <TextField
            id='text-field-vendorPhone'
            name='vendorPhone'
            value={formik.values.vendorPhone}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorPhone', formik.values.vendorPhone);
            }}
            error={formik.touched.vendorPhone && Boolean(formik.errors.vendorPhone)}
            helperText={getHelperText(formik, 'vendorPhone')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Phone',
              'aria-labelledby': 'vendorPhone',
              'data-testid': 'text-field-vendorPhone',
            }}
            disabled={!isEditting}
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Typography component='label' htmlFor='text-field-vendorDescription' sx={styles.label}>
            Description
          </Typography>
          <TextField
            id='text-field-vendorDescription'
            name='vendorDescription'
            value={formik.values.vendorDescription}
            onChange={(event) => formik.handleChange(event)}
            onBlur={(event) => {
              trimOnBlur(event, formik, 'vendorDescription', formik.values.vendorDescription);
            }}
            error={formik.touched.vendorDescription && Boolean(formik.errors.vendorDescription)}
            helperText={getHelperText(formik, 'vendorDescription')}
            sx={styles.textFieldContactCommon}
            size='small'
            inputProps={{
              'aria-label': 'Vendor Description',
              'aria-labelledby': 'vendorDescription',
              'data-testid': 'text-field-vendorDescription',
            }}
            disabled={!isEditting}
          />
        </Box>
      </Box>
    </Box>
    </>
  )
}

export default VendorInfo