import { Dispatch, FC, SetStateAction, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertColor, Box, Button, CircularProgress, Grid, LinearProgress, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import { AMERICAN_DATE_FORMAT, API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST } from "../../../utility/constants";
import { checkFileNameIfExisting, checkRate, checkRatesByToCurrencyIds, checkUserPermissions, exportReport, formatDate, formatNumber, getARCollateralRequest, getBBPeriodRequest, getClientRequest, getCurrencies, getFileName, getOptions, getParamsByType, getUpcToCurrencyIdsForGlTransaction, prependElement } from "../../../utility/helper";
import GeneralBreadcrumbs from "../../../components/breadcrumb";
import RollForwardReportComboBox from "../../../components/common/combo-box";
import MenuButton from "../../../components/common/menu-button";
import ReportsTitle from "../../../components/reports-title";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import styles from "./styles";
import { IComboBoxIds, IComboBoxSetStates, IOption } from "../../../interfaces/comboBox";
import { AuthContext } from "../../../context/authContext";
import Toaster, { ToasterState } from "../../../components/toaster";
import { IClientVariances, IRfBeginningBalance, IRfCalcSummary, IRollForwardReport, IUpcClientRollForward } from "../../../interfaces/rollForwardReportInterface";
import axiosInstance from "../../../service/axiosInstance";
import { arCollateralAPI, multiCurrencyAPI, reportsAPI } from "../../../service/api";
import RollForwardTable from "./table";
import DisabledComponentsContainer from "../../../components/common/disabled-components-container";
import { ICurrency, IRate } from "../../../interfaces/multiCurrencyInterface";
import WarningModal from "../../../components/file-import/modals/warning-modal";
import ClientRfReports from "./upc-level-body";
import { IExportReportWithPayload } from "../../../interfaces/exportReportInterface";
import { ExportReportContext } from "../../../context/exportReportContext";

const initToasterState: ToasterState = {
  open    : false,
  message : '',
  severity: 'success'
};

/**
   * This function formats a numeric value as a currency string based on the provided options, value, and exchange rate.
   * @param value The numeric value to format.
   * @returns The formatted currency string.
   */
export const handleFormatCurrency = (value: number, currencyLabel?: string, exchangeRate?: number) => {
  const parsedCurrencyLabel = currencyLabel === '' || currencyLabel === undefined ? 'USD' : currencyLabel;

  return formatNumber(
    {
      style: 'currency',
      currency: parsedCurrencyLabel,
      currencySign: 'accounting'
    },
    value,
    exchangeRate
  )
};

/**
 * Component for the Roll Forward Report page.
 * @returns A component for the Roll Forward Report page.
 */
const RollForwardReport: FC = () => {
  const navigate              = useNavigate();
  const theme                 = useTheme();
  const belowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const belowSmallBreakpoint  = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedClientContext = useContext(SelectedClientContext);
  const {state}               = useContext(AuthContext);
  const { setShow,
          exports,
          setExports }        = useContext(ExportReportContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [rollForwards, setRollForwards]             = useState<IRollForwardReport[]>([]);
  const [rfCalcSummary, setRfCalcSummary]           = useState<IRfCalcSummary>();
  const [generate, setGenerate]                     = useState<boolean>(false);
  const [clients, setClients]                       = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]         = useState<IOption | null>(null);
  const [clientInput, setClientInput]               = useState<string>('');
  const [collaterals, setCollaterals]               = useState<IOption[]>([]);
  const [selectedCollateral, setSelectedCollateral] = useState<IOption | null>(null);
  const [collateralInput, setCollateralInput]       = useState<string>('');
  const [bbPeriods, setBBPeriods]                   = useState<IOption[]>([]);
  const [selectedBBPeriod, setSelectedBBPeriod]     = useState<IOption | null>(null);
  const [bbPeriodInput, setBBPeriodInput]           = useState<string>('');
  const [currencies, setCurrencies]                 = useState<IOption[]>([]);
  const [selectedCurrency, setSelectedCurrency]     = useState<IOption | null>(null);
  const [currencyInput, setCurrencyInput]           = useState<string>('');
  const [exchangeRate, setExchangeRate]             = useState<number>();
  const [disabled, setDisabled]                     = useState<boolean>(true);
  const [isLoading, setIsLoading]                   = useState<boolean>(false);
  const [exporting, setExporting]                   = useState<boolean>(false);
  const [displayNoTable, setDisplayNoTable]         = useState<boolean>(false);
  const [canExport, setCanExport]                   = useState<boolean>(false);
  const [toasterProps, setToasterProps]             = useState<ToasterState>(initToasterState);
  const [beginningBalance, setBeginningBalance]     = useState<IRfBeginningBalance>();
  const [firstLoad, setFirstLoad]                   = useState<boolean>(true);
  const [toCurrencyIds, setToCurrencyIds]           = useState<number[]>([]);
  const [allExchangeRates, setAllExchangeRates]     = useState<IRate[]>([]);
  const [rateModalOpen, setRateModalOpen]           = useState<boolean>(false);
  const [clientRfReport, setClientRfReport]         = useState<IUpcClientRollForward>();
  const [idToNameMap, setIdToNameMap]               = useState<{[key: string]: string}>();
  const [rateId, setRateId]                         = useState<number>();
  const [clientVariances, setClientVariances]       = useState<IClientVariances>([]);

  /**
   * This variable stores a memoized currency label based on the selected currency's label.
   */
  const currencyLabel = useMemo(() => selectedCurrency?.label.substring(0, 3), [selectedCurrency]);

  /**
   * This variable stores a memoized search params.
   */
  const params = useMemo(() => {
    const borrowerId     = searchParams.get('clientId') ?? '0';
    const arCollateralId = searchParams.get('arCollateralId') ?? '0';
    const bbPeriodId     = searchParams.get('bbPeriodId') ?? '0';
    const endDate        = searchParams.get('endDate') ?? '0';
    const currencyId     = searchParams.get('currencyId') ?? '0';
    return { borrowerId, arCollateralId, bbPeriodId, invCollateralId: '0', customerId: '0', currencyId, endDate };
  }, [searchParams])

  const filteredClients = useMemo(() =>
    clients.filter(client => client.parentClientFk === undefined)
  , [clients]);

  const isUltimateParent = useMemo(() =>
    selectedClientContext.selectedClient?.parentClient
  , [selectedClientContext.selectedClient]);

  /**
   * Memoizes a mapping of exchange rates for each currency ID.
   */
  const collateralRatesMap: {[key: number]: number} = useMemo(() =>
    allExchangeRates.reduce((acc, exchangeRate) => {

      if (!acc[exchangeRate.toCurrencyId]) {
        acc[exchangeRate.toCurrencyId] = exchangeRate.currencyRate
      }

      return acc
    }, {})
  , [allExchangeRates]);
  
  /**
   * This function uses the useEffect hook to execute an asynchronous function when the component mounts.
   */
  useEffect(() => {
    (async () => await getPermission())();
  }, [])

  /**
   * This function checks user permissions and sets the component's 'canExport' state based on the result.
   */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_ROLL_FORWARD_REPORT)
    if(isPermitted){
      setCanExport(isPermitted)
    }
  };
  
  /**
   * This effect is triggered when the 'generate' dependency changes, and it fetches data related to the specified parameters.
   */
  useEffect(() => {
    if (!isUltimateParent) {
      getNonUpcReport();
    }

    if (isUltimateParent && !firstLoad) {
      getUpcReport();
    }
  }, [generate])

  /**
   * This useEffect hook triggers the fetching of UPC reports on first load.
   * Waits for the collateralRatesMap to be populated before fetching.
   */
  useEffect(() => {
    if (isUltimateParent && firstLoad && Object.keys(collateralRatesMap).length > 0) {
      getUpcReport();
    };
  }, [collateralRatesMap])

  /**
   * This effect is triggered when the component mounts and fetches options related to the client type.
   */
  useEffect(() => {
    (async () => await getOptions({
      type: 'client',
      params,
      getSetStatesByType,
      setSelectedByType,
      mainRequest: getClientRequest()
    }))();
  }, [])

  /**
   * This effect monitors changes in selected dropdown values and updates the 'disabled' state accordingly.
   */
  useEffect(() => {
    if (isUltimateParent) {
      const isNotEmptyDropdowns = selectedClient && selectedBBPeriod;
      setDisabled(!(isNotEmptyDropdowns && !firstLoad && allExchangeRates.length > 0));
    } else {
      const isNotEmptyDropdowns = Boolean(selectedClient && selectedBBPeriod && selectedCollateral && selectedCurrency);
      setDisabled(!(isNotEmptyDropdowns && !firstLoad));
    }
  }, [firstLoad, selectedClient, selectedBBPeriod, selectedCollateral, selectedCurrency, isUltimateParent, allExchangeRates]);

  /**
   * This effect is triggered when the 'borrowerId' dependency changes and fetches AR collateral options for the specified borrower.
   */
  useEffect(() => {
    (async () => {
      const { borrowerId, endDate } = params;

      if (borrowerId !== "0") {
        if (!isUltimateParent) {
          const parsed = parseInt(borrowerId);
          await getOptions({ type: 'arCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getARCollateralRequest(parsed), requestForDefault: true});
        } else {
          const bbPeriodOpts = await getAvailableUpcDatesForReport(parseInt(borrowerId));
          setBBPeriods(bbPeriodOpts ?? []);
          searchParams.delete('bbPeriodId');

          if (parseInt(endDate) !== 0) {
            const selected = bbPeriodOpts?.find(bbPeriod => formatDate(bbPeriod.label, 'YYYYMMDD') === endDate);
            setSelectedBBPeriod(selected ?? null);
          }
        }
      }
    })();
  }, [params.borrowerId, isUltimateParent])

  /**
   * This effect is triggered when the 'borrowerId' or 'arCollateralId' dependencies change, and it fetches BB period options and currency data.
   */
  useEffect(() => {
    (async () => {
      const { borrowerId, arCollateralId } = params;
      const parsedBorrowerId = parseInt(borrowerId);
      const parsedARCollateralId = parseInt(arCollateralId);

      if (parsedBorrowerId && parsedARCollateralId && !isUltimateParent) {
        await getOptions({ type: 'bbPeriod', params, getSetStatesByType, setSelectedByType, mainRequest: getBBPeriodRequest(parsedBorrowerId), requestForDefault: false, filter: 'rollForward'});
      }
    })();
  }, [params.borrowerId, params.arCollateralId, isUltimateParent])

  useEffect(() => {
    (async () => {
      const { borrowerId, arCollateralId, endDate } = params;
      const parsedBorrowerId = parseInt(borrowerId);
      const parsedARCollateralId = parseInt(arCollateralId);
      const parsedEndDate = formatDate(endDate, AMERICAN_DATE_FORMAT);

    if (parsedBorrowerId && parsedARCollateralId && parsedEndDate) {
      await fetchCurrency(parsedBorrowerId, parsedARCollateralId, parsedEndDate);
    }
    })();
  }, [params.borrowerId, params.arCollateralId, params.endDate])

  /**
   * This useEffect hook initiates currency fetching based on dropdown selections.
   */
  useEffect(() => {
    (async () => {
      if (isUltimateParent && selectedClient?.currencyId) {
        const clientCurrency = await getCurrencyById(parseInt(selectedClient.currencyId));
        if (clientCurrency) {
          const clientCurrencyOpt: IOption = {
            recordId: clientCurrency.recordId,
            label: `${clientCurrency.currencyCode} - ${clientCurrency.currencyName}`,
            default: true
          };
          setSelectedCurrency(clientCurrencyOpt);
          updateSearchParams('currencyId', `${clientCurrencyOpt.recordId}`);
        }
      }
    })();
  }, [selectedClient, toCurrencyIds, isUltimateParent])

  /**
   * This useEffect hook initiates exchange rate fetching based on dropdown selections.
   */
  useEffect(() => {
    (async () => {
      if (selectedClient?.currencyId && selectedBBPeriod && isUltimateParent) {
        const endDate = formatDate(selectedBBPeriod.label, 'YYYYMMDD');
        const toCurrencyIds = await getUpcToCurrencyIdsForGlTransaction(selectedClient?.recordId, endDate);
        setToCurrencyIds(toCurrencyIds ?? []);

        const rate: IRate[] | undefined = await checkRatesByToCurrencyIds(
          selectedClient?.recordId,
          parseInt(selectedClient.currencyId),
          toCurrencyIds ?? [],
          endDate
        );
        setAllExchangeRates(rate ?? []);
        if (!rate) setRateModalOpen(true);
      }
    })();
  }, [selectedClient, selectedBBPeriod, isUltimateParent])

  /**
   * This function is a callback used to navigate to the '/reports' route.
   */
  const handleNavigate = useCallback(() => navigate('/reports'), []);

  /**
   * This function is a callback used to trigger data generation and update related states.
   */
  const handleGenerate = useCallback(() => {
    setDisabled(true);
    setFirstLoad(false);
    setGenerate(!generate);
  }, [generate]);

  /**
   * This function generates the beginning balance, summary, and roll forwards of the report.
   * @param borrowerId The ID of the selected Borrower/Client.
   * @param bbPeriodId The ID of the selected As of Date.
   * @param arCollateralId The ID of the selected AR Collateral.
   * @returns An early return when report is not available.
   */
  const generateReport = async (borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    const beginningBalance: IRfBeginningBalance | undefined = await getBeginningBalance(borrowerId, bbPeriodId, arCollateralId);
    if (beginningBalance) {
      const summary: IRfCalcSummary | undefined = await getSummary(borrowerId, bbPeriodId, arCollateralId);
      if (summary) {
        const report: IRollForwardReport[] | undefined = await getReport(borrowerId, bbPeriodId, arCollateralId);
        if (!report) return;
        setDisplayNoTable(false);
      }
    }
  }

  /**
   * This function gets the Non Upc Roll Forward Report.
   * Fetches beginning balance, summary, and the roll forwards.
   */
  const getNonUpcReport = async () => {
    const { borrowerId, bbPeriodId, arCollateralId, endDate, currencyId } = params;
    const parsedBorrowerId = parseInt(borrowerId);
    const parsedBBPeriodId = parseInt(bbPeriodId);
    const parsedARCollateralId = parseInt(arCollateralId);
    const parsedEndDate = formatDate(endDate, AMERICAN_DATE_FORMAT);
    const parsedCurrencyId = parseInt(currencyId);

    if (parsedBorrowerId && parsedBBPeriodId && parsedARCollateralId && parsedEndDate) {
      setIsLoading(true);
      await fetchExchangeRate(parsedBorrowerId, parsedARCollateralId, parsedEndDate, parsedCurrencyId);
      await generateReport(parsedBorrowerId, parsedBBPeriodId, parsedARCollateralId);
      setIsLoading(false);
    }
  }

  /**
   * This function asynchronously fetches the beginning balance data for a specified borrower, BB period, and AR collateral.
   * @param borrowerId The ID of the borrower.
   * @param bbPeriodId The ID of the BB period.
   * @param arCollateralId The ID of the AR collateral.
   * @returns A promise that resolves to the beginning balance data or null if no data is found.
   */
  const getBeginningBalance = async (borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.BEGINNING_BALANCE_ENDPOINT,
        method: POST,
        params: { borrowerId, bbPeriodId, arCollateralId }
      })
      const rfbb: IRfBeginningBalance[] = response.data;

      if (rfbb.length) {
        setBeginningBalance(rfbb[0]);
        return rfbb[0];
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING BEGINNING BALANCE ERROR: ', error);
    }
  }

  /**
   * This function asynchronously fetches the summary data for a specified borrower, BB period, and AR collateral.
   * @param borrowerId The ID of the borrower.
   * @param bbPeriodId The ID of the BB period.
   * @param arCollateralId The ID of the AR collateral.
   * @returns A promise that resolves to the summary data or null if no data is found.
   */
  const getSummary = async (borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_SUMMARY,
        method: GET,
        params: { borrowerId, bbPeriodId, arCollateralId, sortBy: 'rfCalcSummaryId,ASC' }
      })
      const rfcs: IRfCalcSummary[] = response.data;

      if (rfcs.length) {
        setRfCalcSummary(rfcs[0]);
        return rfcs[0];
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING REPORT SUMMARY ERROR: ', error);
    }
  };

  /**
   * This function asynchronously fetches the roll forward report data for a specified borrower, BB period, and AR collateral.
   * @param borrowerId The ID of the borrower.
   * @param bbPeriodId The ID of the BB period.
   * @param arCollateralId The ID of the AR collateral. 
   * @returns A promise that resolves to the roll forward report data or null if no data is found.
   */
  const getReport = async (borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.MAIN_ENDPOINT,
        method: GET,
        params: { borrowerId, bbPeriodId, arCollateralId, sortBy: 'rfCategoryName,ASC' }
      })
      const rfs: IRollForwardReport[] = response.data.content;

      if (rfs.length) {
        setRollForwards(rfs);
        return rfs;
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING REPORT ERROR: ', error);
    }
  };

  /**
   * This function gets the Upc Roll Forward Report.
   * Fetches beginning balance, summary, and the roll forwards for all clients under the parent client.
   */
  const getUpcReport = async () => {
    const { borrowerId, endDate } = params;
    const parsedParentClientId = parseInt(borrowerId);
    const parsedEndDate = parseInt(endDate);

    if (parsedParentClientId && parsedEndDate) {
      setIsLoading(true);

      const beginningBalances: IRfBeginningBalance[] | undefined = await getBeginningBalances(parsedParentClientId, endDate);
      if (beginningBalances) {
        const summaries: IRfCalcSummary[] | undefined = await getSummaries(parsedParentClientId, endDate);
        if (summaries) {
          const rollForwards: IRollForwardReport[] | undefined = await getRollForwards(parsedParentClientId, endDate);
          if (!rollForwards) return;
          getIdToNameMap(summaries);
          getClientRfReport(rollForwards, summaries, beginningBalances);
        }
      }

      setIsLoading(false);
    }
  }

  /**
   * This function sets the Roll Forward Report by Client and Collateral
   * @param summaries The RF Summaries under the Parent Client
   * @param beginningBalances The Beginning Balances under the Parent Client
   */
  const getClientRfReport = (rollForwards: IRollForwardReport[], summaries: IRfCalcSummary[], beginningBalances: IRfBeginningBalance[]) => {
    const initialClientRf = getInitialClientRfBySummaries(summaries);
    for (const rollForward of rollForwards) {
      const { borrowerId, arCollateralId } = rollForward;

      if (!initialClientRf[borrowerId][arCollateralId][`rollForwards`]) {
        initialClientRf[borrowerId][arCollateralId][`rollForwards`] = [];
      }

      initialClientRf[borrowerId][arCollateralId][`rollForwards`].push(rollForward);
    }

    for (const beginningBalance of beginningBalances) {
      const { borrowerId, arCollateralId } = beginningBalance;
      
      initialClientRf[borrowerId][arCollateralId][`beginningBalance`] = beginningBalance;
    }

    setClientRfReport(initialClientRf);
  }

  /**
   * This function gets all beginning balances of the clients under the selected parent client.
   * @param parentClientId The ID of the Ultimate Parent Client.
   * @param endDate The end date of the BB Period.
   * @returns An array of beginning balances under the parent client.
   */
  const getBeginningBalances = async (parentClientId: number, endDate: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_UPC_BEGINNING_BALANCE,
        method: POST,
        params: { parentClientId, endDate }
      })
      const rfbb: IRfBeginningBalance[] = response.data;

      if (rfbb.length) {
        setDisplayNoTable(false);
        return rfbb;
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING UPC BEGINNING BALANCES ERROR: ', error);
    }
  };

  /**
   * This function gets all the summaries of the clients under the selected parent client.
   * @param parentClientId The ID of the Ultimate Parent Client.
   * @param endDate The end date of the BB Period.
   * @returns An array of roll forward summaries under the parent client.
   */
  const getSummaries = async (parentClientId: number, endDate: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_UPC_SUMMARY,
        method: GET,
        params: { parentClientId, endDate }
      })
      const rfcs: IRfCalcSummary[] = response.data;

      if (rfcs.length) {
        setDisplayNoTable(false);
        return rfcs;
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING UPC REPORT SUMMARY ERROR: ', error);
    }
  };

  /**
   * This function gets all the roll forwards of the clients under the selected parent client.
   * @param parentClientId The ID of the Ultimate Parent Client.
   * @param endDate The end date of the BB Period.
   * @returns An array of roll forwards under the parent client.
   */
  const getRollForwards = async (parentClientId: number, endDate: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.MAIN_ENPOINT_UPC,
        method: GET,
        params: { parentClientId, endDate, sortBy: 'rfCategoryName,ASC' }
      })
      const rfs: IRollForwardReport[] = response.data.content;

      if (rfs.length) {
        setDisplayNoTable(false);
        return rfs;
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      setDisplayNoTable(true);
      setIsLoading(false);
      console.log('GETTING REPORT ERROR: ', error);
    }
  };

  /**
   * Initializes Borrower Id to Borrower Name, and AR Collateral Id to AR Collateral Name Map
   * @param summaries Roll Forward summaries of all clients under the parent client
   * @returns an initial Roll Forward Report by Client
   */
  const getIdToNameMap = (summaries: IRfCalcSummary[]) => {
    const idToName = summaries.reduce((acc: {[key: string]: string}, item: IRfCalcSummary) => {
      const { borrowerId, borrowerName, arCollateralId, arCollateralName } = item;

      if (!acc[`${borrowerId}`]) {
        acc[`${borrowerId}`] = borrowerName;
      }

      if (!acc[`${borrowerId} - ${arCollateralId}`]) {
        acc[`${borrowerId} - ${arCollateralId}`] = arCollateralName;
      }

      return acc;
    }, {});

    setIdToNameMap(idToName);
  }

  /**
   * Initializes Roll Forward Report by Client using Summaries
   * @param summaries Roll Forward summaries of all clients under the parent client
   * @returns an initial Roll Forward Report by Client
   */
  const getInitialClientRfBySummaries = (summaries: IRfCalcSummary[]) => {
    return summaries.reduce((acc, item: IRfCalcSummary) => {
      const { borrowerId, arCollateralId, currencyId } = item;

      if (!acc[borrowerId]) {
        acc[borrowerId] = {};
      }

      if (!acc[borrowerId][arCollateralId]) {
        acc[borrowerId][arCollateralId] = {};
      }

      if (!acc[borrowerId][arCollateralId]['currencyId']) {
        acc[borrowerId][arCollateralId]['currencyId'] = parseInt(currencyId);
      }

      acc[borrowerId][arCollateralId]['rfCalcSummary'] = item;

      return acc;
    }, {});
  }

  /**
   * This function gets the available as of dates for Roll Forward Report.
   * @param borrowerId The record id of the Ultimate Parent Client.
   * @returns The options for the available as of dates.
   */
  const getAvailableUpcDatesForReport = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_AVAILABLE_UPC_DATES,
        method: GET,
        params: { parentClientId: borrowerId }
      });

      const endDates: string[] = response.data;
      const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
        recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
        label: formatDate(endDate, AMERICAN_DATE_FORMAT),
        default: index === 0
      }))
      return bbPeriodOpts;
    } catch (error) {
      console.log('GET AVAILABLE UPC AS OF DATES FOR ROLL FORWARD REPORT ERROR: ', error);
    }
  }

  /**
   * Fetches currency information by ID from the multi-currency API.
   * @param currencyId The ID of the currency to retrieve.
   * @returns A Promise that resolves to the currency data.
   */
  const getCurrencyById = async (currencyId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${multiCurrencyAPI.GET_CURRENCY_BY_ID}/${currencyId}`,
        method: GET,
      });
      return response.data as ICurrency;
    } catch (error) {
      console.log('GET CURRENCY BY ID ERROR: ', error);
    }
  }

  /**
   * This function asynchronously fetches currency data, AR currency ID, and client currency ID, and prepares currency options.
   */
  const fetchCurrency = async (borrowerId: number, arCollateralId: number, endDate: string) => {
    const currencies        = await getCurrencies()
    const arCurrencyId      = await getARCurrencyId()
    const clientCurrencyId  = await getClientCurrencyId()
    const availableRate     = await checkRate(
      arCollateralId,
      borrowerId,
      endDate
    )

    if (currencies && arCurrencyId && clientCurrencyId && availableRate) {
      const currencyOptions:IOption[] = currencies.currencies
      .filter(currency => {
        const hasClientCurrency = `${currency.recordId}` === `${clientCurrencyId}`;
        const hasArCurrency = `${currency.recordId}` === `${arCurrencyId}`;
        const hasRate = currency.recordId === availableRate.toCurrencyId;
        return hasClientCurrency || (hasArCurrency && hasRate);
      })
      .map(filtered => ({
        recordId: filtered.recordId,
        label   : `${filtered.currencyCode} - ${filtered.currencyName}`,
        default : `${filtered.recordId}` === `${clientCurrencyId}`
      }))

      setCurrencies(prependElement(currencyOptions, 'recordId', clientCurrencyId.toString()))
      const selected = currencyOptions?.find(currency => currency.recordId === parseInt(params.currencyId)) ?? null;
      setSelectedCurrency(selected);
    }
  }
  
  /**
   * This function asynchronously retrieves the AR currency ID based on AR collateral settings.
   * @returns A promise that resolves to the AR currency ID or 0 if not found.
   */
  const getARCurrencyId = async () => {
    try {
      const response = await axiosInstance.request({
        url: arCollateralAPI.FIND_AR_SETTINGS_BY_AR_COLLATERAL_ID,
        method: GET,
        params: {arCollateralId: params.arCollateralId, isCurrent: true}
      });
    
      return response.data.content[0]?.currency ?? 0;
    } catch (error) {
      console.log('GET AR CURRENCY ID : ', error);
      setIsLoading(false);
      setDisplayNoTable(true);
    }
  }

  /**
   * This function, when called from a React component, asynchronously retrieves the currency ID associated with a client based on the specified borrower ID.
   * @returns A promise that resolves to the client's currency ID or null if not found.
   */
  const getClientCurrencyId = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/borrowers/${params.borrowerId}`,
        method: GET,
      });
      return response.data.currencyId;
    } catch (error) {
      console.log('GET CLIENT CURRENCY ID : ', error);
      setIsLoading(false);
      setDisplayNoTable(true);
    }
  };

  /**
   * This function asynchronously fetches and sets the exchange rate based on AR currency, client currency, and selected currency.
   * @returns Early return with the setting of exchange rate when currency is found on the first element of currencies array.
   */
  const fetchExchangeRate = async (borrowerId: number, arCollateralId: number, endDate: string, currencyId: number) => {
    const arCurrencyId = await getARCurrencyId()
    const clientCurrencyId  = await getClientCurrencyId()
    const completeIds = arCurrencyId && clientCurrencyId && currencyId;

    if (completeIds && `${currencyId}` === clientCurrencyId) {
      // Foreign (Client Currency) is selected
      const exchangeRate = await checkRate(arCollateralId, borrowerId, endDate);

      if (exchangeRate){
        setExchangeRate(exchangeRate.currencyRate)
        setRateId(exchangeRate.recordId)
      }
    } else {
      // Local (Collateral Currency) Selected
      setExchangeRate(1);
      setRateId(undefined);
    }
  }

  /**
   * This function returns an object with state setter functions based on the provided type, which is used to manage options, selected values, and input for various components.
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @returns An object containing state setter functions for options, selected value, and input.
   */
  const getSetStatesByType = (type: IComboBoxIds) => {
    switch (type) {
      case 'client': return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
      case 'arCollateral': return { setOptions: setCollaterals, setSelected: setSelectedCollateral, setInput: setCollateralInput };
      case 'currency': return { setOptions: setCurrencies, setSelected: setSelectedCurrency, setInput: setCurrencyInput };
      default: return { setOptions: setBBPeriods, setSelected: setSelectedBBPeriod, setInput: setBBPeriodInput };
    }
  };

  /**
   * This function sets the selected value for a component based on the provided type and manages associated state updates.
   * @param selected The selected option.
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @param setStates An object containing state setter functions for options, selected value, and input.
   */
  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    if (type === 'client') selectedClientContext?.setSelectedClient(selected);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);

    const key = getParamsByType(type, params).key;
    updateSearchParams(key, `${selected.recordId}`);
  };

  /**
   * This function handles the change event for a component, updates the selected value, and manages associated state changes based on the provided type.
   * @param _event The event object (unused in this implementation).
   * @param newValue The new selected option (or null if none selected).
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @param setSelected The state setter function for the selected value.
   */
  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null, type: IComboBoxIds, setSelected: Dispatch<SetStateAction<IOption | null>>) => {
    if (isUltimateParent) {
      searchParams.delete('bbPeriodId');
    }
    
    if (type === 'client') {
      resetStates('bbPeriod', getSetStatesByType('bbPeriod'));
      updateSearchParams(`endDate`, '0');

      resetStates('arCollateral', getSetStatesByType('arCollateral'));
      resetStates('currency', getSetStatesByType('currency'));
      const selected = selectedClientContext.clients.find(client => client.recordId === newValue?.recordId);
      selectedClientContext?.setSelectedClient(selected ?? null);
      setToCurrencyIds([]);
    }

    if (type === 'arCollateral') {
      resetStates('bbPeriod', getSetStatesByType('bbPeriod'));
      updateSearchParams(`endDate`, '0');
      
      resetStates('currency', getSetStatesByType('currency'));
    }

    if (type === 'bbPeriod') {
      if (isUltimateParent) {
        updateSearchParams('endDate', `${newValue?.recordId}`);
        setSelected(newValue);
        setToCurrencyIds([]);
        resetTable();
        return;
      } else {
        updateSearchParams(`endDate`, `${newValue?.label ? formatDate(newValue.label, 'YYYYMMDD') : 0}`);
        resetStates('currency', getSetStatesByType('currency'));
      }
    }

    updateSearchParams(getParamsByType(type, params).key, `${newValue?.recordId ?? 0}`);
    setSelected(newValue);
    resetTable();
  };

  /**
   * This function handles the input change event for a component and updates the input value based on the provided type.
   * @param _event The event object (unused in this implementation).
   * @param newInputValue The new input value.
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @param setInput The state setter function for the input value.
   */
  const onInputChange = (_event: SyntheticEvent<Element, Event>, newInputValue: string, type: IComboBoxIds, setInput: Dispatch<SetStateAction<string>>) => {
    setInput(newInputValue);
  };

  /**
   * This function resets the states (options, selected value, and input) for a component based on the provided type.
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @param setStates An object containing state setter functions for options, selected value, and input.
   */
  const resetStates = (type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setOptions([]);
    setStates.setSelected(null);
    setStates.setInput('');
    updateSearchParams(getParamsByType(type, params).key, '0');
  };

  /**
   * This function resets various state values related to a table, including roll forwards, summary data, beginning balance, and display flags.
   */
  const resetTable = () => {
    setRollForwards([]);
    setRfCalcSummary(undefined);
    setBeginningBalance(undefined);
    setClientRfReport(undefined);
    setClientVariances([]);
    setIdToNameMap(undefined);
    setDisplayNoTable(false);
    setFirstLoad(false);
  };

  /**
   * This function updates a specific key-value pair in the search parameters of the current URL.
   * @param key The key to update in the search parameters.
   * @param value The new value to set for the specified key.
   */
  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  /**
   * Retrieves collateral options and selected value based on the ultimate parent status.
   * @returns an object with 'options' array and 'value' representing selected collateral.
   */
  const getArCollateralOptionsAndValue = () => {
    const allOpt = {recordId: 0, label: 'All', default: true};

    if (!isUltimateParent) {
      return {
        options: collaterals,
        value: selectedCollateral,
      }
    } else {
      return {
        options: [allOpt],
        value: allOpt,
      }
    }
  };

  /**
   * This function returns a JSX component representing a table based on the state values including roll forward data, summary data, and display flags.
   * @returns A JSX component representing the table or null if conditions are not met.
   */
  const getTable = () => {
    if (isLoading) {
      return (<LinearProgress />);
    } else if (displayNoTable) {
      return (
        <Paper sx={styles.paperContainer}>
          <Typography tabIndex={0} align='center'>No data available</Typography>
        </Paper>
      )
    } else if (isUltimateParent && clientRfReport && idToNameMap) {
      return (
        <ClientRfReports
          clientRfReport={clientRfReport}
          setClientRfReport={setClientRfReport}
          idToNameMap={idToNameMap}
          bbPeriodLabel={selectedBBPeriod?.label ?? ''}
          showToaster={showToaster}
          currencyLabel={currencyLabel ?? ''}
          collateralRatesMap={collateralRatesMap}
          clientVariances={clientVariances}
          setClientVariances={setClientVariances}
        />
      )

    } else if (!isUltimateParent && rfCalcSummary && rollForwards.length) {
      return (
        <RollForwardTable
          rfCalcSummary={rfCalcSummary}
          setRfCalcSummary={setRfCalcSummary}
          rollForwards={rollForwards}
          beginningBalance={beginningBalance}
          setBeginningBalance={setBeginningBalance}
          showToaster={showToaster}
          currencyLabel={currencyLabel ?? ''}
          isUltimateParent={false}
          exchangeRate={exchangeRate ?? 1}
        />
      )
    } else return null;
  };

  /**
   * This function returns an object containing event handlers for change and input events based on the provided component type.
   * @param type The type of component (e.g., 'client', 'arCollateral', 'currency', 'bbPeriod').
   * @returns An object with event handlers for change and input events.
   */
  const getHandleChangeByType = (type: IComboBoxIds) => {
    return {
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IOption | null) => onChange(e, newValue, type, getSetStatesByType(type).setSelected),
      onInputChange: (e: SyntheticEvent<Element, Event>, newInputValue: string) => onInputChange(e, newInputValue, type, getSetStatesByType(type).setInput)
    }
  };

  /**
   * This function displays a toaster notification with the specified severity and message.
   * @param severity The severity level of the notification (e.g., 'success', 'error', 'warning', 'info').
   * @param message The message to be displayed in the notification.
   */
  const showToaster = (severity: AlertColor, message: string) => {
    setToasterProps({open: true, message, severity});
  };

  /**
   * This function hides the currently displayed toaster notification.
   */
  const hideToaster = () => {
    setToasterProps({...toasterProps, open: false});
  };

  /**
   * This function gets the display of the report
   * Based whether the client is an Ultimate Parent or not
   * @returns A JSX Component of the display of the report
   */
  const getReportDisplay = () => {
    if (isUltimateParent) {
      return getTable();
    } else {
      return (
        <>
        <Box sx={styles.varianceContainer}>
          <Typography tabIndex={0} sx={styles.varianceText} component='label' htmlFor='variance'>{rfCalcSummary ? 'Variance' : ''}</Typography>
          <Typography tabIndex={0} sx={styles.variance} component='label' id='variance' aria-label={rollForwards.length ? handleFormatCurrency(rfCalcSummary?.variance ?? 0, currencyLabel, exchangeRate) : ''}>
            {rollForwards.length ? handleFormatCurrency(rfCalcSummary?.variance ?? 0, currencyLabel, exchangeRate) : ''}
          </Typography>
        </Box>

        <ReportsTitle
          clientName={selectedClient?.label ?? ''}
          reportName='Roll Forward Report'
          bbPeriod={selectedBBPeriod?.label ?? ''}
        />

        { exchangeRate !== 1 && selectedCurrency && !isLoading && currencies.length > 1 && rollForwards.length > 0 &&
        <Grid container sx={styles.rateLabel}>
          <Typography sx={styles.textFont}>
            <Box component="span" fontWeight='bold' fontSize={14}>Exchange Rate </Box>
            <Box component="span" fontSize={14}>{`${exchangeRate} ${currencies[1]?.label.substring(0, 3)} = 1 ${currencies[0]?.label.substring(0, 3)}`}</Box>
          </Typography>
        </Grid>}
        {getTable()}
        </>
      )
    }
  }

  /**
   * This function determine which filetype of the AR Aging report should export to.
   * @param filetype Determine which file type of report should export to.
   */
  const handleSelect = (filetype: 'Excel' | 'PDF') => {
    const reportName = 'RollForward';

    const fileName: string = getFileName(
      filetype,
      reportName,
      formatDate(selectedBBPeriod?.label ?? '', 'MM/DD/YYYY'),
      selectedClient?.label,
      selectedCollateral?.label);

    if (isUltimateParent) {
      const fileNameToExport: string = checkFileNameIfExisting(exports, fileName, reportName);

      const additionalPayload = {
        setExporting,
        apiURL: getExportUrl(filetype),
        payloadData: getPayloadData(fileNameToExport)
      }
      updateExports(fileNameToExport, fileName, additionalPayload);
    } else {
      exportReport(
        setExporting,
        getExportUrl(filetype),
        getPayloadData(fileName),
        fileName
      )
    }
  }

  const updateExports = (filename: string, originalFileName: string, additionalPayload: any) => {
    const fileToExport: IExportReportWithPayload = {
      filename,
      status: 'loading',
      originalFileName,
      ...additionalPayload,
    }
    setExports([...exports, fileToExport]);
    setShow(true);
  }

  /**
   * This function checks if the user has the permission to export the report on time of exporting.
   * 
   * @param func Function that is used to export the report.
   * @param args Parameters need to run the export function.
   */
  const checkPermission = (func: Function, ...args: any[]) => {
    (async () => {
      try {
        const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_AR_AGING_REPORT)
        if (isPermitted) {
          func(...args)
        } else {
          showToaster('error', NO_PERMISSION_MSG);
        }
      } catch (error) {
        showToaster('error', 'Failed in checking permission to export the report!');
        console.log('CHECK PERMISSION EXPORT: ', error);
      }
    })();
  };

  /**
   * This function generates a Payload to be used on exported file.
   * 
   * @returns A Payload for the export file
   */
  const getPayloadData = (filename: string) => {
    return {
      borrowerId: selectedClient?.recordId,
      borrowerName: selectedClient?.label,
      arCollateralName: selectedCollateral?.label,
      endDate: formatDate(selectedBBPeriod?.label ?? '' , 'MMMM DD, YYYY'),
      rfCalcSummaryDTO: rfCalcSummary,
      rollForwardReportDTOs: rollForwards,
      currencyId: selectedCurrency?.recordId,
      rateId: rateId,
      isUltimateParent,
      idToNameMap,
      collateralRatesMap,
      upcRollForwardDTOs: clientRfReport,
      clientVariances,
      filename,
    }
  }

  /**
   * Determines whether the export functionality should be disabled based on certain conditions.
   * @returns True if export should be disabled, otherwise false.
   */
  const getDisabledExport = () => {
    const initialConds = isLoading || exporting || displayNoTable;

    if (isUltimateParent && clientRfReport) {
      return initialConds || Object.keys(clientRfReport).length === 0;
    } else {
      return initialConds || rollForwards.length === 0;
    }
  }

  /**
   * Generates the export URL based on the specified file type.
   * @param filetype The type of file to export ('Excel' or 'PDF').
   * @returns The export URL for the specified file type.
   */
  const getExportUrl = (filetype: 'Excel' | 'PDF') => {
    if (isUltimateParent) {
      return reportsAPI.rollForwardReport[`EXPORT_${filetype.toUpperCase()}_UPC`]
    } else {
      return reportsAPI.rollForwardReport[`EXPORT_${filetype.toUpperCase()}`];
    }
  }

  return (
    <Box>
      <Box sx={styles.pageContainer}>
        <Grid container flexDirection='row' justifyContent='space-between' alignItems='center' columnSpacing={1}>
          <Grid item xs={12} sm={6} lg={8} xl={8.3}>
            <Box sx={styles.breadcrumbsContainer}>
              <GeneralBreadcrumbs selectedText='Roll Forward' breadcrumbLinks={[{ linkText: 'Reports', route: '/reports' }]}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3.7} sx={styles.clientDropdownContainer}>
            <Box sx={styles.clientDropdownBox}>
              <RollForwardReportComboBox
                id='client'
                options={filteredClients}
                value={selectedClient}
                inputValue={clientInput}
                {...getHandleChangeByType('client')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.headerContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.headerTitle} data-testid="Roll Forward Report Header">Roll Forward Report</Typography>
        <Box sx={styles.headerActionWrapper}>
          {canExport &&
          <MenuButton 
            label='Export'
            options={[
              { label: 'Excel', handleSelect: () => checkPermission(handleSelect, 'Excel') },
              { label: 'PDF', handleSelect: () => checkPermission(handleSelect, 'PDF') }
            ]}
            buttonProps={{
              endIcon: exporting ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
              disabled: getDisabledExport(),
              size: 'medium',
              variant: 'outlined',
              'aria-label': 'Download the Report',
              sx: styles.headerButtons
            }}
          />
          }
          <Button
            size='medium'
            variant='outlined'
            data-testid='reports-go-back-button'
            aria-label='Go back'
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={handleNavigate}
            sx={styles.headerButtons}
          >
            {belowMediumBreakpoint ? null : 'Go back'}
          </Button>
        </Box>
      </Box>

      <Box sx={styles.pageContainer}>
        <Grid container columnSpacing={2} sx={styles.dropdownsContainer}>
          <Grid item xs={12} sm={3}>
            <RollForwardReportComboBox
              id='arCollateral'
              {...getArCollateralOptionsAndValue()}
              inputValue={collateralInput}
              disabled={isUltimateParent}
              {...getHandleChangeByType('arCollateral')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <RollForwardReportComboBox
              id='bbPeriod'
              options={bbPeriods}
              value={selectedBBPeriod}
              inputValue={bbPeriodInput}
              {...getHandleChangeByType('bbPeriod')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <RollForwardReportComboBox
              id='currency'
              options={currencies}
              value={selectedCurrency}
              inputValue={currencyInput}
              disabled={isUltimateParent}
              {...getHandleChangeByType('currency')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container justifyContent={belowSmallBreakpoint ? 'center' : 'flex-end'}>
              <DisabledComponentsContainer isDisabled={disabled}>
                <Button
                  variant='contained'
                  disabled={disabled}
                  aria-label={disabled ? 'Generate Report button disabled' : 'Generate Report'}
                  onClick={handleGenerate}
                  sx={styles.generateReportButton}
                  data-testid='generate-report-button'
                >
                  Generate Report
                </Button>
              </DisabledComponentsContainer>
            </Grid>
          </Grid>
        </Grid>
        {getReportDisplay()}
      </Box>
      <Toaster
        {...toasterProps}
        onCloseChange={hideToaster}
      />
      <WarningModal
        open={rateModalOpen}
        onClose={() => setRateModalOpen(false)}
        onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
        noDataIncluded
        issueType='error'
        issueMessages={['There is no exchange rate yet for the selected \'As of Date\' and collateral currency. Click Proceed to visit Exchange Rate page']}
      />
    </Box>
  )
}
export default RollForwardReport;