import colors from "../../../utility/colors";

const styles = {
  customerOrVendorName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '37ch'
  },
  customerOrVendorSrcId: {
    fontSize: '12px',
    color: colors.SECONDARY_TEXT
  },
  noPermissionBox: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
    padding: '15px'
  },
  titleSaveCancelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    marginLeft: '1rem',
  },
  title: {
    color: colors.PRIMARY,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  saveCancelContainer: {
    marginRight: '1rem',
    display: 'flex',
    columnGap: '0.5rem'
  },
  mainComponentsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    columnGap: '4rem',
  },
}
export default styles;