const styles = {
  messageContainer: {
    backgroundColor: '#F7F7F7',
    padding: '1rem',
    borderRadius: '8px',
  },
  messageText: {
    textAlign: 'center',
    fontSize: '14px',
  },
}

export default styles;
