import colors from "../../../../../utility/colors";

const styles = {
  parentRow: {
    cursor: 'pointer',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    }
  },
  parentSelectAndActionCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
  },
  parentNameCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'left',
  },
  parentArAmountCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'right',
  },
  arrowContainer: {
    display: 'flex',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  iconButtonProperties : {
    padding: '0px',
    color: colors.PRIMARY,
  },
  childTableCellContainer: {
    p: 0,
    borderBottom: 'none',
  },
  childrenHeaderSelectAndActionCell: {
    borderBottom: 'none',
    backgroundColor: colors.NEUTRAL_LIGHT,
    textAlign: 'center',
  },
  childrenHeaderNameCell: {
    textAlign: 'left',
    borderBottom: 'none',
    backgroundColor: colors.NEUTRAL_LIGHT,
  },
  childrenHeaderArAmountCell: {
    borderBottom: 'none',
    textAlign: 'right',
    backgroundColor: colors.NEUTRAL_LIGHT,
  },
  childrenHeaderTypography: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  custName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '37ch'
  },
  custSrcId: {
    fontSize: '12px',
    color: colors.SECONDARY_TEXT
  },
  parentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5ch'
  },
  renameParent: {
    '& .MuiInputBase-input': {
      fontSize: '14px',
      padding: '4px 8px',
    },
    '& .MuiFormHelperText-root': {
      margin: '4px 0 0',
    },
  }
};
export default styles;