import { useState } from "react";
import { IARCustomer, IARVendor } from "../../../interfaces";
import { OptionalObjectSchema } from "yup/lib/object";
import PopUpModal from "../../../components/modals/pop-up-modal";
import { MODAL_WIDTH } from "../../../utility/constants";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import ConfirmModal from "../../../components/modals/confirm-modal";
import { Box, Button, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import DisabledComponentsContainer from "../../../components/common/disabled-components-container";
import VendorInfo from "./vendor-info";
import CustomerInfo from "./customer-info";

export interface CustomerVendorModalProps {
  open: boolean;
  isEditting: boolean;
  selectedCustomerVendor: IARCustomer | IARVendor;
  onEdit?: () => void;
  onSave: (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => void | Promise<any>;
  onClose: () => void;
  schema: OptionalObjectSchema<any>;
  createCustomer?: boolean;
}

const CustomerVendorModal = (props: CustomerVendorModalProps) => {
  const {
    open,
    isEditting,
    selectedCustomerVendor,
    onEdit,
    onSave,
    onClose,
    schema,
    createCustomer
  } = props;

  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down('w720'));
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const formikRef = (node: FormikProps<IARCustomer | IARVendor>) => {
    if (node === null) { return; }
    setIsDirty(node.dirty);
    setIsSubmitting(node.isSubmitting);
  }
  
  const isVendor = (obj: any): obj is IARVendor => {
    return 'vendorName' in obj;
  }

  const getEditModalTitle = () => {
    if (isVendor(selectedCustomerVendor)) return 'Edit Vendor';
    else if (createCustomer) return 'Create Customer';
    else return 'Edit Customer';
  }

  const getViewModalTitle = () => {
    if (isVendor(selectedCustomerVendor)) return 'View Vendor';
    else return 'View Customer';
  }

  const handleClosePrompt = () => {
    setShowPrompt(false);
  }
  
  const handleCloseModal = () => {
    if (isDirty) {
      setShowPrompt(true);
    } else {
      handleCloseAll();
    }
  }

  const handleCloseAll = () => {
    handleClosePrompt();
    onClose();
  }

  return (
    <PopUpModal
      open={open}
      onClose={handleCloseModal}
      isComponentEditable={!createCustomer}
      handleEditClick={onEdit}
      isEditableTrue={isEditting}
      isNotDeletable={true}
      title1={getEditModalTitle()}
      title2={getViewModalTitle()}
      width={MODAL_WIDTH}
      isCloseDisabled={isSubmitting}
    >
      <Box sx={styles.formContainer}>
        <Formik
          enableReinitialize
          initialValues={selectedCustomerVendor}
          validationSchema={schema}
          onSubmit={onSave}
          innerRef={formikRef}
        >
          {(formik) => {
            return (
              <Form>
                <Box sx={{
                  ...styles.infoContainer,
                  ...(belowBreakPoint && { flexDirection: 'column' })
                }}>
                  {isVendor(selectedCustomerVendor) ?
                    <VendorInfo
                      {...props}
                      formik={formik}
                      isEditting={isEditting}
                      belowBreakPoint={belowBreakPoint}
                    /> :
                    <CustomerInfo
                      {...props}
                      formik={formik}
                      isEditting={isEditting}
                      belowBreakPoint={belowBreakPoint}
                    />
                  }
                </Box>
                <Box sx={styles.buttonGroup}>
                  <Button
                      data-testid='cancel-button'
                      variant='outlined'
                      disableElevation
                      onClick={handleCloseModal}
                      sx={styles.cancelButton}
                      disabled={formik.isSubmitting}
                    >
                      Cancel
                    </Button>
                    {formik.isSubmitting ? 
                      <DisabledComponentsContainer isDisabled={formik.isSubmitting}>
                        <Button
                          data-testid='submit-button'
                          variant='contained'
                          type='submit'
                          disableElevation
                          disabled={formik.isSubmitting}
                          aria-label='Saving changes'
                          sx={styles.saveButton}
                        >
                          <CircularProgress size={15} />
                        </Button>
                      </DisabledComponentsContainer>
                    :
                      <DisabledComponentsContainer isDisabled={!(formik.dirty && formik.isValid)}>
                        <Button
                          data-testid='submit-button'
                          variant='contained'
                          type='submit'
                          disableElevation
                          disabled={!(formik.dirty && formik.isValid)}
                          aria-label={!(formik.dirty && formik.isValid) ? 'Save changes button disabled' : 'Save changes'}
                          sx={styles.saveButton}
                        >
                          Save Changes
                        </Button>
                      </DisabledComponentsContainer>}
                </Box>
                <ConfirmModal
                  open={showPrompt}
                  onClose={handleCloseAll}
                  onConfirm={handleClosePrompt}
                  onButtonClose={handleClosePrompt}
                  promptChecker
                  title='Confirm Navigation'
                  description='You have unsaved changes. Are you sure you want to leave this page?'
                  yesButtonText='Keep Editing'
                  noButtonText='Discard Changes'
                  confirmOnly
                />
              </Form>
            )
          }}

        </Formik>
      </Box>
    </PopUpModal>
  )
}

export default CustomerVendorModal;