import { CustomerRowProps } from "..";
import { IARCustomer } from "../../../../../../interfaces";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { formatCurrency } from "../../../../../../utility/helper";
import { getNameAndSrcId } from "../../..";
import styles from "./styles";
import NewTag from "../../../../../../components/common/new-tag";

export interface ChildRowProps extends CustomerRowProps {
  index: number;
  childCustomer: IARCustomer;
  handleOnDrop: () => void;
}

const ChildRow = (props: ChildRowProps) => {
  const {
    index,
    childCustomer,
    customerWithChildAndVendors,
    handleOnDrop
  } = props;

  // Rendering the Component
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        bgcolor: '#F7F7F7'
      }}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`${customerWithChildAndVendors.custName.toLowerCase()}-child-list-item-${index}`}
    >
      <TableCell sx={styles.tableDataDragAndActionCell}>
      </TableCell>
      <TableCell sx={styles.tableDataNameCell}>
        <Box sx={styles.nameAndTagContainer}>
          <NewTag additionalStyles={childCustomer.isNew ? undefined : { visibility: 'hidden'}}/>
          <Box sx={styles.tableDataNameBox}>
            {getNameAndSrcId(childCustomer, 'customer')}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataArAmountCell}>
        <Typography tabIndex={0} sx={styles.tableDataTypography}>
          {childCustomer.arAmount ?
            formatCurrency(childCustomer.arAmount) :
            '-'}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableDataDragAndActionCell}>
      </TableCell>
    </TableRow>
  )
}

export default ChildRow;