import { DragIndicator, IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import { Box, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { IARCustomer } from "../../../../../../interfaces";
import { formatCurrency, isOddNumber } from "../../../../../../utility/helper";
import styles from "./styles";
import { getCustNameAndCustSrcId } from "../../..";
import { ParentRowProps } from "..";
import { useContext } from "react";
import { EditParentChildContext, IEditParentChildContext } from "../../../../../../context/editParentChildContext";

export interface ChildRowProps extends ParentRowProps {
  index: number;
  childCustomer: IARCustomer;
  handleOnDrop: () => void;
}

const ChildRow = (props: ChildRowProps) => {
  const {
    index,
    childCustomer,
    parentWithChild,
    handleOnDrop
  } = props;

  // Contexts
  const {
    setSelectedParentAndChild,
    vendorConfirmModalOpen,
    lastChildConfirmModalOpen,
    setLastChildConfirmModalOpen,
    childRemoveConfirmModalOpen,
    setChildRemoveConfirmModalOpen,
    createParentModalOpen,
    setSelectedCustomers,
    isDragging,
    setIsDragging,
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  // Rendering the Component
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        bgcolor: isOddNumber(index) ?
          'background.paper' :
          '#F7F7F7'
      }}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`${parentWithChild.custName.toLowerCase()}-child-list-item-${index}`}
    >
      <TableCell sx={styles.tableDataDragAndActionCell}>
        <Box sx={styles.tableDataDragContainer}>
          <Tooltip
            title='Drag and drop to remove from this parent'
            PopperProps={{
              sx: isDragging ? styles.noTooltip : null
            }}
          >
            <IconButton
              draggable
              edge='end'
              aria-label='Drag and drop icon'
              onDragStart={(event) => {
                setIsDragging(true);
                setSelectedParentAndChild({
                  selectedParent: parentWithChild,
                  selectedChildren: [childCustomer]
                });

                // Create ghost image
                const dragGhostContainer = document.createElement('div');
                Object.assign(dragGhostContainer.style, styles.ghostImageContainer);

                const dragGhost = document.createElement('div');
                dragGhost.textContent = childCustomer.custName!;
                Object.assign(dragGhost.style, styles.ghostImage);
            
                dragGhostContainer.appendChild(dragGhost);

                document.body.appendChild(dragGhostContainer);

                event.dataTransfer.setDragImage(dragGhostContainer, -20, 25);

                setTimeout(() => {
                  document.body.removeChild(dragGhostContainer);
                }, 0);
              }}
              onDragEnd={() => {
                setIsDragging(false);
                if (!(
                  vendorConfirmModalOpen ||
                  lastChildConfirmModalOpen ||
                  childRemoveConfirmModalOpen ||
                  createParentModalOpen
                )) {
                  setSelectedParentAndChild(null);
                  setSelectedCustomers([]);
                }
              }}
              sx={styles.dragButton} 
            >
              <DragIndicator/>
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataNameCell}>
        <Box sx={styles.tableDataNameBox}>
          {getCustNameAndCustSrcId(childCustomer)}
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataArAmountCell}>
        <Typography tabIndex={0} sx={styles.tableDataTypography}>
          {childCustomer.arAmount ?
            formatCurrency(childCustomer.arAmount) :
            '-'}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableDataDragAndActionCell}>
        <IconButton
          aria-label='Remove icon'
          data-testid='remove-child-button'
          sx={styles.removeButton}
          onClick={() => {
            const currentSelectedParentAndChild = {
              selectedParent: parentWithChild,
              selectedChildren: [childCustomer]
            };
            setSelectedParentAndChild(currentSelectedParentAndChild);

            // Check first if last child
            if (parentWithChild.childCustomers.length < 2) {
              setLastChildConfirmModalOpen(true);
            } else {
              setChildRemoveConfirmModalOpen(true);
            }
          }}
        >
          <IndeterminateCheckBoxOutlined/>
        </IconButton> 
      </TableCell>
    </TableRow>
  )
}

export default ChildRow;