import { useContext } from "react";
import { CustomerRowProps } from "..";
import { IARVendor } from "../../../../../../interfaces";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../../../../context/editVendorCustomerContext";
import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { formatCurrency } from "../../../../../../utility/helper";
import { getNameAndSrcId } from "../../..";
import styles from "./styles";
import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import NewTag from "../../../../../../components/common/new-tag";

export interface VendorRowProps extends CustomerRowProps {
  index: number;
  vendor: IARVendor;
  handleOnDrop: () => void;
}

const VendorRow = (props: VendorRowProps) => {
  const {
    index,
    vendor,
    customerWithChildAndVendors,
    handleOnDrop
  } = props;

  // Contexts
  const {
    setSelectedCustomerWithChildAndVendor,
    setVendorRemoveConfirmModalOpen,
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // Rendering the Component
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        bgcolor: 'background.paper'
      }}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`${customerWithChildAndVendors.custName.toLowerCase()}-vendor-list-item-${index}`}
    >
      <TableCell sx={styles.tableDataDragAndActionCell}>
      </TableCell>
      <TableCell sx={styles.tableDataNameCell}>
        <Box sx={styles.nameAndTagContainer}>
          <NewTag additionalStyles={vendor.isNew ? undefined : { visibility: 'hidden'}}/>
          <Box sx={styles.tableDataNameBox}>
            {getNameAndSrcId(vendor, 'vendor')}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataArAmountCell}>
        <Typography tabIndex={0} sx={styles.tableDataTypography}>
          {vendor.apAmount ?
            formatCurrency(vendor.apAmount) :
            '-'}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableDataDragAndActionCell}>
        <IconButton
          aria-label='Remove icon'
          data-testid='remove-vendor-button'
          sx={styles.removeButton}
          onClick={() => {
            const currentSelectedParentAndChild = {
              selectedCustomer: customerWithChildAndVendors,
              selectedVendors: [vendor]
            };
            setSelectedCustomerWithChildAndVendor(currentSelectedParentAndChild);
            setVendorRemoveConfirmModalOpen(true);
          }}
        >
          <IndeterminateCheckBoxOutlined/>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default VendorRow;