import { Box, InputAdornment, LinearProgress, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import styles from './styles';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ParentRow from './parent-row';
import { ISelectedParentAndChild, IParentWithChild } from '../../../../interfaces/editParentChildInterface';
import { IARCustomer, IARVendor } from '../../../../interfaces';
import _ from 'lodash';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import { Circle } from '@mui/icons-material';
import { FormikHelpers, FormikProps } from 'formik';
import { MainSectionProps } from '..';
import { EditParentChildContext, IEditParentChildContext } from '../../../../context/editParentChildContext';
import useManageParentChild from '../../../../utility/custom-hooks/useManageParentChild';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import CustomerVendorModal from '../../customer-vendor-modal';
import customerSchema from '../../../../schemas/customerSchema';
import NoDataPlaceholder from '../../../../components/common/no-data-placeholder';

// Interfaces
export interface ParentsSectionProps extends MainSectionProps {

}

// Constants
const searchFilters = ['All', 'Parent', 'Child'];

const ParentsSection = (props: ParentsSectionProps) => {
  const { formik, tableContainerRef, isUltimateParent, selectedARCollateral } = props;

  // Contexts
  const {
    initialCustomers,
    parentsList,
    setParentsList,
    filteredParentsList,
    setFilteredParentsList,
    orphansList,
    setOrphansList,
    filteredOrphansList,
    setFilteredOrphansList,
    isFetching,
    selectedParentAndChild,
    setSelectedParentAndChild,
    vendorConfirmModalOpen,
    setVendorConfirmModalOpen,
    lastChildConfirmModalOpen,
    setLastChildConfirmModalOpen,
    childRemoveConfirmModalOpen,
    setChildRemoveConfirmModalOpen,
    droppedInParent,
    setDroppedInParent,
    triggerResetSearch,
    setSelectedCustomers,
    isDragging,
    createParentModalOpen,
    setCreateParentModalOpen,
    deleteParentModalOpen,
    setDeleteParentModalOpen,
    updatedCustomerList,
    setUpdatedCustomerList,
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  const { selectedClient } = useContext(SelectedClientContext);

  // Hooks
  const { addChild, removeChild, removeOrphans, replaceChild } = useManageParentChild();

  // States
  const [selectedSearch, setSelectedSearch] = useState<string>(searchFilters[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);

  // Effects
  useEffect(() => {
    setSelectedSearch(searchFilters[0]);
    setSearchValue('');
    setPage(0);
  }, [triggerResetSearch])
  
  // Memos
  const rowsPerPage = useMemo(() => 10, []);

  const hasMore = useMemo(() => {
    return filteredParentsList.slice(0, page * rowsPerPage + rowsPerPage).length < filteredParentsList.length;
  }, [filteredParentsList, page, rowsPerPage]);

  // Callbacks
  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    // Current implementation does not cover this branch on any cases
    // Will comment out for future references:
    // if (isFetching) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [hasMore]); // remove isFetching from dependency

  // Functions
  const triggerSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);

    let debounce = _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const filtered = handleSearch(event.target.value);
      setFilteredParentsList(filtered);
    }, 500);
    debounce(event);
  };

  const handleSearch = (searchValue: string) => {
    const loweredSearchValue = searchValue.toLowerCase().trim();
    if (loweredSearchValue === '') return parentsList;
    return filterParents(selectedSearch, loweredSearchValue);
  }

  const filterParents = (selectedSearch: string, loweredSearchValue: string) => {
    if (selectedSearch === 'All') {
      return parentsList.filter(parent => 
        parent.custName.toLowerCase().includes(loweredSearchValue) ||
        parent.childCustomers.some(customer => 
          customer.custName?.toLowerCase().includes(loweredSearchValue) ||
          customer.custSrcId?.toLowerCase().includes(loweredSearchValue)
        )
      )
    } else if (selectedSearch === 'Parent') {
      return parentsList.filter(parent => 
        parent.custName.toLowerCase().includes(loweredSearchValue)
      )
    } else {
      return parentsList.filter(parent => {
        
        const filteredChildCustomers: IARCustomer[] = parent.childCustomers.filter(customer => 
          customer.custName?.toLowerCase().includes(loweredSearchValue) ||
          customer.custSrcId?.toLowerCase().includes(loweredSearchValue)
        );

        return filteredChildCustomers.length > 0;
      })
    }
  }

  // ----------------- REMOVE LAST PARENT -----------------

  const handleConfirmRemoveParent = () => {
    const { selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;
    const newParent = droppedInParent;
    
    if (newParent === null) {
      // Child to Orphan
      handleRemoveParentToOrphan(selectedChildren);
    } else if (newParent === 'new') {
      handleRemoveParentToNewParent(selectedChildren);
    } else if (selectedChildren.some(child => child.hasVendorsLinked)) {
      setVendorConfirmModalOpen(true);
    } else {
      handleRemoveParentToOtherParent(selectedChildren, newParent);
    }
  }

  const handleRemoveParentToOrphan = (
    selectedChildren: IARCustomer[]
  ) => {
    const {
      currentCustomersToEdit,
      orphanCustomers
    } = getCustomersToEditAndOrphans(selectedChildren);

    const newParentsList = removeChild(parentsList, selectedParentAndChild!);
    
    const {
      currentCustomersToEditParentCustomers,
      currentParentCustomersToSaveParentCustomers,
      currentParentCustomersToDeleteParentCustomers
    } = getParentCustomersToEditDeleteSave(
      newParentsList,
      currentCustomersToEdit,
      formik.values.parentCustomersToSave,
      formik.values.parentCustomersToDelete
    );

    formik.setFieldValue('customersToEdit', currentCustomersToEditParentCustomers);
    formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSaveParentCustomers);
    formik.setFieldValue('parentCustomersToDelete', currentParentCustomersToDeleteParentCustomers);

    // Updating the display
    // Parents List
    setParentsList(
      newParentsList.filter(parent => parent.childCustomers.length > 0)
    );

    setFilteredParentsList(
      removeChild(filteredParentsList, selectedParentAndChild!)
        .filter(parent => parent.childCustomers.length > 0)
    );

    // Orphans List
    setOrphansList([...orphansList, ...orphanCustomers]);
    setFilteredOrphansList([...filteredOrphansList, ...orphanCustomers]);

    // Cleanup
    setSelectedParentAndChild(null);
    setLastChildConfirmModalOpen(false);
  }

  const getCustomersToEditAndOrphans = (
    selectedChildren: IARCustomer[],
  ) => {
    // Updating customersToEdit
    const currentCustomersToEdit = [...formik.values.customersToEdit];
    const orphanCustomers: IARCustomer[] = [];

    for (const selectedChild of selectedChildren) {
      const orphaned: IARCustomer = {
        ...selectedChild,
        parentARCustomerId: undefined,
        parentCustSrcId: undefined,
        parentCustName: undefined,
        upcParentCustId: undefined,
        upcParentCustSrcId: undefined,
        upcParentCustName: undefined,
      }
      orphanCustomers.push(orphaned);

      const existingCustomerIndex = currentCustomersToEdit.findIndex(
        (customer) => customer.recordId === selectedChild.recordId
      );

      if (existingCustomerIndex !== -1) {
        currentCustomersToEdit[existingCustomerIndex] = {
          ...currentCustomersToEdit[existingCustomerIndex],
          parentARCustomerId: undefined,
          parentCustSrcId: undefined,
          parentCustName: undefined,
          upcParentCustId: undefined,
          upcParentCustSrcId: undefined,
          upcParentCustName: undefined,
        };
      } else {
        currentCustomersToEdit.push(orphaned);
      }
    }

    return ({ currentCustomersToEdit, orphanCustomers });
  }

  const getParentCustomersToEditDeleteSave = (
    newParentsList: IParentWithChild[],
    currentCustomersToEdit: IARCustomer[],
    currentParentCustomersToSave: IARCustomer[],
    currentParentCustomersToDelete: IARCustomer[],
  ) => {
    const currentCustomersToEditParentCustomers = [...currentCustomersToEdit]; // to ensure encapsulation
    const currentParentCustomersToSaveParentCustomers = [...currentParentCustomersToSave];
    const currentParentCustomersToDeleteParentCustomers = [...currentParentCustomersToDelete];

    const parentsToDelete = newParentsList.filter(parent => parent.childCustomers.length === 0);
    
    for (const parent of parentsToDelete) {
      const existingParentToAddIndex = currentParentCustomersToSaveParentCustomers.findIndex(
        (customer) => customer.custSrcId === parent.custSrcId
      );

      if (existingParentToAddIndex !== -1) {
        currentParentCustomersToSaveParentCustomers.splice(existingParentToAddIndex, 1);
      } else {
        // Check if parent is in the customersToEdit (Renaming)
        const existingCustomerToEditIndex = currentCustomersToEditParentCustomers.findIndex(
          (customer) => customer.custSrcId === parent.custSrcId
        );

        if (existingCustomerToEditIndex !== -1) {
          currentCustomersToEditParentCustomers.splice(existingCustomerToEditIndex, 1);
        }

        // Add customer to Parents to be Deleted
        const customerRecordOfParent = initialCustomers.find(customer =>
          customer.custSrcId === parent.custSrcId
        )
        
        if (customerRecordOfParent) {
          currentParentCustomersToDeleteParentCustomers.push(customerRecordOfParent);
        }
      }
    }

    return ({
      currentCustomersToEditParentCustomers,
      currentParentCustomersToSaveParentCustomers,
      currentParentCustomersToDeleteParentCustomers
    });
  }

  const handleRemoveParentToNewParent = (selectedChildren: IARCustomer[]) => {
    if (selectedChildren.some(child => child.hasVendorsLinked)) {
      setVendorConfirmModalOpen(true);
    } else {
      createUpdatedCustomerList(initialCustomers, formik);
      setCreateParentModalOpen(true);
      setDroppedInParent(null);
      setLastChildConfirmModalOpen(false);
    }
  }

  const handleRemoveParentToOtherParent = (
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild,
  ) => {
    const { currentCustomersToEdit } = getCustomersToEdit(
      selectedChildren,
      newParent,
      formik.values.customersToEdit
    );

    const newParentsList = replaceChild(parentsList, newParent, selectedParentAndChild!);

    const {
      currentCustomersToEditParentCustomers,
      currentParentCustomersToSaveParentCustomers,
      currentParentCustomersToDeleteParentCustomers
    } = getParentCustomersToEditDeleteSave(
      newParentsList,
      currentCustomersToEdit,
      formik.values.parentCustomersToSave,
      formik.values.parentCustomersToDelete,
    );
    
    formik.setFieldValue('customersToEdit', currentCustomersToEditParentCustomers);
    formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSaveParentCustomers);
    formik.setFieldValue('parentCustomersToDelete', currentParentCustomersToDeleteParentCustomers);

    // Updating the display
    // Parents List
    setParentsList(
      newParentsList.filter(parent => parent.childCustomers.length > 0)
    );

    setFilteredParentsList(
      replaceChild(filteredParentsList, newParent, selectedParentAndChild!)
        .filter(parent => parent.childCustomers.length > 0)
    );

    setDroppedInParent(null);
    setSelectedParentAndChild(null);
    setLastChildConfirmModalOpen(false);
  }

  // ----------------- VENDOR LINKING -----------------

  const handleVendorLinking = () => {
    const { selectedParent, selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;
    const newParent = droppedInParent;

    if (newParent === 'new') {
      createUpdatedCustomerList(initialCustomers, formik);
      setCreateParentModalOpen(true);
    } else {
      handleVendorLinkingToOtherParent(selectedParent, selectedChildren, newParent);
    }
  }

  const handleVendorLinkingToOtherParent = (
    selectedParent: IParentWithChild | null,
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild | null,
  ) => {
    const { currentCustomersToEdit } = getCustomersToEdit(
      selectedChildren,
      newParent,
      formik.values.customersToEdit,
    );

    if (selectedParent === null) {
      formik.setFieldValue('customersToEdit', currentCustomersToEdit);

      // Update the display
      // Parents List
      setParentsList(addChild(parentsList, newParent!, selectedParentAndChild!));
      setFilteredParentsList(addChild(filteredParentsList, newParent!, selectedParentAndChild!));

      // Orphans List
      setOrphansList(removeOrphans(orphansList, selectedParentAndChild!));
      setFilteredOrphansList(removeOrphans(filteredOrphansList, selectedParentAndChild!));
      setSelectedCustomers([]);
    } else {
      // From Child to Other Parent
      const newParentsList = replaceChild(parentsList, newParent!, selectedParentAndChild!);
      
      const {
        currentCustomersToEditParentCustomers,
        currentParentCustomersToSaveParentCustomers,
        currentParentCustomersToDeleteParentCustomers
      } = getParentCustomersToEditDeleteSave(
        newParentsList,
        currentCustomersToEdit,
        formik.values.parentCustomersToSave,
        formik.values.parentCustomersToDelete,
      );

      formik.setFieldValue('customersToEdit', currentCustomersToEditParentCustomers);
      formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSaveParentCustomers);
      formik.setFieldValue('parentCustomersToDelete', currentParentCustomersToDeleteParentCustomers);

      // Updating the display
      setParentsList(
        newParentsList.filter(parent => parent.childCustomers.length > 0)
      );
      
      setFilteredParentsList(
        replaceChild(filteredParentsList, newParent!, selectedParentAndChild!)
          .filter(parent => parent.childCustomers.length > 0)
      );
    }

    setSelectedParentAndChild(null);
    setDroppedInParent(null);
    setVendorConfirmModalOpen(false);
    setLastChildConfirmModalOpen(false);
  }

  const getCustomersToEdit = (
    selectedChildren: IARCustomer[],
    newParent: IParentWithChild | null,
    customersToEdit: IARCustomer[],
  ) => {
    const currentCustomersToEdit = [...customersToEdit];
    
    for (const selectedChild of selectedChildren) {
      // Customers To Edit
      const existingCustomerIndex = currentCustomersToEdit.findIndex(
        (customer) => customer.recordId === selectedChild.recordId
      );

      if (existingCustomerIndex !== -1) {
        currentCustomersToEdit[existingCustomerIndex] = {
          ...currentCustomersToEdit[existingCustomerIndex],
          parentARCustomerId: undefined,
          parentCustSrcId: isUltimateParent ? undefined : newParent?.custSrcId,
          parentCustName: isUltimateParent ? undefined : newParent?.custName,
          upcParentCustId: undefined,
          upcParentCustSrcId: isUltimateParent ? newParent?.custSrcId : undefined,
          upcParentCustName: isUltimateParent ? newParent?.custName : undefined,
        };
      } else {
        const addedCustomer: IARCustomer = {
          ...selectedChild,
          parentARCustomerId: undefined,
          parentCustSrcId: isUltimateParent ? undefined : newParent?.custSrcId,
          parentCustName: isUltimateParent ? undefined : newParent?.custName,
          upcParentCustId: undefined,
          upcParentCustSrcId: isUltimateParent ? newParent?.custSrcId : undefined,
          upcParentCustName: isUltimateParent ? newParent?.custName : undefined,
        };
        currentCustomersToEdit.push(addedCustomer);
      }
    }

    return ({ currentCustomersToEdit });
  }

  // ----------------- REMOVE CHILD ----------------- 

  const handleConfirmRemoveChild = () => {
    const { selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;

    const {
      currentCustomersToEdit,
      orphanCustomers
    } = getCustomersToEditAndOrphans(selectedChildren);

    formik.setFieldValue('customersToEdit', currentCustomersToEdit);

    // Updating the parentsList
    setParentsList(removeChild(parentsList, selectedParentAndChild!));
    setFilteredParentsList(removeChild(filteredParentsList, selectedParentAndChild!));

    // Updating the orphansList
    setOrphansList([...orphansList, ...orphanCustomers]);
    setFilteredOrphansList([...filteredOrphansList, ...orphanCustomers]);
  }

  // ----------------- CREATE NEW PARENT ----------------- 
  const createUpdatedCustomerList = (
    initialCustomers: IARCustomer[],
    formik: FormikProps<{
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }>
  ) => {
    const {
      customersToEdit,
      parentCustomersToSave,
      parentCustomersToDelete,
    } = formik.values;

    const updatedCustomers = initialCustomers
      .reduce((accumulator, customer) => {
        // Edited Customer (Including renamed existing parent)
        const editedCustomer = customersToEdit.find(edited => edited.custSrcId === customer.custSrcId);
        if (editedCustomer) {
          accumulator.push(editedCustomer);
          return accumulator;
        }
        
        // Parent Customer to Delete
        const customerToDelete = parentCustomersToDelete.find(toDelete => toDelete.custSrcId === customer.custSrcId);
        if (!customerToDelete) {
          accumulator.push(customer);
          return accumulator;
        }

        return accumulator;
      }, [] as IARCustomer[]);

    updatedCustomers.push(...parentCustomersToSave);
    setUpdatedCustomerList(updatedCustomers);
  }

  const handleCreateParent = (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    const customerValues = values as IARCustomer;

    const newParent: IARCustomer = {
      borrowerId: selectedClient?.recordId,
      arCollateralId: !isUltimateParent ? selectedARCollateral?.recordId : undefined,
      custName: isUltimateParent ? customerValues.upcParentCustName : customerValues.parentCustName,
      custSrcId: isUltimateParent ? customerValues.upcParentCustSrcId : customerValues.parentCustSrcId,
      custAddress1: customerValues.custAddress1,
      custAddress2: customerValues.custAddress2,
      custCity: customerValues.custCity,
      custCountry: customerValues.custCountry,
      custPhone: customerValues.custPhone,
      custState: customerValues.custState,
      custPostalCode: customerValues.custPostalCode,
      custDescription: customerValues.custDescription,
      isCustParent: !isUltimateParent,
      isUpcParent: isUltimateParent,
      isNew: true,
      archive: false,
    }

    const {
      currentCustomersToEditNewParent,
      currentParentCustomersToSaveNewParent,
      currentParentCustomersToDeleteNewParent,
    } = createNewParent(
      newParent,
      formik.values.customersToEdit,
      formik.values.parentCustomersToSave,
      formik.values.parentCustomersToDelete,
    )

    // No need to check in initial customers, customers to edit, and parent customers to save
    // INVARIANCE: The new parent will not have any matching custSrcId from
    //             the initial customers, customers to edit, and parent customers to save
    //             since it is validated from the creation of the parent


    // Connect children to the new parent
    const { selectedParent, selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;

    const {
      currentCustomersToEditConnectedChildren,
      connectedChildren,
    } = connectChildrenToNewParent(
      newParent,
      selectedChildren,
      currentCustomersToEditNewParent
    )

    const newParentWithChild: IParentWithChild = {
      custSrcId: newParent.custSrcId!,
      custName: newParent.custName!,
      childCustomers: connectedChildren,
    }

    const parentsListWithCreatedParent = [...parentsList, newParentWithChild];
    const filteredParentsListWithCreatedParent = [...filteredParentsList, newParentWithChild];

    if (selectedParent === null) {
      // From Orphan to New Parent
      formik.setFieldValue('customersToEdit', currentCustomersToEditConnectedChildren);
      formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSaveNewParent);
      formik.setFieldValue('parentCustomersToDelete', currentParentCustomersToDeleteNewParent);

      // Update the parent display
      setParentsList(parentsListWithCreatedParent);
      setFilteredParentsList(filteredParentsListWithCreatedParent);

      // Update the orphan display
      setOrphansList(removeOrphans(orphansList, selectedParentAndChild!));
      setFilteredOrphansList(removeOrphans(filteredOrphansList, selectedParentAndChild!));
      setSelectedCustomers([]);
    } else {
      // From Child to New Parent
      // Updating the parentsList    
      const newParentsList = removeChild(parentsListWithCreatedParent, selectedParentAndChild!);

      const {
        currentCustomersToEditParentCustomers,
        currentParentCustomersToSaveParentCustomers,
        currentParentCustomersToDeleteParentCustomers
      } = getParentCustomersToEditDeleteSave(
        newParentsList,
        currentCustomersToEditConnectedChildren,
        currentParentCustomersToSaveNewParent,
        currentParentCustomersToDeleteNewParent,
      );

      formik.setFieldValue('customersToEdit', currentCustomersToEditParentCustomers);
      formik.setFieldValue('parentCustomersToSave', currentParentCustomersToSaveParentCustomers);
      formik.setFieldValue('parentCustomersToDelete', currentParentCustomersToDeleteParentCustomers);

      // Updating the display
      setParentsList(
        newParentsList.filter(parent => parent.childCustomers.length > 0)
      );
      
      setFilteredParentsList(
        removeChild(filteredParentsListWithCreatedParent, selectedParentAndChild!)
          .filter(parent => parent.childCustomers.length > 0)
      );
    }

    formikHelpers.setSubmitting(false);
    handleClose();
  }

  const createNewParent = (
    newParent: IARCustomer,
    currentCustomersToEdit: IARCustomer[],
    currentParentCustomersToSave: IARCustomer[],
    currentParentCustomersToDelete: IARCustomer[],
  ) => {
    const currentCustomersToEditNewParent = [...currentCustomersToEdit];
    const currentParentCustomersToDeleteNewParent = [...currentParentCustomersToDelete];
    const currentParentCustomersToSaveNewParent = [...currentParentCustomersToSave];

    const existingParentToDeleteIndex = currentParentCustomersToDeleteNewParent.findIndex(customer =>
      customer.custSrcId === newParent.custSrcId
    );
    
    if (existingParentToDeleteIndex !== -1) {
      // remove from customers to delete
      currentParentCustomersToDeleteNewParent.splice(existingParentToDeleteIndex, 1);

      // add to customersToEdit
      const initialRecordOfTheParent = initialCustomers.find(customer => 
        customer.custSrcId === newParent.custSrcId
      );

      if (initialRecordOfTheParent) {
        currentCustomersToEditNewParent.push(
          { ...initialRecordOfTheParent, ...newParent }
        );
      }
    } else {
      // If not -> add to parent customers to save
      currentParentCustomersToSaveNewParent.push(newParent);
    }

    return ({
      currentCustomersToEditNewParent,
      currentParentCustomersToSaveNewParent,
      currentParentCustomersToDeleteNewParent,
    });
  }

  const connectChildrenToNewParent = (
    newParent: IARCustomer,
    selectedChildren: IARCustomer[],
    currentCustomersToEdit: IARCustomer[],
  ) => {
    // Updating Customers & Vendors To Edit
    const currentCustomersToEditConnectedChildren = [...currentCustomersToEdit];
    let connectedChildren: IARCustomer[] = [];

    for (const selectedChild of selectedChildren) {
      const connected: IARCustomer = {
        ...selectedChild,
        parentARCustomerId: undefined,
        parentCustSrcId: isUltimateParent ? undefined : newParent.custSrcId,
        parentCustName: isUltimateParent ? undefined : newParent.custName,
        upcParentCustId: undefined,
        upcParentCustSrcId: isUltimateParent ? newParent.custSrcId : undefined,
        upcParentCustName: isUltimateParent ? newParent.custName : undefined,
      }
      connectedChildren.push(connected);

      // Customers To Edit
      const existingCustomerIndex = currentCustomersToEditConnectedChildren.findIndex(
        (customer) => customer.recordId === selectedChild.recordId
      );

      if (existingCustomerIndex !== -1) {
        currentCustomersToEditConnectedChildren[existingCustomerIndex] = {
          ...currentCustomersToEditConnectedChildren[existingCustomerIndex],
          parentARCustomerId: undefined,
          parentCustSrcId: isUltimateParent ? undefined : newParent.custSrcId,
          parentCustName: isUltimateParent ? undefined : newParent.custName,
          upcParentCustId: undefined,
          upcParentCustSrcId: isUltimateParent ? newParent.custSrcId : undefined,
          upcParentCustName: isUltimateParent ? newParent.custName : undefined,
        };
      } else {
        currentCustomersToEditConnectedChildren.push(connected);
      }
    }

    return ({
      currentCustomersToEditConnectedChildren,
      connectedChildren,
    })
  }

  // ----------------- DELETE PARENT -----------------
  const handleConfirmDeleteParent = () => {
    const { selectedChildren } = selectedParentAndChild as ISelectedParentAndChild;
    handleRemoveParentToOrphan(selectedChildren);
  }

  const handleClose = () => {
    setCreateParentModalOpen(false);
  }
  
  // Rendering the component
  const getTableBody = () => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell
            sx={styles.linearProgressCell}
            colSpan={4}
          >
            <LinearProgress />
          </TableCell>
        </TableRow>
      )
    } else if (filteredParentsList.length) {
      return (
        [...filteredParentsList]
          .sort((a, b) =>
            a.custName.toLowerCase().localeCompare(b.custName.toLowerCase())
          )
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((value, index, array) => {
            const lastRow = array.length === index + 1;

            return (
              <ParentRow
                {...props}
                key={value.custSrcId}
                index={index}
                parentWithChild={value}
                lastRow={lastRow}
                lastRowElementRef={lastRowElementRef}
                createUpdatedCustomerList={createUpdatedCustomerList}
              />
            )
          })
      )
    } else {
      return (
        <TableRow>
          <TableCell
            sx={styles.emptyVerbiageCell}
            colSpan={4}
          >
            <NoDataPlaceholder
              messageText='No Parent Customers Added'
              messageContainerStyle={styles.emptyVerbiageContainer}
            />
          </TableCell>
        </TableRow>
      )
    }
  }
  
  return (
    <>
    <Box
      component={Paper}
      sx={styles.parentsSectionContainer}
    >
      <Box sx={styles.clearButtonBox} />
      {/* Search bar */}
      <Box sx={styles.searchContainer}>
        <Select
          id='filter-dropdown-parent-list'
          value={selectedSearch}
          // No filtering needed on change of this component since the child is always under the parent
          onChange={(event) => setSelectedSearch(event.target.value)}
          sx={styles.filterSelector}
          inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
          data-testid='search-dropdown-parents-list'
        >
          {searchFilters.map(filter => (
            <MenuItem
              key={filter}
              value={filter}
            >
              {filter}
            </MenuItem>
          ))}
        </Select>
        <TextField
          inputProps={{ 'aria-label': 'Search-Textfield' }}
          id='search-parent-list'
          data-testid='search-field-parents-list'
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                tabIndex={0}
                aria-label='Search icon'
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={triggerSearch}
          placeholder='Search'
          size='small'
          sx={styles.searchField}
        />
      </Box>

      {/* Main Table */}
      <TableContainer
        ref={tableContainerRef}
        sx={styles.sectionTableContainer}
        >
        <Table stickyHeader>

          {/* Headers */}
          <TableHead sx={styles.sectionTableHead}>
            <TableRow sx={styles.sectionTableHeaderRow}>
              <TableCell sx={styles.selectAndActionTableCell} />
              <TableCell sx={styles.parentNameHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                  Parent Name
                </Typography>
              </TableCell>
              <TableCell sx={styles.parentArAmountHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  Amount
                </Typography>
              </TableCell>
              <TableCell sx={styles.selectAndActionTableCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'center' }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Spacer */}
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>

          {/* Drop Section for Creating a Parent */}
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={4}
                sx={styles.dropSectionCell}
              >
                <Box
                  tabIndex={0}
                  data-testid='parent-section-drop-area'
                  sx={isDragging ? 
                    {...styles.dropSection, ...styles.dropSectionActive} :
                    {...styles.dropSection}}
                  onDragOver={(event) => {
                    // Prevent ghost image to bounce back
                    event.preventDefault();
                  }}
                  onDrop={() => {
                    if (selectedParentAndChild === null) return;
                    const { selectedParent, selectedChildren } = selectedParentAndChild;

                    // Handle Orphan to New Parent
                    if (selectedParent === null) {
                      if (selectedChildren.some(child => child.hasVendorsLinked)) { // Check if has vendors
                        setDroppedInParent('new'); // Create New Parent
                        setVendorConfirmModalOpen(true);
                      } else {
                        createUpdatedCustomerList(initialCustomers, formik);
                        setCreateParentModalOpen(true);
                      }
                    }

                    // Handle Child to New Parent 
                    else if (selectedParent.childCustomers.length < 2) { // Check first if last child
                      setDroppedInParent('new'); // Create New Parent
                      setLastChildConfirmModalOpen(true);
                    } else if (selectedChildren.some(child => child.hasVendorsLinked)) { // Check if has vendors
                      setDroppedInParent('new'); // Create New Parent
                      setVendorConfirmModalOpen(true);
                    } else {
                      createUpdatedCustomerList(initialCustomers, formik);
                      setCreateParentModalOpen(true);
                    }
                  }}
                >
                  <Typography>
                    Drag and drop selected customer to add new parent
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {getTableBody()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    <CustomerVendorModal
      open={createParentModalOpen}
      isEditting={true}
      selectedCustomerVendor={{}}
      onSave={handleCreateParent}
      onClose={handleClose}
      schema={customerSchema(updatedCustomerList, true, isUltimateParent)}
      createCustomer
    />
    <ConfirmModal
      title='Remove'
      description={
        'If the last child is removed, '
        +'the Parent will be removed. '
        +'Are you sure you want to remove this child from the Parent?'
      }
      open={lastChildConfirmModalOpen}
      onClose={() => {
        setDroppedInParent(null);
        setSelectedParentAndChild(null);
        setLastChildConfirmModalOpen(false);
      }}
      onConfirm={() => {
        handleConfirmRemoveParent();
      }}
      yesButtonText='Remove'
      noButtonText='Cancel'
      errorButton={false}
      confirmOnly
    />
    <ConfirmModal
      title='Child Customers Have Associated Vendor/s'
      description={
        'One or more customers have associated vendors. '
        +'These vendor associations will be with the parent customer '
        +'when you create a parent-child relationship. '
        +'Are you sure you want to proceed?'
      }
      open={vendorConfirmModalOpen}
      onClose={() => {
        setDroppedInParent(null);
        setSelectedParentAndChild(null);
        setVendorConfirmModalOpen(false);
        setLastChildConfirmModalOpen(false);
      }}
      onConfirm={() => {
        handleVendorLinking();
      }}
      yesButtonText='Proceed'
      noButtonText='Cancel'
      errorButton={false}
      confirmOnly
    >
      <Box sx={styles.overflowBox}>
        {selectedParentAndChild?.selectedChildren
          .filter(selectedChild => selectedChild.hasVendorsLinked)
          .map(selectedChild => 
            <ListItem
              key={selectedChild.custSrcId}
              dense
              disablePadding
            >
              <ListItemIcon sx={styles.childListItemIcon}>
                <Circle sx={styles.circleIcon}/>
              </ListItemIcon>
              <ListItemText>{selectedChild.custName}</ListItemText>
            </ListItem>
        )}
      </Box>
    </ConfirmModal>
    <ConfirmModal
      title='Remove'
      description={
        'You are about to remove this child.'
        +'Are you sure?'
      }
      open={childRemoveConfirmModalOpen}
      onClose={() => {
        setSelectedParentAndChild(null);
        setChildRemoveConfirmModalOpen(false);
      }}
      onConfirm={() => {
        handleConfirmRemoveChild();
      }}
      yesButtonText='Remove'
      noButtonText='Cancel'
      errorButton={false}
    />
    <ConfirmModal
      title={`Delete ${selectedParentAndChild?.selectedParent?.custName}`}
      description={
        'The relationship between this parent and its children will be removed. '
        +'Are you sure you want to delete this parent?'
      }
      open={deleteParentModalOpen}
      onClose={() => {
        setSelectedParentAndChild(null);
        setDeleteParentModalOpen(false);
      }}
      onConfirm={() => {
        handleConfirmDeleteParent();
      }}
      yesButtonText='Delete'
      noButtonText='Cancel'
      errorButton={true}
    />
    </>
  )
}

export default ParentsSection;