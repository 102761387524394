const styles = {
    container: { 
        marginTop: '10px', 
        padding: '5px' 
    },
    iframe: { 
        display: 'block', 
        border: 'none',
        height: '100vh'
    }
};

export default styles;