import { Box, Link, Paper, Typography } from "@mui/material";
import UpdatePasswordForm from "../../../components/forgot-password/update-password";
import styles from "./styles";
import LoanWatchLogo from '../../../assets/images/logo.png';
import { FC, useContext, useEffect, useState } from "react";
import { getLocalStorageItem } from "../../../utility/helper";
import { AuthContext } from "../../../context/authContext";
import { ActionType } from "../../../actions/authActions";
import { IUserDetailsAPI } from "../../../interfaces/rolesPermissionInterface";
import axiosInstance from "../../../service/axiosInstance";
import { useSearchParams } from "react-router-dom";
import { GET } from "../../../utility/constants";
import api from "../../../service/api";

interface IProps {
  type: 'create' | 'update';
}

/**
 * Component for showing the Users and Roles table.
 * @param props The props for the UpdatePassword component. See the IProps interface for more information.
 */
const UpdatePassword : FC<IProps> = (props) => {
  const {dispatch} = useContext(AuthContext);
  const {type}     = props;
  const [searchParams] = useSearchParams();
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const [isCheckUserActiveLoading, setIsCheckUserActiveLoading] = useState(true);

  /**
   * This useEffect hook automatically logs the user out if the the user accessed this page while logged in.
   */
  useEffect(() => {
    if(getLocalStorageItem('token') !== null) {
      dispatch({
        type: ActionType.LOGOUT,
      });
    }

    if (type === 'create') {
      checkIsUserActive();
    }
  }, []);

  const checkIsUserActive = async () => {
    try {
      setIsCheckUserActiveLoading(true);
      const userDatail = await getDetailsOfUserByRecordId(searchParams.get('userDetailId'));
      if (userDatail?.status === 'Active') {
        setIsUserActive(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsCheckUserActiveLoading(false);
    }
  }

  /** 
   * This function calls the API endpoint for retrieving the details of a user by it ID of the user details.
   * @returns The details of the user. See IUserDetailsAPI interface for more information.
   */
  const getDetailsOfUserByRecordId = async (recordId: string | null) => {
    if (recordId === null) return;
    try {
      const response = await axiosInstance.request({
        url: api.GET_USER_INFO_BY_ID,
        method: GET,
        params: {recordId: recordId}
      })
      const details : IUserDetailsAPI = response.data;
      return details;
    } catch (e) {
      console.log(e)
    }
  }

  return (
    !isCheckUserActiveLoading || type == 'update' ?
      <Box sx={styles.mainContainer}>
        <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={500} tabIndex={0} />
        {!isUserActive || type == 'update'
            ? <Paper elevation={0} sx={styles.paperStyle}>
                <Box component={'h2'} sx={styles.modalTitle}>
                  Create a strong password
                </Box>
                <Box component={'p'} sx={styles.descriptionText}>
                  Create a new, strong password that you don’t use for other Websites
                </Box>
                <UpdatePasswordForm type={type}/>
              </Paper>
            : <Paper elevation={0} sx={styles.paperStyle}>
                <Typography>
                  Your account is already activated. Please proceed to the 
                  <Link
                    data-testid='forgot-password'
                    underline='none'
                    href='/sign-in'
                    color='#0E55AA'
                  >
                    &nbsp;Login Page.
                  </Link>
                </Typography>
              </Paper>
          }
      </Box>
      : <></>
  )
};

export default UpdatePassword;