import { ToasterState } from "../components/toaster";
import { IOperators } from "../interfaces";
import { DraggableListItem } from "../interfaces/draggableList";
import { ICollateralRuleOperators } from "../interfaces/collateralRuleInterface";

// AXIOS CONFIGURATION
export const BEARER = 'Bearer ';
export const CSRF_TOKEN = 'nocheck';
export const TIMEOUT_LIMIT = 100;
export const XML_HTTP_REQUEST = 'XMLHttpRequest';

// HTTP METHOD
export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const DRAWER_WIDTH = 240;
export const MINI_DRAWER_WIDTH = 60;
export const MODAL_WIDTH = '1070px';

export const API_DOMAIN = (<any>window).BASE_URL;
export const WEB_SOCKET_DOMAIN =  API_DOMAIN !== undefined ? API_DOMAIN.replace(/^http?/,'ws') : '';

// STATUS CODES
export const STATUS_CODES = {
    OK                      : 200,
    CREATED                 : 201,
    BAD_REQUEST             : 400,
    UNAUTHORIZED            : 401,
    FORBIDDEN               : 403,
    NOT_FOUND               : 404,
    CONFLICT                : 409,
    INTERNAL_SERVER_ERROR   : 500
};

//SELECT
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

//DATE
export const AMERICAN_DATE_FORMAT        = 'MM/DD/YYYY'
export const AMERICAN_DATE_24TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss'
export const AMERICAN_DATE_8HR_FORMAT    = 'MM/DD/YYYY hh:mm A'
export const ISO_DATE_FORMAT             = 'YYYY-MM-DD';
export const LONG_DATE_8HR_FORMAT        = "MMM D, YYYY h:mm A";
export const TIMESTAMP_WITH_ZONE         = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const CONTAINS_NUMBER_REGEX = /^\d+$/;
export const CONTAINS_CHARACTER_REGEX = /^[A-Za-z ]+$/;
export const CONTAINS_ALPHANUMERIC_ONLY = /^[a-zA-Z0-9 ]*$/;

// VALUE FOR INVALID WHOLE NUMBER
export const NON_EXISTING = -1;
// VALUE FOR INVALID NON DECIMAL PERCENT
export const NON_EXISTING_PERCENT = -999;
// VALUE FOR NON APPLICABLE
export const NOT_APPLICABLE = 0;

// MAXIMUM VALUES FOR INTEGER (DB VALUE FOR INT)
export const MAX_POSITIVE_INTEGER = 2147483647;
export const MAX_NEGATIVE_INTEGER = -2147483647;

// CONSTANTS FOR COMMON NUMBERS (TENS)
export const ZERO = 0;
export const TEN = 10;
export const ONE_HUNDRED = TEN * TEN;
export const ONE_THOUSAND = ONE_HUNDRED * TEN;
export const TEN_THOUSAND = ONE_THOUSAND * TEN;
export const ONE_HUNDRED_THOUSAND = TEN_THOUSAND * TEN;
export const ONE_MILLION = ONE_HUNDRED_THOUSAND * TEN;

// BATCH PROCESSING SIZE
export const BATCH_SIZE = 100000;
export const CONCURRENCY_OF_STAGING_REQUESTS = 1;

// UNIT TEST TIMEOUT
export const UNIT_TEST = {
    jestTimeoutInMs: {
        xSmall: 150000,
        small: 300000,
        medium: 600000,
        large: 900000,
        xLarge: 1800000,
    }
}

export const REPORT_FREQ_WEEKLY = [
    {
        'id': 1,
        'name': 'Monday'
    },
    {
        'id': 2,
        'name': 'Tuesday'
    },
    {
        'id': 3,
        'name': 'Wednesday'
    },
    {
        'id': 4,
        'name': 'Thursday'
    },
    {
        'id': 5,
        'name': 'Friday'
    }
];

export const REPORT_FREQ_QUARTERLY = [
    {
        'id':1,
        'name':'January'
    },
    {
        'id':2,
        'name':'February'
    },
    {
        'id':3,
        'name':'March'
    },
    {
        'id':4,
        'name':'April'
    },
    {
        'id':5,
        'name':'May'
    },
    {
        'id':6,
        'name':'June'
    },
    {
        'id':7,
        'name':'July'
    },
    {
        'id':8,
        'name':'August'
    },
    {
        'id':9,
        'name':'September'
    },
    {
        'id':10,
        'name':'October'
    },
    {
        'id':11,
        'name':'November'
    },
    {
        'id':12,
        'name':'December'
    }
];

// CURRENCIES
export const CURRENCY_ACCOUNTING = { style: 'currency', currency: 'USD', currencySign: 'accounting' };

// PROMPT VERBIAGES
export const PROMPT = {
    NAV_PROMPT: {
        title: 'Confirm Navigation',
        description: 'You have unsaved changes. Are you sure you want to leave this page?',
        keepEditing: 'Keep Editing',
        discardChanges: 'Discard Changes'
    },
    CONFIRM_PROMPT: {
        title: 'Confirm Navigation',
        description: 'There are still unmapped files. Do you want to proceed?',
        cancel: 'Cancel',
        proceed: 'Proceed to Import',
    },
    DELETE_PROMPT: {
        title: 'Delete',
        description: 'Are you sure you want to delete this item?',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    ARCHIVE_PROMPT: {
        title: 'Archive',
        description: 'Are you sure you want to archive this item?',
        cancel: 'Cancel',
        archive: 'Archive'
    },
    EXPORT_PROMPT: {
        title: 'Export',
        description: 'You are about to export this file. Are you sure you want to proceed?',
        cancel: 'Cancel',
        export: 'Export',
    }
}

export const fieldTableList = [
    {
        name: 'Customer List',
        fieldTable: 'ARCustomer'
    },
    {
        name: 'AR Aging',
        fieldTable: 'ARTransaction'
    }
]

const createOperationList = (operationList: {display: string, value: string}[], isArithmetic: boolean) =>
    operationList.map((list) => ({...list, isArithmetic}));

export const operationList: IOperators[] = [
    ...createOperationList([
        {display: 'Equals',                     value: '='},
        {display: 'Is One Of',                  value: 'IN'},
        {display: 'Is Not One Of',              value: 'NOT IN'},
        {display: 'Does Not Equal',             value: '<>'},
        {display: 'Contains',                   value: 'LIKE'},
        {display: 'Does Not Contain',           value: 'NOT LIKE'}
    ], false),
    ...createOperationList([
        {display: 'Less Than',                  value: '<'},
        {display: 'Less Than or Equal To',      value: '<='},
        {display: 'Greater Than',               value: '>'},
        {display: 'Greater Than or Equal To',   value: '>='}
    ], true),
]

export const dataMappingFields = {
    arAging: [
        {name: 'Customer ID',           value: 'customerSrcId'},
        {name: 'Customer Name',         value: 'customerName'},
        {name: 'Document #',            value: 'arTrxNo'},
        {name: 'Invoice Date',          value: 'arTrxDate'},
        {name: 'Due Date',              value: 'arTrxDueDate'},
        {name: 'Amount',                value: 'arTrxAmt'},
        {name: 'Payment Terms',         value: 'arTrxPmtTerms'},
        {name: 'Transaction Type',      value: 'arTrxType'},
        {name: 'Parent Id',             value: 'parentCustomerSrcId'},
        {name: 'Parent Name',           value: 'parentCustomerName'},
        {name: 'Customer Address 1',    value: 'customerAddress1'},
        {name: 'Customer Address 2',    value: 'customerAddress2'},
        {name: 'Customer City',         value: 'customerCity'},
        {name: 'Customer State',        value: 'customerState'},
        {name: 'Customer Country',      value: 'customerCountry'},
        {name: 'Customer Zip Code',     value: 'customerPostalCode'},
        {name: 'Customer Phone Number', value: 'customerPhone'},
        {name: 'Customer Description',  value: 'customerDescription'},
    ],
    apAging: [
        {name: 'Vendor ID',           value: 'vendorSrcId'},
        {name: 'Vendor Name',         value: 'vendorName'},
        {name: 'Document #',          value: 'apTrxNo'},
        {name: 'Invoice Date',        value: 'apTrxDate'},
        {name: 'Due Date',            value: 'apTrxDueDate'},
        {name: 'Amount',              value: 'apTrxAmt'},
        {name: 'Transaction Type',    value: 'apTrxType'},
        {name: 'Vendor City',         value: 'vendorCity'},
        {name: 'Vendor State',        value: 'vendorState'},
        {name: 'Vendor Country',      value: 'vendorCountry'},
        {name: 'Vendor Phone',        value: 'vendorPhone'},
        {name: 'Vendor Type',         value: 'vendorType'},
    ],
    customerList: [
        {name: 'Customer ID',           value: 'custSrcId'},
        {name: 'Customer Name',         value: 'custName'},
        {name: 'Address 1',             value: 'custAddress1'},
        {name: 'Address 2',             value: 'custAddress2'},
        {name: 'Country',               value: 'custCountry'},
        {name: 'City',                  value: 'custCity'},
        {name: 'State',                 value: 'custState'},
        {name: 'Zip Code',              value: 'custPostalCode'},
        {name: 'Phone Number',          value: 'custPhone'},
        {name: 'Credit Limit',          value: 'creditLimit'},
        {name: 'Description',           value: 'custDescription'},
        {name: 'Parent ID',             value: 'parentCustSrcId'},
        {name: 'Parent Name',           value: 'parentCustName'}
    ],
    vendorList: [
        {name: 'Vendor ID',             value: 'vendorSrcId'},
        {name: 'Vendor Name',           value: 'vendorName'},
        {name: 'Address 1',             value: 'vendorAddress1'},
        {name: 'Address 2',             value: 'vendorAddress2'},
        {name: 'Country',               value: 'vendorCountry'},
        {name: 'City',                  value: 'vendorCity'},
        {name: 'State',                 value: 'vendorState'},
        {name: 'Zip Code',              value: 'vendorPostalCode'},
        {name: 'Phone Number',          value: 'vendorPhone'},
        {name: 'Description',           value: 'vendorDescription'},
    ],
    glTransaction: [
        {name: 'Customer ID',           value: 'custSrcId'},
        {name: 'Customer Name',         value: 'custName'},
        {name: 'Transaction Number',    value: 'glTrxNo'},
        {name: 'Transaction Type',      value: 'glTrxType'},
        {name: 'Date',                  value: 'glTrxDate'},
        {name: 'Memo',                  value: 'glTrxMemo'},
        {name: 'Amount 1',              value: 'glTrxAmt1'},
        {name: 'Amount 2',              value: 'glTrxAmt2'},
    ]
}

export const collateralRuleOperationList: ICollateralRuleOperators[] = [
    {label: 'Equals',                     id: '='},
    {label: 'Is One Of',                  id: 'IN'},
    {label: 'Is Not One Of',              id: 'NOT IN'},
    {label: 'Does Not Equal',             id: '<>'},
    {label: 'Contains',                   id: 'LIKE'},
    {label: 'Does not Contain',           id: 'NOT LIKE'},
    {label: 'Less Than',                  id: '<'},
    {label: 'Less Than or Equal to',      id: '<='},
    {label: 'Greater Than',               id: '>'},
    {label: 'Greater Than or Equal to',   id: '>='}
]

// DOCUMENT TYPES
export const DOCTYPE = {
    AR_AGING        : 'AR Aging',
    AP_AGING        : 'AP Aging',
    CUST_LIST       : 'Customer List',
    VENDOR_LIST     : 'Vendor List',
    GL_TRANSACTION  : 'GL Transaction'
}

export const fieldsPerDocumentType = {
    'AR Aging'      : dataMappingFields.arAging,
    'AP Aging'      : dataMappingFields.apAging,
    'Customer List' : dataMappingFields.customerList,
    'Vendor List'   : dataMappingFields.vendorList,
    'GL Transaction': dataMappingFields.glTransaction
} as { [key: string]: { name: string; value: string; }[] };

export const requiredMappingFields = {
    'AR Aging'      : ['customerName', 'arTrxNo', 'arTrxDate', 'arTrxAmt'],
    'AP Aging'      : ['vendorName', 'apTrxAmt'],
    'Customer List' : ['custName'],
    'Vendor List'   : ['vendorName'],
    'GL Transaction': ['glTrxType', 'glTrxDate', 'glTrxAmt1']
}

export const optionalMappingFields = {
    'AR Aging'      : ['arTrxDueDate'],
    'AP Aging'      : ['apTrxDueDate'],
    'Customer List' : [],
    'Vendor List'   : [],
    'GL Transaction': [],
}

const createFieldTypeList = (fields: {field: string, fieldName: string}[], fieldType: string) => 
    fields.map(o => ({...o, fieldType}));

export const fieldTypeList = [
    ...createFieldTypeList([
        // Customer List
        {field: 'Customer Address 1',   fieldName: 'custAddress1'},
        {field: 'Customer Address 2',   fieldName: 'custAddress2'},
        {field: 'Customer City',        fieldName: 'custCity'},
        {field: 'Customer Country',     fieldName: 'custCountry'},
        {field: 'Customer ID',          fieldName: 'custSrcId'},
        {field: 'Customer Name',        fieldName: 'custName'},
        {field: 'Customer State',       fieldName: 'custState'},
        {field: 'Customer Zip Code',    fieldName: 'custPostalCode'},
        {field: 'Customer Phone Number',fieldName: 'custPhone'},
        {field: 'Customer Description', fieldName: 'custDescription'},
        {field: 'Parent ID',            fieldName: 'parentCustSrcId'},
        {field: 'Parent Name',          fieldName: 'parentCustName'},

        // AR Aging
        {field: 'Customer ID',          fieldName: 'customerSrcId'},
        {field: 'Customer Name',        fieldName: 'customerName'},
        {field: 'Document #',        fieldName: 'arTrxNo'},
        {field: 'Payment Terms',        fieldName: 'arTrxPmtTerms'},
        {field: 'AR Transaction Type',  fieldName: 'arTrxType'},
        {field: 'Parent ID',            fieldName: 'parentCustomerSrcId'},
        {field: 'Parent Name',          fieldName: 'parentCustomerName'},
        {field: 'Customer Address 1',   fieldName: 'customerAddress1'},
        {field: 'Customer Address 2',   fieldName: 'customerAddress2'},
        {field: 'Customer City',        fieldName: 'customerCity'},
        {field: 'Customer State',       fieldName: 'customerState'},
        {field: 'Customer Country',     fieldName: 'customerCountry'},
        {field: 'Customer Zip Code',    fieldName: 'customerPostalCode'},
        {field: 'Customer Phone Number',fieldName: 'customerPhone'},
        {field: 'Customer Description', fieldName: 'customerDescription'},

        // AP Aging
        {field: 'Vendor ID',            fieldName: 'vendorSrcId'},
        {field: 'Vendor Name',          fieldName: 'vendorName'},
        {field: 'Vendor Transaction Type',fieldName: 'apTrxType'},
        {field: 'Vendor City',          fieldName: 'vendorCity'},
        {field: 'Vendor State',         fieldName: 'vendorState'},
        {field: 'Vendor Country',       fieldName: 'vendorCountry'},
        {field: 'Vendor Phone',         fieldName: 'vendorPhone'},
        {field: 'Vendor Type',          fieldName: 'vendorType'},
        {field: 'Vendor Address 1',     fieldName: 'vendorAddress1'},
        {field: 'Vendor Address 2',     fieldName: 'vendorAddress2'},
        {field: 'Vendor Zip Code',      fieldName: 'vendorPostalCode'},
        {field: 'Vendor Description',   fieldName: 'vendorDescription'},

        // GL Transaction
        {field: 'GL Transaction Number',    fieldName: 'glTrxNo'},
        {field: 'GL Transaction Type',      fieldName: 'glTrxType'},
        {field: 'GL Transaction Memo',      fieldName: 'glTrxMemo'},
        ],
        'string'),
    ...createFieldTypeList([
        {field: 'Amount',                   fieldName: 'arTrxAmt'},
        {field: 'Credit Limit',             fieldName: 'creditLimit'},
        {field: 'Payment Terms (Days)',     fieldName: 'arTrxPmtTermDays'},
        {field: 'Vendor Amount',            fieldName: 'apTrxAmt'},
        {field: 'GL Transaction Amount 1',  fieldName: 'glTrxAmt1'},
        {field: 'GL Transaction Amount 2',  fieldName: 'glTrxAmt2'},
        ], 
        'number'),
    ...createFieldTypeList([
        {field: 'Due Date',             fieldName: 'arTrxDueDate'},
        {field: 'Invoice Date',         fieldName: 'arTrxDate'},
        {field: 'Vendor Invoice Date',  fieldName: 'apTrxDate'},
        {field: 'Vendor Due Date',      fieldName: 'apTrxDueDate'},
        {field: 'GL Transaction Date',  fieldName: 'glTrxDate'},
    ],
        'date')

]

export const fieldTypeListForUPC = fieldTypeList.map(fieldType => {
  if (fieldType.fieldName === 'parentCustSrcId') { return {field: 'Parent ID', fieldName: 'upcParentCustSrcId', fieldType: 'string' } }
  if (fieldType.fieldName === 'parentCustName') { return {field: 'Parent Name', fieldName: 'upcParentCustName', fieldType: 'string' } }
  return fieldType;
})

// TOASTER
const initToasterState: ToasterState = { open: false, message: '', severity: 'success' }
export const TOASTER = {
    AUTOMATIC_MAP       : 'File has the required fields and has been automatically mapped.',
    EMPTY_NULL_VALUES   : 'There are empty cells found under the following column(s):',
    EMPTY_NULL_CHECK    : 'Please check the mapped field(s) or reupload the file.',
    EMPTY_NULL_AUTOMATIC: 'There are empty cells found in one or more files. Please check the mapped field(s) or reupload the file(s).',
    ERROR_DATA_MAPPING  : 'Error in data mapping',
    ERROR_ARCHIVING     : 'Error in archiving',
    INITIAL_STATES      : initToasterState,
}

// DATA MAPPING STATUS
export const dataMappingStatus = {
    NOT_STARTED : 'Not Started',
    APPLIED     : 'Applied',
    COMPLETED   : 'Completed'
}

export const apiStatusCode = {
    CONFLICT : 'CONFLICT'
}

// UPLOAD FILE LIMIT -> 256mb = 2 ^ 28
// limiting file size to 256mb for now since uploading peaks at 1GB memory heap usage when uploading the 700k+ rows of file (137mb)
export const FILE_SIZE_LIMIT = Math.pow(2, 28);

// PARENT-CHILD CATEGORY
export const SEARCH_FILTER = {
    PARENT: 'Parent',
    CHILD:  'Child',
    ALL:    'All',
    NEW:    'New',
}

// PLACEHOLDERS
export const PLACEHOLDERS = {
    SEARCH_BAR: 'Search',
    SELECT: 'Please Select',
}

// ARIA-LABELS
export const ARIA_LABELS = {
    PAGINATION: {
        LEFT_BUTTON: 'Left Pagination Arrow - Go to Previous Page',
        RIGHT_BUTTON: 'Right Pagination Arrow - Go to the Next Page',
    },
    CHECKBOX: {
        INCLUDE_SETTINGS: 'Checkbox - Include Ineligible Settings in Report'
    }
}

// PAGINATION
export const PAGINATION = {
    PARAMETERS: {
        DEFAULT_MAX_PAGES: { pageNo: 0, pageSize: 999999 }
    }
}

// INELIGIBLE SETTINGS UNEDITABLE CODES
export const FIXED_CODES = ['DLQ', 'AC', 'XA', 'CN', 'CONTRA', 'CL', 'RFV'];

// MIME TYPES
export const MIME_TYPES = {
    'csv'   : 'text/csv',
    'xls'   : 'application/vnd.ms-excel',
    'xlsx'  : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

//PERMISSIONS
export const PERMISSIONS = {
    VIEW_CLIENT: 'lw_view_clients',
    VIEW_ASSIGNED_CLIENT: 'lw_view_assigned_clients',
    ADD_CLIENT: 'lw_add_new_client',
    UPDATE_CLIENT: 'lw_update_client',
    DELETE_ARCHIVE_CLIENT: 'lw_delete_archive_client',

    VIEW_OTHER_LOAN: 'lw_view_other_loans',
    ADD_OTHER_LOAN: 'lw_add_other_loan',
    UPDATE_OTHER_LOAN: 'lw_update_other_loan',
    DELETE_ARCHIVE_OTHER_LOAN: 'lw_delete_archive_other_loan',

    VIEW_CLIENT_SETTINGS: 'lw_view_client_settings',
    CALCULATE_INELIGIBLE: 'lw_clients_calculate_ineligible',
    CALCULATE_BB: 'lw_clients_calculate_borrowing_base',

    VIEW_AR: 'lw_view_ar_collaterals',
    CHANGE_DEFAULT_AR: 'lw_change_default_ar_collateral',
    ADD_AR: 'lw_add_ar',
    UPDATE_AR: 'lw_update_ar',
    DELETE_ARCHIVE_AR: 'lw_delete_archive_ar',

    VIEW_INV: 'lw_view_inv_collaterals',
    CHANGE_DEFAULT_INV: 'lw_change_default_inv_collateral',
    ADD_INV: 'lw_add_inventory',
    UPDATE_INV: 'lw_update_inventory',
    DELETE_ARCHIVE_INV: 'lw_delete_archive_inventory',
    
    VIEW_LOAN_BALANCES: 'lw_view_loan_balances',
    ADD_LOAN_BALANCES: 'lw_add_loan_balances',
    UPDATE_LOAN_BALANCES: 'lw_update_loan_balances',
    DELETE_ARCHIVE_LOAN_BALANCES: 'lw_delete_archive_loan_balances',

    VIEW_USERS_AND_ROLES: 'lw_view_users_roles',
    ADD_USER: 'lw_add_user',
    UPDATE_USER: 'lw_update_user',
    DEACTIVATE_USER: 'lw_deactivate_user',
    REACTIVATE_USER: 'lw_reactivate_user',
    DELETE_USER: 'lw_delete_user',
    ADD_ROLE: 'lw_add_role',
    UPDATE_ROLE: 'lw_update_role',
    DELETE_ROLE: 'lw_delete_role',

    VIEW_LENDER_SETTINGS: 'lw_view_lender_settings',
    UPDATE_COMPANY_INFORMATION: 'lw_update_company_information',
    UPLOAD_COMPANY_LOGO: 'lw_update_company_logo',
    ADD_INELIGIBLE: 'lw_add_ineligible',
    UPDATE_INELIGIBLE: 'lw_update_ineligible',
    DELETE_INELIGIBLE: 'lw_delete_ineligible',
    UPDATE_INELIGIBLE_PRIORITY: 'lw_update_ineligible_priority',

    VIEW_RESERVES:'lw_view_reserves',
    ADD_RESERVES:'lw_add_reserves',
    UPDATE_RESERVES:'lw_update_reserves',
    DELETE_ARCHIVE_RESERVES:'lw_delete_archive_reserves',

    VIEW_IA: 'lw_view_ineligible_adjustments',
    ADD_IA: 'lw_add_ineligible_adjustments',
    UPDATE_IA: 'lw_update_ineligible_adjustments',
    DELETE_ARCHIVE_IA: 'lw_delete_archive_ineligible_adjustments',

    VIEW_FILE_IMPORT : 'lw_view_file_import',
    IMPORT_FILE : 'lw_import_file',
    DOWNLOAD_FILE : 'lw_download_file',
    ARCHIVE_FILE : 'lw_archive_file',

    VIEW_REPORTS: 'lw_view_reports',
    
    VIEW_BB_REPORT:'lw_view_borrowing_base_report',
    EXPORT_BB_REPORT:'lw_export_borrowing_base_report',

    VIEW_INELIGIBLE_REPORT:'lw_view_ar_ineligible_report',
    EXPORT_INELIGIBLE_REPORT:'lw_export_ar_ineligible_report',
    
    VIEW_AR_AGING_REPORT:'lw_view_ar_aging_report',
    EXPORT_AR_AGING_REPORT:'lw_export_ar_aging_report',

    VIEW_INELIGILE_COMPARE_REPORT:'lw_view_ar_ineligible_compare_report',
    EXPORT_INELIGILE_COMPARE_REPORT:'lw_export_ar_ineligible_compare_report',
    
    VIEW_INVENTORY_REPORT:'lw_view_inventory_report',
    EXPORT_INVENTORY_REPORT:'lw_export_inventory_report',
    
    VIEW_ROLL_FORWARD_REPORT:'lw_view_roll_forward_report',
    EXPORT_ROLL_FORWARD_REPORT:'lw_export_roll_forward_report',
    
    VIEW_AP_AGING_REPORT:'lw_view_ap_aging_report',
    EXPORT_AP_AGING_REPORT:'lw_export_ap_aging_report',
    
    VIEW_FINANCIAL_MAPPING_REPORT:'lw_view_financial_mapping_report',
    EXPORT_FINANCIAL_MAPPING_REPORT:'lw_export_financial_mapping_report',
    
    VIEW_BALANCE_SHEET_REPORT:'lw_view_balance_sheet_report',
    EXPORT_BALANCE_SHEET_REPORT:'lw_export_balance_sheet_report',
    
    VIEW_PROFIT_LOST_REPORT:'lw_view_profit_and_loss_statement_report',
    EXPORT_PROFIT_LOST_REPORT: 'lw_export_profit_and_loss_statement_report',

    VIEW_INELIGIBLE_SETTINGS: 'lw_view_ineligible_settings',
    REORDER_INELIGIBLE_LIST: 'lw_reorder_ineligible_list',
    ACTIVATE_DEACTIVATE_INELIGIBLE: 'lw_activate_deactivate_ineligible',
    ADD_CRITERIA_AND_SETTINGS: 'lw_add_criteria_and_settings',
    UPDATE_CRITERIA_AND_SETTINGS: 'lw_update_criteria_and_settings',
    DELETE_CRITERIA_AND_SETTINGS: 'lw_delete_criteria_and_settings',
    UPDATE_ADDITIONAL_SETTINGS: 'lw_update_additional_settings',
    INELIGIBLE_SETTINGS_CALCULATE_INELIGIBLE: 'lw_ineligible_settings_calculate_ineligible',

    VIEW_CUSTOMER_LIST: 'lw_view_customer_list',
    VIEW_CUSTOMER_INFORMATION: 'lw_view_customer_information',
    UPDATE_CUSTOMER_INFORMATION: 'lw_update_customer_information',
    VIEW_PARENT_LIST: 'lw_view_parent_list',
    VIEW_PARENT_INFORMATION: 'lw_view_parent_information',
    UPDATE_PARENT_INFORMATION: 'lw_update_parent_information',
    EDIT_PARENT_CHILD_RELATIONSHIP: 'lw_edit_parent_child_relationship',

    VIEW_INVENTORY_WORKSHEET: 'lw_view_inventory_worksheet',
    ADD_INVENTORY_WORKSHEET: 'lw_add_inventory_worksheet',
    UPDATE_INVENTORY_WORKSHEET: 'lw_update_inventory_worksheet',
    DELETE_INVENTORY_WORKSHEET: 'lw_delete_inventory_worksheet',

    VIEW_INVENTORY_INELIGIBLES: 'lw_view_inventory_ineligibles',
    ADD_INVENTORY_INELIGIBLES: 'lw_add_inventory_ineligibles',
    UPDATE_INVENTORY_INELIGIBLES: 'lw_update_inventory_ineligibles',
    DELETE_INVENTORY_INELIGIBLES: 'lw_delete_inventory_ineligibles',
}

//Client Column List
export const clientColumnList: DraggableListItem[] = [
  {
    recordId: 1,
    name: 'Client Name',
    value: 'borrowerName',
    checked: true,
    disabled: false,
  },
  {
    recordId: 2,
    name: "Parent Client",
    value: 'parentClientFk',
    checked: true,
    disabled: false
  },
  {
    recordId: 3,
    name: "Accounting System",
    value: 'accountingSystem.accountingSystemName',
    checked: true,
    disabled: false
  },
  {
    recordId: 4,
    name: "Line of Business",
    value: 'lineOfBusiness',
    checked: true,
    disabled: false
  },
  {
    recordId: 5,
    name: "Line Limit",
    value: 'lineLimit',
    checked: true,
    disabled: false
  },
  {
    recordId: 6,
    name: 'Report Frequency',
    value: 'reportingFrequency',
    checked: false,
    disabled: false
  },
  {
    recordId: 7,
    name: "NAICS Code",
    value: 'naicsCode',
    checked: false,
    disabled: false
  },
  {
    recordId: 8,
    name: "Client Loan Number",
    value: 'clientLoanNumber',
    checked: false,
    disabled: false
  },
  {
    recordId: 9,
    name: "CRM Name",
    value: 'crmName',
    checked: false,
    disabled: false
  },
  {
    recordId: 10,
    name: "Due",
    value: 'dueDate',
    checked: false,
    disabled: false
  },
  {
    recordId: 11,
    name: "Country",
    value: 'country',
    checked: false,
    disabled: false
  },
  {
    recordId: 12,
    name: 'Address 1',
    value: 'address1',
    checked: false,
    disabled: false
  },
  {
    recordId: 13,
    name: "Address 2",
    value: 'address2',
    checked: false,
    disabled: false
  },
  {
    recordId: 14,
    name: "City",
    value: 'city',
    checked: false,
    disabled: false
  },
  {
    recordId: 15,
    name: "State",
    value: 'state',
    checked: false,
    disabled: false
  },
  {
    recordId: 16,
    name: 'Zip Code',
    value: 'zipCode',
    checked: false,
    disabled: false
  },
  {
    recordId: 17,
    name: "Contact Name",
    value: 'clientContactName',
    checked: false,
    disabled: false
  },
  {
    recordId: 18,
    name: "Email",
    value: 'clientContactEmail',
    checked: false,
    disabled: false
  },
  {
    recordId: 19,
    name: "Phone",
    value: 'clientContactPhoneNumber',
    checked: false,
    disabled: false
  },
  {
    recordId: 20,
    name: "External Client ID",
    value: 'externalClientId',
    checked: false,
    disabled: false
  },
  {
    recordId: 21,
    name: "External Parent ID",
    value: 'externalParentId',
    checked: false,
    disabled: false
  },
  {
    recordId: 22,
    name: "External FCN",
    value: 'externalFCN',
    checked: false,
    disabled: false
  },
  {
    recordId: 23,
    name: "ECID",
    value: 'ecid',
    checked: false,
    disabled: false
  },
];

export const NO_PERMISSION_MSG = 'You do not have the necessary permissions for this action.'

export const customerLevelCodes = ['XA', 'CN', 'CONTRA', 'AC', 'CL'];

export const codesWithNoDetails = ['CL', 'CONTRA', 'RFV'];

export const HEADER_KEYS = {
    TOKEN: 'token',
    X_USER_ID: 'x-user-id',
};

export const collateralIDs = [
    'AR01', 'AR02', 'AR03', 'AR04', 'AR05', 'AR06', 'AR07', 'AR08', 'AR09', 'AR10',
    'AR11', 'AR12', 'AR13', 'AR14', 'AR15', 'AR16', 'AR17', 'AR18', 'AR19', 'AR20',
    'AR21', 'AR22', 'AR23', 'AR24', 'AR25', 'AR26', 'AR27', 'AR28', 'AR29', 'AR30',
    'AR31', 'AR32', 'AR33', 'AR34', 'AR35', 'AR36', 'AR37', 'AR38', 'AR39', 'AR40',
    'AR41', 'AR42', 'AR43', 'AR44', 'AR45', 'AR46', 'AR47', 'AR48', 'AR49', 'AR50',
    'INV01', 'INV02', 'INV03', 'INV04', 'INV05', 'INV06', 'INV07', 'INV08', 'INV09', 'INV10',
    'INV11', 'INV12', 'INV13', 'INV14', 'INV15', 'INV16', 'INV17', 'INV18', 'INV19', 'INV20',
    'INV21', 'INV22', 'INV23', 'INV24', 'INV25', 'INV26', 'INV27', 'INV28', 'INV29', 'INV30',
    'INV31', 'INV32', 'INV33', 'INV34', 'INV35', 'INV36', 'INV37', 'INV38', 'INV39', 'INV40',
    'INV41', 'INV42', 'INV43', 'INV44', 'INV45', 'INV46', 'INV47', 'INV48', 'INV49', 'INV50'
];

export const DEFAULT_CHILD_MODAL_TEXT = {
    description: 'An existing default child client is assigned to this parent. Are you sure you want to set this client as the default child client?',
    title: 'Set Default Child Client',
    confirmText: 'Proceed',
};

export const routeList = [
    '/clients', '/clients/:borrowerId/settings', '/clients/:borrowerId/settings/exchangeRate', 
    '/clients/:borrowerId/settings/:arCollateralId/customers', '/clients/:borrowerId/settings/:arCollateralId/customers/edit', 
    '/clients/:borrowerId/settings/:arCollateralId/vendors/edit', '/clients/:borrowerId/settings/ineligible-adjustments', 
    '/clients/:borrowerId/settings/loan-balances', '/clients/:borrowerId/settings/reserves', 
    '/clients/:borrowerId/settings/:invCollateralFk/inventory-worksheet', '/clients/:borrowerId/settings/:invCollateralId/ineligibles-inventory', 
    '/reports/borrowing-base','/reports/ar-ineligible', '/reports/ar-ineligible', '/reports/ar-aging', '/reports/inventory', 
    '/reports/roll-forward', '/reports/ar-compare', '/reports/ap-aging', '/borrowing-base', '/analytics', '/general-settings', 
    '/audit-trail', '/users-and-roles', '/users-and-roles/:id/users-profile', '/file-import', '/ineligible-settings', '/upload-files', '/forbidden'
];
  
