import { useCallback } from "react";
import { ICustomerWithChildAndVendors, ISelectedCustomerWithChildAndVendor } from "../../interfaces/editVendorCustomerInterface";
import { IARVendor } from "../../interfaces";

const useManageVendorCustomer = () => {
  const unlinkVendors = useCallback(
    (
      list: ICustomerWithChildAndVendors[],
      selectedCustomerAndVendor: ISelectedCustomerWithChildAndVendor
    ) => {
      const { selectedCustomer, selectedVendors } = selectedCustomerAndVendor;
      const selectedVendorsRecordIds = selectedVendors.map(
        (vendor) => vendor.recordId
      );

      return list.map((customer) => {
        if (customer.custSrcId === selectedCustomer?.custSrcId) {
          return {
            ...customer,
            vendors: customer.vendors.filter(
              (vendor) => !selectedVendorsRecordIds.includes(vendor.recordId)
            ),
          };
        }

        return customer;
      });
    },
    []
  );

  const linkVendors = useCallback(
    (
      list: ICustomerWithChildAndVendors[],
      newCustomer: ICustomerWithChildAndVendors,
      selectedCustomerAndVendor: ISelectedCustomerWithChildAndVendor
    ) => {
      const { selectedVendors } = selectedCustomerAndVendor;

      return list.map((customer) => {
        if (customer.custSrcId === newCustomer.custSrcId) {
          return {
            ...customer,
            vendors: [...customer.vendors, ...selectedVendors],
          };
        }

        return customer;
      });
    },
    []
  );

  const removeVendors = useCallback(
    (
      list: IARVendor[],
      selectedCustomerAndVendor: ISelectedCustomerWithChildAndVendor
    ) => {
      const { selectedVendors } = selectedCustomerAndVendor;
      const selectedVendorsRecordIds = selectedVendors.map(
        (vendor) => vendor.recordId
      );

      return list.filter(
        (vendor) => !selectedVendorsRecordIds.includes(vendor.recordId)
      );
    },
    []
  );

  const clearNewCustomers = useCallback(
    (
      list: ICustomerWithChildAndVendors[],
    ) => {
      return list.map((customer) => ({
        ...customer,
        isNew: false,
        childCustomers: customer.childCustomers.map((child) => ({
          ...child,
          isNew: false,
        })),
      }));
    },
    []
  );

  const clearNewVendorsInCustomersList = useCallback(
    (
      list: ICustomerWithChildAndVendors[],
    ) => {
      return list.map((customer) => ({
        ...customer,
        vendors: customer.vendors.map((vendor) => ({
          ...vendor,
          isNew: false,
        })),
      }));
    },
    []
  );

  const clearNewVendorsInVendorsList = useCallback(
    (
      list: IARVendor[],
    ) => {
      return list.map((vendor) => ({
        ...vendor,
        isNew: false,
      }));
    },
    []
  );

  return {
    unlinkVendors,
    linkVendors,
    removeVendors,
    clearNewCustomers,
    clearNewVendorsInCustomersList,
    clearNewVendorsInVendorsList
  };
};

export default useManageVendorCustomer;