import colors from "../../../../utility/colors";

const styles = {
  asterisk: {
    color: `${colors.REQUIRED}`
  },
  sectionTitle: {
    borderBottom: `1px solid ${colors.NEUTRAL}`,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginBottom: '0.9rem',
    paddingBottom: '0.5rem',
    width: '100%',
  },
  customerInfoContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    padding: '3rem',
  },
  customerInputsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    width: '100%'
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
  },
  contactInfoContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    padding: '3rem',
  },
  stateAndZipContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '1.3rem',
    justifyContent: 'flex-end',
  },
  textfieldCustomerInfo: {
    width: '12.7rem',
    marginBottom: '0.75rem',
    '& .MuiOutlinedInput-input': {
      fontSize: '0.8125rem',
      'WebkitBoxShadow': 'none',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: '2.3rem',
      marginLeft: '0.2rem'
    },
  },
  textFieldContactCommon: {
    width: '21.3rem',
    '.MuiOutlinedInput-input': {
      'WebkitBoxShadow': 'none',
      fontSize: '13px',
    }
  },
  textFieldContactSmall: {
    width: '6.8rem',
    '.MuiOutlinedInput-input': {
      'WebkitBoxShadow': 'none',
      fontSize: '13px',
    }
  },
}
export default styles;