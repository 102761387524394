import { Box, Checkbox, IconButton, InputAdornment, LinearProgress, Link, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import styles from './styles';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { DragIndicator } from '@mui/icons-material';
import OrphanRow from './orphan-row';
import NewTag from '../../../../components/common/new-tag';
import _ from 'lodash';
import { MainSectionProps } from '..';
import { EditParentChildContext, IEditParentChildContext } from '../../../../context/editParentChildContext';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import { clearNewCustomersByIds } from '../../../../utility/helper';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../../context/customerSettingsContext';
import useManageParentChild from '../../../../utility/custom-hooks/useManageParentChild';
import NoDataPlaceholder from '../../../../components/common/no-data-placeholder';

// Interfaces
export interface OrphansSectionProps extends MainSectionProps {
  
}

// Constants
const searchFilters = ['All', 'New'];

const OrphansSection = (props: OrphansSectionProps) => {
  const { tableContainerRef } = props;

  const { clearNewCustomers } = useManageParentChild();
  
  // Contexts
  const {
    setToaster
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const {
    parentsList,
    setParentsList,
    filteredParentsList,
    setFilteredParentsList,
    orphansList,
    setOrphansList,
    filteredOrphansList,
    setFilteredOrphansList,
    isFetching,
    triggerResetSearch,
    triggerResetSelection,
    setTriggerResetSelection,
    setSelectedCustomers,
    initialCustomers,
    setInitialCustomers
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  // States
  const [selectedSearch, setSelectedSearch] = useState<string>(searchFilters[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [triggerSelectAll, setTriggerSelectAll] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setSelectedSearch(searchFilters[0]);
    setSearchValue('');
    setPage(0);
    setIsSelectedAll(false);
  }, [triggerResetSearch]);
    
  // Memos
  const rowsPerPage = useMemo(() => 10, []);

  const hasMore = useMemo(() => {
    return filteredOrphansList.slice(0, page * rowsPerPage + rowsPerPage).length < filteredOrphansList.length;
  }, [filteredOrphansList, page, rowsPerPage]);
  

  // Callbacks
  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    // Current implementation does not cover this branch on any cases
    // Will comment out for future references:
    // if (isFetching) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [hasMore]); // remove isFetching from dependency

  // Functions
  const triggerSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleResetSelection();
    setSearchValue(event.target.value);

    let debounce = _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleSearch(selectedSearch, event.target.value);
    }, 500);
    debounce(event);
  };

  const handleSearch = (selectedSearch: string, searchValue: string) => {
    const loweredSearchValue = searchValue.toLowerCase().trim();
    if (loweredSearchValue === '') {
      if (selectedSearch === 'All') {
        setFilteredOrphansList(orphansList);
      } else {
        const filtered = orphansList.filter(orphan => orphan.isNew);
        setFilteredOrphansList(filtered);
      }
    } else {
      const filtered = filterOrphans(selectedSearch, loweredSearchValue);
      setFilteredOrphansList(filtered);
    }
  }

  const filterOrphans = (selectedSearch: string, loweredSearchValue: string) => {
    if (selectedSearch === 'All') {
      return orphansList.filter(orphan => 
        orphan.custName?.toLowerCase().includes(loweredSearchValue) ||
        orphan.custSrcId?.toLowerCase().includes(loweredSearchValue)
      )
    } else {
      return orphansList.filter(orphan => orphan.isNew && (
        orphan.custName?.toLowerCase().includes(loweredSearchValue) ||
        orphan.custSrcId?.toLowerCase().includes(loweredSearchValue)
      ))
    }
  }

  const handleChangeFilter = (event: SelectChangeEvent<string>) => {
    handleResetSelection();
    const selected = event.target.value;
    setSelectedSearch(selected);
    handleSearch(selected, searchValue);
  }

  const handleSelectAll = () => {
    // isSelectedAll is the current value
    // Remove the selection if previously selected before onClick

    if (isSelectedAll) {
      // Remove customers
      setSelectedCustomers([]);
    } else {
      // Add all customers
      setSelectedCustomers(filteredOrphansList);
    }
  }

  const handleResetSelection = () => {
    setIsSelectedAll(false);
    setTriggerResetSelection(!triggerResetSelection);
    setSelectedCustomers([]);
  }

  const handleClearNewCustomers = async () => {
    try {
      const allCustomerIds: number[] = initialCustomers.map(customer => customer.recordId!);
      await clearNewCustomersByIds(allCustomerIds);

      const clearedCustomers = initialCustomers.map(customer => ({
        ...customer,
        isNew:false,
      }))

      setInitialCustomers(clearedCustomers);
      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' });
      
      // Updating the display
      // Parents List
      setParentsList(clearNewCustomers(parentsList));
      setFilteredParentsList(clearNewCustomers(filteredParentsList));

      // Orphans List
      setOrphansList(
        orphansList.map(orphan => ({ ...orphan, isNew: false }))
      );

      setFilteredOrphansList(
        filteredOrphansList.map(orphan => ({ ...orphan, isNew: false }))
      );
    } catch (error) {
      console.log('CLEAR ALL ORPHANS ERROR: ', error);
      setToaster({ open: true, message: 'Failed to clear new customers!', severity: 'error' });
    }
  }

  // Rendering the component
  const getTableBody = () => {
    if (isFetching) {
      return (
        <TableRow>
          <TableCell
            sx={styles.linearProgressCell}
            colSpan={4}
          >
            <LinearProgress />
          </TableCell>
        </TableRow>
      )
    } else if (filteredOrphansList.length) {
      return (
        [...filteredOrphansList]
          .sort((a, b) =>
            a.custName!.toLowerCase().localeCompare(b.custName!.toLowerCase())
          )
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((value, index, array) => {
            const lastRow = array.length === index + 1;
            
            return (
              <OrphanRow
                {...props}
                key={value.custSrcId}
                index={index}
                orphanCustomer={value}
                isSelectedAll={isSelectedAll}
                setIsSelectedAll={setIsSelectedAll}
                triggerSelectAll={triggerSelectAll}
                lastRow={lastRow}
                lastRowElementRef={lastRowElementRef}
              />
            )
          })
      )
    } else {
      return (
        <TableRow>
          <TableCell
            sx={styles.emptyVerbiageCell}
            colSpan={4}
          >
            <NoDataPlaceholder
              messageText='No Customer List'
              messageContainerStyle={styles.emptyVerbiageContainer}
            />
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <Box
      component={Paper}
      sx={styles.orphansSectionContainer}
    >
      <Box sx={styles.clearButtonBox}>
        <Link 
          component='button'
          type='button'
          sx={styles.clearButton}
          onClick={() => {
            setClearModalOpen(true);
          }}
        >
          Clear All New Customers
        </Link>
      </Box>
      
      {/* Search bar */}
      <Box sx={styles.searchContainer}>
        <Select
          id='filter-dropdown-orphan-list'
          value={selectedSearch}
          onChange={handleChangeFilter}
          sx={styles.filterSelector}
          inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
          data-testid='search-dropdown-orphan-list'
        >
          {searchFilters.map(filter => (
            <MenuItem
              key={filter}
              value={filter}
            >
              {filter}
            </MenuItem>
          ))}
        </Select>
        <TextField
          inputProps={{ 'aria-label': 'Search-Textfield' }}
          id='search-orphan-list'
          data-testid='search-field-orphan-list'
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                tabIndex={0}
                aria-label='Search icon'
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={triggerSearch}
          placeholder='Search'
          size='small'
          sx={styles.searchField}
        />
      </Box>

      {/* Main Table */}
      <TableContainer
        ref={tableContainerRef}
        sx={styles.sectionTableContainer}
      >
        <Table stickyHeader>

          {/* Headers */}
          <TableHead sx={styles.sectionTableHead}>
            <TableRow sx={styles.sectionTableHeaderRow}>
              <TableCell sx={styles.selectAndActionTableCell}>
                <Box sx={styles.selectAndActionContainer}>
                  <IconButton
                    draggable
                    edge='end'
                    aria-label='Drag and drop icon'
                    sx={styles.dragButton}
                  >
                    <DragIndicator/>
                  </IconButton>
                  <Tooltip title='Select All'>
                    <Checkbox
                      id='checkbox'
                      data-testid='select-all-checkbox'
                      aria-label='Checkbox'
                      aria-labelledby='checkbox'
                      edge='end'
                      inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
                      checked={isSelectedAll && filteredOrphansList.length > 0}
                      disableRipple
                      onClick={() => {
                        setIsSelectedAll(!isSelectedAll);
                        setTriggerSelectAll(!triggerSelectAll);
                        handleSelectAll();
                      }}
                      sx={styles.checkbox}
                    />
                  </Tooltip>
                </Box>
              </TableCell>

              
              <TableCell sx={styles.orphanNameHeaderCell}>
                <Box sx={styles.nameAndTagContainer}>
                  <NewTag additionalStyles={{ visibility: 'hidden'}}/>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                    Customer Name
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={styles.orphanArAmountHeaderCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  AR Amount
                </Typography>
              </TableCell>
              <TableCell sx={styles.dateCreatedTableCell}>
                <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'center' }}>
                  Date Created
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Spacer */}
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>

          {/* Orphan List */}
          <TableBody>
            {getTableBody()}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmModal
        title='Clear All New Customers'
        description='Are you sure you want to clear all new added customers?'
        open={clearModalOpen}
        onClose={() => {
          setClearModalOpen(false);
        }}
        onConfirm={() => {
          handleClearNewCustomers();
        }}
        yesButtonText='Clear'
        noButtonText='Cancel'
      />
    </Box>
  )
}

export default OrphansSection;