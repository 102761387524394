import { Chip, SxProps, Theme } from '@mui/material'
import colors from '../../../utility/colors';

interface NewTagProps {
  expanding?: boolean;
  additionalStyles?: SxProps<Theme>;
}

const NewTag: React.FC<NewTagProps> = (props) => {
  const { expanding, additionalStyles } = props;

  return (
    <Chip
      size={'small'}
      sx={{
        backgroundColor: colors.SECONDARY,
        color: colors.WHITE,
        fontSize: '12px',
        '& .MuiChip-label:after': {
            content: '"N"',
        },
        ...(expanding ? ({
          marginLeft: '15px',
          '& .MuiChip-label:hover:after': {
            content: '"New"',
          },
        }) : ({
          marginRight: '5px',
        })),
        ...additionalStyles
      }}
    />
  )
}

export default NewTag