import * as Yup from 'yup';
import { IARVendor } from '../interfaces';

const vendorSchema = (
  updatedVendorList: IARVendor[],
) => {
  const { usedVendorNames, usedVendorSrcIds } = updatedVendorList.reduce((prevValue, vendor) => {
    prevValue.usedVendorNames.push(vendor.vendorName!.toLowerCase());
    prevValue.usedVendorSrcIds.push(vendor.vendorSrcId!.toLowerCase());
    return prevValue;
  }, {
    usedVendorNames: [] as string[],
    usedVendorSrcIds: [] as string[],
  });

  return Yup.object({
    vendorSrcId: Yup
      .string()
      .required(`Vendor ID is required`)
      .trim()
      .test('unique', `Vendor ID must be unique`, (value) =>
        value ? !usedVendorSrcIds.includes(value.toLowerCase()) : true
      ),
    vendorName: Yup
      .string()
      .required(`Vendor Name is required`)
      .trim()
      .test('unique', `Vendor Name must be unique`, (value) =>
        value ? !usedVendorNames.includes(value.toLowerCase()) : true
      ),
  });
}

export default vendorSchema;
