import { Box, Chip, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { CustomersSectionProps } from "..";
import { ICustomerWithChildAndVendors, ISelectedCustomerWithChildAndVendor } from "../../../../../interfaces/editVendorCustomerInterface";
import styles from "./styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../../../context/editVendorCustomerContext";
import useManageVendorCustomer from "../../../../../utility/custom-hooks/useManageVendorCustomer";
import { formatCurrency, isOddNumber } from "../../../../../utility/helper";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ChildRow from "./child-row";
import VendorRow from "./vendor-row";
import NewTag from "../../../../../components/common/new-tag";
import { IARVendor } from "../../../../../interfaces";

// Interfaces
export interface CustomerRowProps extends CustomersSectionProps {
  index: number;
  customerWithChildAndVendors: ICustomerWithChildAndVendors;
  lastRow: boolean;
  lastRowElementRef: (node: any) => void;
}

// Functions
const getParentNameToolTip = (customerWithChildAndVendors: ICustomerWithChildAndVendors) => {
  const custName = customerWithChildAndVendors.custName;
  const custSrcId = customerWithChildAndVendors.custSrcId;

  if (custName.length >= 37) {
    return (
      <Tooltip
        title={custName}
        placement='bottom-start'
      >
        <>
        <Box sx={styles.parentNameContainer}>
          <Typography
            tabIndex={0}
            id={custSrcId}
            sx={styles.custName}
          >
            {custName}
          </Typography>
          <Typography
            tabIndex={0}
            sx={styles.custName}
          >
            {` (${
              customerWithChildAndVendors.childCustomers.length +
              customerWithChildAndVendors.vendors.length
            })`}
          </Typography>
        </Box>
        <Typography
          tabIndex={0}
          sx={styles.custSrcId}
        >
          {`Customer ID: ${custSrcId}`}
        </Typography>
        </>
      </Tooltip>
    );
  } else {
    return (
      <>
      <Box sx={styles.parentNameContainer}>
        <Typography
          tabIndex={0}
          id={custSrcId}
          sx={styles.custName}
        >
          {custName}
        </Typography>
        <Typography
          tabIndex={0}
          sx={styles.custName}
        >
          {` (${
            customerWithChildAndVendors.childCustomers.length +
            customerWithChildAndVendors.vendors.length
          })`}
        </Typography>
      </Box>
      <Typography
        tabIndex={0}
        sx={styles.custSrcId}
      >
        {`Customer ID: ${custSrcId}`}
      </Typography>
      </>
    )
  }
}

const CustomerRow = (props: CustomerRowProps) => {
  const {
    formik,
    index,
    customerWithChildAndVendors,
    lastRow,
    lastRowElementRef  } = props;

  // Contexts
  const {
    customersList,
    setCustomersList,
    filteredCustomersList,
    setFilteredCustomersList,
    vendorsList,
    setVendorsList,
    filteredVendorsList,
    setFilteredVendorsList,
    selectedCustomerWithChildAndVendor,
    setSelectedVendors,
    triggerResetSearch,
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // Hooks
  const { linkVendors, removeVendors } = useManageVendorCustomer();

  // States
  const [openChildVendorList, setOpenChildVendorList] = useState<boolean>(false);

  // Memos
  const totalArAmount = useMemo(() => 
    customerWithChildAndVendors.isParent ? 
      customerWithChildAndVendors.childCustomers.reduce((prevValue, customer) =>
        prevValue + (customer.arAmount ?? 0), 0.0) :
      customerWithChildAndVendors.totalArAmount
  , [customerWithChildAndVendors.childCustomers, customerWithChildAndVendors.isParent, customerWithChildAndVendors.totalArAmount]);

  // Effects
  useEffect(() => {
    setOpenChildVendorList(false);
  }, [triggerResetSearch])

  // Functions
  const handleOnDrop = () => {
    if (selectedCustomerWithChildAndVendor === null) return;
    const { selectedVendors } = selectedCustomerWithChildAndVendor;
    const newCustomer = customerWithChildAndVendors;

    handleVendorDrop(selectedVendors, newCustomer, selectedCustomerWithChildAndVendor);
  }

  const handleVendorDrop = (
    selectedVendors: IARVendor[],
    newCustomer: ICustomerWithChildAndVendors,
    selectedCustomerWithChildAndVendor: ISelectedCustomerWithChildAndVendor
  ) => {
    // Add vendor to vendorsToEdit
    const updatedVendors = updateVendorsToEdit(selectedVendors, newCustomer, formik.values.vendorsToEdit);
    formik.setFieldValue('vendorsToEdit', updatedVendors);

    // Updating the customersList
    setCustomersList(linkVendors(customersList, newCustomer, selectedCustomerWithChildAndVendor));
    setFilteredCustomersList(linkVendors(filteredCustomersList, newCustomer, selectedCustomerWithChildAndVendor));

    // Updating the vendorsList
    setVendorsList(removeVendors(vendorsList, selectedCustomerWithChildAndVendor));
    setFilteredVendorsList(removeVendors(filteredVendorsList, selectedCustomerWithChildAndVendor));
    setSelectedVendors([]);
  }

  const updateVendorsToEdit = (
    selectedVendors: IARVendor[],
    newCustomer: ICustomerWithChildAndVendors,
    currentVendorsToEdit: IARVendor[]
  ) => {
    const updatedVendors = [...currentVendorsToEdit];

    for (const selectedVendor of selectedVendors) {
      const existingVendorIndex = updatedVendors.findIndex(
        vendor => vendor.recordId === selectedVendor.recordId
      );

      if (existingVendorIndex !== -1) {
        updatedVendors[existingVendorIndex] = {
          ...updatedVendors[existingVendorIndex],
          arCustomerId: newCustomer.recordId,
        };
      } else {
        const addedVendor: IARVendor = {
          ...selectedVendor,
          arCustomerId: newCustomer.recordId,
        };
        updatedVendors.push(addedVendor);
      }
    }

    return updatedVendors;
  }

  // Rendering the Component
  return (
    <>
    {/* Parent Row */}
    <TableRow
      sx={{
        ...styles.parentRow,
        bgcolor: isOddNumber(index) ?
          'background.paper' :
          '#F7F7F7'
      }}
      onClick={() => setOpenChildVendorList(!openChildVendorList)}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`customer-row-${index}`}
      ref={lastRow ? lastRowElementRef : undefined}
    >
      <TableCell sx={styles.parentSelectAndActionCell}>
        <Box sx={styles.arrowContainer}>
          {openChildVendorList ? 
            <KeyboardArrowUp sx={styles.arrow} /> :
            <KeyboardArrowDown sx={styles.arrow} />}
        </Box>
      </TableCell>
      <TableCell sx={styles.parentNameCell}>
        <Box sx={styles.nameAndTagContainer}>
          <NewTag additionalStyles={customerWithChildAndVendors.isNew ? undefined : { visibility: 'hidden'}}/>
          <Box sx={styles.tableDataNameBox}>
            {getParentNameToolTip(customerWithChildAndVendors)}
          </Box>
          <Chip size='small' sx={customerWithChildAndVendors.isParent ? styles.parentIdentifierChip : styles.parentIdentifierChipHidden} />
        </Box>
      </TableCell>
      <TableCell sx={styles.parentArAmountCell}>
        {totalArAmount ? formatCurrency(totalArAmount) : '-'}
      </TableCell>
      <TableCell sx={styles.parentSelectAndActionCell}>
      </TableCell>
    </TableRow>

    {/* Child Table Row Component */}
    {openChildVendorList &&
      <>
      {(customerWithChildAndVendors.childCustomers.length !== 0 || customerWithChildAndVendors.vendors.length !== 0) ?
      <>
      <TableRow>
        <TableCell sx={styles.childrenHeaderSelectAndActionCell}></TableCell>
        <TableCell sx={styles.childrenHeaderNameCell}>
          <Typography sx={styles.childrenHeaderTypography}>
            Accounts Name
          </Typography>
        </TableCell>
        <TableCell sx={styles.childrenHeaderArAmountCell}>
          <Typography sx={styles.childrenHeaderTypography}>
            Accounts Amount
          </Typography>
        </TableCell>
        <TableCell sx={styles.childrenHeaderSelectAndActionCell}>
        </TableCell>
      </TableRow>
      {[...customerWithChildAndVendors.childCustomers]
        .sort((a, b) =>
          (a.custName as string).toLowerCase().localeCompare((b.custName as string).toLowerCase())
        )
        .map((value, index) => (
          <ChildRow
            {...props}
            key={value.custSrcId}
            index={index}
            childCustomer={value}
            handleOnDrop={handleOnDrop}
          />
        ))}
      {[...customerWithChildAndVendors.vendors]
        .sort((a, b) =>
          (a.vendorName as string).toLowerCase().localeCompare((b.vendorName as string).toLowerCase())
        )
        .map((value, index) => (
          <VendorRow
            {...props}
            key={value.vendorSrcId}
            index={index}
            vendor={value}
            handleOnDrop={handleOnDrop}
          />
        ))}
      </>
        :
      <TableRow>
        <TableCell
          colSpan={4}
          sx={{
            ...styles.dropSectionCell,
            bgcolor: isOddNumber(index) ?
              'background.paper' :
              '#F7F7F7'
          }}
        >
          <Box
            tabIndex={0}
            data-testid={`customer-${index}-drop-section`}
            sx={styles.dropSection}
            onDragOver={(event) => {
              // Prevent ghost image to bounce back
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
              handleOnDrop();
            }}
          >
            <Typography sx={styles.dropSectionVerbiage}>
              Drag and drop selected vendor here.
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
      }
      </>
    }
    </>
  )
}

export default CustomerRow;