import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { VendorsSectionProps } from "..";
import { IARVendor } from "../../../../../interfaces";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../../../context/editVendorCustomerContext";
import { Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { formatCurrency, formatDate, isOddNumber } from "../../../../../utility/helper";
import styles from "./styles";
import { DragIndicator } from "@mui/icons-material";
import NewTag from "../../../../../components/common/new-tag";
import { getNameAndSrcId } from "../..";
import { AMERICAN_DATE_FORMAT } from "../../../../../utility/constants";

export interface VendorRowProps extends VendorsSectionProps {
  index: number;
  unlinkedVendor: IARVendor;
  isSelectedAll: boolean;
  setIsSelectedAll: Dispatch<SetStateAction<boolean>>;
  triggerSelectAll: boolean;
  lastRow: boolean;
  lastRowElementRef: (node: any) => void;
}

const VendorRow = (props: VendorRowProps) => {
  const {
    index,
    unlinkedVendor,
    isSelectedAll,
    setIsSelectedAll,
    triggerSelectAll,
    lastRow,
    lastRowElementRef,
  } = props;

  // Contexts
  const {
    filteredVendorsList,
    setSelectedCustomerWithChildAndVendor,
    selectedVendors,
    setSelectedVendors,
    triggerResetSearch,
    triggerResetSelection,
    isDragging,
    setIsDragging,
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // States
  const [isSelected, setIsSelected] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIsSelected(isSelectedAll);
  }, [triggerSelectAll]);

  useEffect(() => {
    setIsSelected(false);
  }, [triggerResetSearch, triggerResetSelection]);

  const handleSelection = (addOnly?: boolean) => {
    const existingIndex = selectedVendors.findIndex(
      vendor => vendor.vendorSrcId === unlinkedVendor.vendorSrcId
    );
    const isAlreadySelected = existingIndex !== -1;
  
    if ((addOnly || !isSelected) && !isAlreadySelected) {
      const newSelected = [...selectedVendors, unlinkedVendor];
      setSelectedVendors(newSelected);
      return newSelected;
    }
  
    if (!addOnly && isSelected && isAlreadySelected) {
      const updatedVendors = selectedVendors.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedVendors(updatedVendors);
      return updatedVendors;
    }
  
    return selectedVendors;
  };

  // Rendering the component
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        bgcolor: isOddNumber(index) ?
          'background.paper' :
          '#F7F7F7'
      }}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      data-testid={`vendor-list-item-${index}`}
      ref={lastRow ? lastRowElementRef : undefined}
    >
      <TableCell sx={styles.tableDataDragAndActionCell}>
        <Box sx={styles.tableDataDragContainer}>
          <Tooltip
            title='Drag and drop to associate with a customer'
            PopperProps={{
              sx: isDragging ? styles.noTooltip : null
            }}
          >
            <IconButton
              draggable
              edge='end'
              data-testid={`vendor-drag-icon-${index}`}
              aria-label='Drag and drop icon'
              onDragStart={(event) => {
                setIsDragging(true);
                setIsSelected(true);

                // On drag start, the current vendor if not previously selected,
                // will not be present in the selectedVendors
                const newSelectedVendors = handleSelection(true);
                setIsSelectedAll(newSelectedVendors.length === filteredVendorsList.length);

                setSelectedCustomerWithChildAndVendor({
                  selectedCustomer: null,
                  selectedVendors: newSelectedVendors,
                });

                // Create ghost images
                const dragGhostContainer = document.createElement('div');
                Object.assign(dragGhostContainer.style, styles.ghostImageContainer);

                // Display first 3 images
                const maxVisible = 3;
                newSelectedVendors
                  .slice(0, maxVisible)
                  .forEach((vendor) => {
                    const dragGhost = document.createElement('div');
                    dragGhost.textContent = vendor.vendorName!;
                    Object.assign(dragGhost.style, styles.ghostImage);
                    dragGhostContainer.appendChild(dragGhost);
                  });
                
                // Add `n more customers` ghost image
                const remainingCount = newSelectedVendors.length - maxVisible;
                if (remainingCount > 0) {
                  const moreGhost = document.createElement('div');
                  moreGhost.textContent = `+${remainingCount} more customers`;
                  Object.assign(moreGhost.style, styles.ghostImage);
                  dragGhostContainer.appendChild(moreGhost);
                }
                
                document.body.appendChild(dragGhostContainer);

                event.dataTransfer.setDragImage(dragGhostContainer, -20, 25);

                setTimeout(() => {
                  document.body.removeChild(dragGhostContainer);
                }, 0);
              }}
              onDragEnd={() => {
                setIsDragging(false);
                setSelectedCustomerWithChildAndVendor(null);
              }}
              sx={styles.dragButton} 
            >
              <DragIndicator/>
            </IconButton>
          </Tooltip>
          <Checkbox
            id='checkbox'
            data-testid='select-vendor-checkbox'
            aria-label='Checkbox'
            aria-labelledby='checkbox'
            edge='end'
            inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
            checked={isSelected}
            disableRipple
            onClick={() => {
              setIsSelected(!isSelected);
              const newSelectedVendors = handleSelection();
              setIsSelectedAll(newSelectedVendors.length === filteredVendorsList.length);
            }}
            sx={styles.checkbox}
          />
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataNameCell}>
        <Box sx={styles.nameAndTagContainer}>
          <NewTag additionalStyles={unlinkedVendor.isNew ? undefined : { visibility: 'hidden'}}/>
          <Box sx={styles.tableDataNameBox}>
            {getNameAndSrcId(unlinkedVendor, 'vendor')}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataArAmountCell}>
        <Typography tabIndex={0} sx={styles.tableDataTypography}>
          {unlinkedVendor.apAmount ?
            formatCurrency(unlinkedVendor.apAmount) :
            '-'}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableDataDateCell}>
        {formatDate(unlinkedVendor.createdAt!, AMERICAN_DATE_FORMAT)}
      </TableCell>
    </TableRow>
  )
}

export default VendorRow