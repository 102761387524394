import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { IARCustomer, IARVendor } from "../interfaces";
import { IParentWithChild, ISelectedParentAndChild } from "../interfaces/editParentChildInterface";

export type DroppedInParent =  IParentWithChild | 'new' | null;

export interface IEditParentChildContext {
  initialCustomers: IARCustomer[];
  setInitialCustomers: Dispatch<SetStateAction<IARCustomer[]>>;
  initialVendors: IARVendor[];
  setInitialVendors: Dispatch<SetStateAction<IARVendor[]>>;
  parentsList: IParentWithChild[];
  setParentsList: Dispatch<SetStateAction<IParentWithChild[]>>;
  filteredParentsList: IParentWithChild[];
  setFilteredParentsList: Dispatch<SetStateAction<IParentWithChild[]>>;
  orphansList: IARCustomer[];
  setOrphansList: Dispatch<SetStateAction<IARCustomer[]>>;
  filteredOrphansList: IARCustomer[];
  setFilteredOrphansList: Dispatch<SetStateAction<IARCustomer[]>>;
  isFetching: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  selectedParentAndChild: ISelectedParentAndChild | null;
  setSelectedParentAndChild: Dispatch<SetStateAction<ISelectedParentAndChild | null>>;
  vendorConfirmModalOpen: boolean;
  setVendorConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  lastChildConfirmModalOpen: boolean;
  setLastChildConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  childRemoveConfirmModalOpen: boolean;
  setChildRemoveConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  droppedInParent: DroppedInParent;
  setDroppedInParent: Dispatch<SetStateAction<DroppedInParent>>;
  triggerResetSearch: boolean;
  setTriggerResetSearch: Dispatch<SetStateAction<boolean>>;
  triggerResetSelection: boolean;
  setTriggerResetSelection: Dispatch<SetStateAction<boolean>>;
  selectedCustomers: IARCustomer[];
  setSelectedCustomers: Dispatch<SetStateAction<IARCustomer[]>>;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  createParentModalOpen: boolean;
  setCreateParentModalOpen: Dispatch<SetStateAction<boolean>>;
  deleteParentModalOpen: boolean;
  setDeleteParentModalOpen: Dispatch<SetStateAction<boolean>>;
  updatedCustomerList: IARCustomer[];
  setUpdatedCustomerList: Dispatch<SetStateAction<IARCustomer[]>>;
}

export const EditParentChildContext = createContext<IEditParentChildContext | null>(null);

const EditParentChildProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  // For checking initial values
  const [initialCustomers, setInitialCustomers] = useState<IARCustomer[]>([]);
  const [initialVendors, setInitialVendors] = useState<IARVendor[]>([]);

  // For common props
  const [parentsList, setParentsList] = useState<IParentWithChild[]>([]);
  const [filteredParentsList, setFilteredParentsList] = useState<IParentWithChild[]>([]);
  const [orphansList, setOrphansList] = useState<IARCustomer[]>([]);
  const [filteredOrphansList, setFilteredOrphansList] = useState<IARCustomer[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [selectedParentAndChild, setSelectedParentAndChild] = useState<ISelectedParentAndChild | null>(null);
  const [droppedInParent, setDroppedInParent] = useState<DroppedInParent>(null);
  const [vendorConfirmModalOpen, setVendorConfirmModalOpen] = useState<boolean>(false);
  const [lastChildConfirmModalOpen, setLastChildConfirmModalOpen] = useState<boolean>(false);
  const [childRemoveConfirmModalOpen, setChildRemoveConfirmModalOpen] = useState<boolean>(false);
  const [triggerResetSearch, setTriggerResetSearch] = useState<boolean>(false);
  const [triggerResetSelection, setTriggerResetSelection] = useState<boolean>(false);
  const [selectedCustomers, setSelectedCustomers] = useState<IARCustomer[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [createParentModalOpen, setCreateParentModalOpen] = useState<boolean>(false);
  const [deleteParentModalOpen, setDeleteParentModalOpen] = useState<boolean>(false);
  const [updatedCustomerList, setUpdatedCustomerList] = useState<IARCustomer[]>([]);

  const value = useMemo(() => ({
    initialCustomers, setInitialCustomers,
    initialVendors, setInitialVendors,
    parentsList, setParentsList,
    filteredParentsList, setFilteredParentsList,
    orphansList, setOrphansList,
    filteredOrphansList, setFilteredOrphansList,
    isFetching, setIsFetching,
    selectedParentAndChild, setSelectedParentAndChild,
    droppedInParent, setDroppedInParent,
    vendorConfirmModalOpen, setVendorConfirmModalOpen,
    lastChildConfirmModalOpen, setLastChildConfirmModalOpen,
    childRemoveConfirmModalOpen, setChildRemoveConfirmModalOpen,
    triggerResetSearch, setTriggerResetSearch,
    triggerResetSelection, setTriggerResetSelection,
    selectedCustomers, setSelectedCustomers,
    isDragging, setIsDragging,
    createParentModalOpen, setCreateParentModalOpen,
    deleteParentModalOpen, setDeleteParentModalOpen,
    updatedCustomerList, setUpdatedCustomerList,
  }), [
    initialCustomers, setInitialCustomers,
    initialVendors, setInitialVendors,
    parentsList, setParentsList,
    filteredParentsList, setFilteredParentsList,
    orphansList, setOrphansList,
    filteredOrphansList, setFilteredOrphansList,
    isFetching, setIsFetching,
    selectedParentAndChild, setSelectedParentAndChild,
    droppedInParent, setDroppedInParent,
    vendorConfirmModalOpen, setVendorConfirmModalOpen,
    lastChildConfirmModalOpen, setLastChildConfirmModalOpen,
    childRemoveConfirmModalOpen, setChildRemoveConfirmModalOpen,
    triggerResetSearch, setTriggerResetSearch,
    triggerResetSelection, setTriggerResetSelection,
    selectedCustomers, setSelectedCustomers,
    isDragging, setIsDragging,
    createParentModalOpen, setCreateParentModalOpen,
    deleteParentModalOpen, setDeleteParentModalOpen,
    updatedCustomerList, setUpdatedCustomerList,
  ]);

  return (
    <EditParentChildContext.Provider value={value}>
      {children}
    </EditParentChildContext.Provider>
  );
}

export default EditParentChildProvider;