import * as Contract from '@tableau/api-external-contract-js';
import {
  ExecuteParameters,
  ParameterId,
  QuantitativeIncludedValues,
  SelectOptions as SelectOptionsInternal,
  SelectionUpdateType as SelectionUpdateTypeInternal,
  VerbId,
  VisualId,
} from '@tableau/api-internal-contract-js';
import {
  DimensionSelectionModel,
  HierarchicalSelectionModel,
  HoverTupleInteractionModel,
  RangeSelectionModel,
  SelectTuplesInteractionModel,
  SelectionModelsContainer,
  TupleSelectionModel,
  ValueSelectionModel,
} from '../../Models/SelectionModels';
import { TableauError } from '../../TableauError';
import { Param } from '../../Utils/Param';
import { SelectionService } from '../SelectionService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class SelectionServiceImpl extends ServiceImplBase implements SelectionService {
  public get serviceName(): string {
    return ServiceNames.Selection;
  }

  /**
   * Method to clear all the selected marks for the given worksheet.
   *
   * @param visualId
   */
  public clearSelectedMarksAsync(visualId: VisualId): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'clearSelectedMarksAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.ClearSelectedMarks, parameters).then<void>((response) => {
      return; // Expecting an empty model and hence the void response.
    });
  }

  /**
   * Method to select marks for the given worksheet.
   *
   * @param visualId
   * @param selectionCriteria
   * @param selectionUpdateType
   */
  public selectMarksByValueAsync(
    visualId: VisualId,
    selectionCriterias: Array<Contract.SelectionCriteria>,
    selectionUpdateType: Contract.SelectionUpdateType,
  ): Promise<void> {
    if (selectionCriterias.length === 0) {
      throw new TableauError(Contract.ErrorCodes.InvalidParameter, 'Selection criteria missing for selecting marks by value');
    }

    const selectionType: string = this.validateSelectionUpdateType(selectionUpdateType);
    const selectionModelContainer: SelectionModelsContainer = this.parseSelectionMarks(selectionCriterias);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'selectMarksByValueAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.SelectionUpdateType]: selectionType,
    };

    if (selectionModelContainer.hierModelArr && selectionModelContainer.hierModelArr.length) {
      parameters[ParameterId.HierValSelectionModels] = selectionModelContainer.hierModelArr;
    }
    if (selectionModelContainer.quantModelArr && selectionModelContainer.quantModelArr.length) {
      parameters[ParameterId.QuantRangeSelectionModels] = selectionModelContainer.quantModelArr;
    }
    if (selectionModelContainer.dimModelArr && selectionModelContainer.dimModelArr.length) {
      parameters[ParameterId.DimValSelectionModels] = selectionModelContainer.dimModelArr;
    }

    return this.execute(VerbId.SelectByValue, parameters).then<void>((response) => {
      this.apiFilterHandlerCheckForCommandError(response.result as { [key: string]: string });
      return;
    });
  }

  /**
   * Method to execute hover actions and render tooltip for a given tuple representing a mark in the visualization.
   * If the tooltip parameter is included it will show the tooltip on hover. If not, no tooltip is shown.
   *
   * @param visualId
   * @param hoveredTuple
   * @param tooltip
   * @returns empty promise that resolves when the extension host has successfully been informed of the request and rejects on error
   */
  public hoverTupleAsync(visualId: VisualId, hoveredTuple: number, tooltip?: Contract.TooltipContext): Promise<void> {
    let interactionModel = new HoverTupleInteractionModel(visualId, hoveredTuple, tooltip);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'hoverTupleAsync',
      [ParameterId.HoverTupleInteraction]: interactionModel,
    };

    return this.execute(VerbId.RaiseHoverTupleNotification, parameters).then<void>((response) => {
      return;
    });
  }

  /**
   * Method to modify selection, execute select actions and render tooltip for a given list of tuples representing a mark or marks in the visualization.
   * If the tooltip parameter is included it will show the tooltip on select. If not, no tooltip is shown.
   *
   * @param visualId
   * @param selectedTuples
   * @param selectOption
   * @param tooltip
   * @returns empty promise that resolves when the extension host has successfully been informed of the request and rejects on error
   */
  public selectTuplesAsync(
    visualId: VisualId,
    selectedTuples: Array<number>,
    selectOption: Contract.SelectOptions,
    tooltip?: Contract.TooltipContext,
  ): Promise<void> {
    const option: string = this.validateSelectOption(selectOption);
    let interactionModel = new SelectTuplesInteractionModel(visualId, selectedTuples, option, tooltip);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'selectTuplesAsync',
      [ParameterId.SelectTuplesInteraction]: interactionModel,
    };

    return this.execute(VerbId.RaiseSelectTuplesNotification, parameters).then<void>((response) => {
      return;
    });
  }

  private apiFilterHandlerCheckForCommandError(serverPm: { [key: string]: string }) {
    if (!serverPm[ParameterId.ParameterError]) {
      return;
    }
    if (serverPm[ParameterId.InvalidFields]) {
      throw new TableauError(Contract.SharedErrorCodes.InvalidSelectionFieldName, serverPm[ParameterId.InvalidFields]);
    }
    if (serverPm[ParameterId.InvalidValues]) {
      throw new TableauError(Contract.SharedErrorCodes.InvalidSelectionValue, serverPm[ParameterId.InvalidValues]);
    }
    if (serverPm[ParameterId.InvalidDates]) {
      throw new TableauError(Contract.SharedErrorCodes.InvalidSelectionDate, serverPm[ParameterId.InvalidDates]);
    }
  }

  /**
   * Method to select marks for the given worksheet.
   *
   * @param visualId
   * @param MarkInfo
   * @param selectionUpdateType
   */
  public selectMarksByIdAsync(
    visualId: VisualId,
    marks: Array<Contract.MarkInfo>,
    selectionUpdateType: Contract.SelectionUpdateType,
  ): Promise<void> {
    if (marks.length === 0) {
      throw new TableauError(Contract.ErrorCodes.InvalidParameter, 'Marks info missing for selecting marks by Id');
    }

    const selectionType: string = this.validateSelectionUpdateType(selectionUpdateType);
    const selectionModelContainer: SelectionModelsContainer = this.parseSelectionIds(marks);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'selectMarksByIdAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.SelectionUpdateType]: selectionType,
      [ParameterId.Selection]: selectionModelContainer.selection,
    };
    return this.execute(VerbId.SelectByValue, parameters).then<void>((response) => {
      // Expecting an empty model and hence the void response.
      return;
      // TODO Investigate the error response with multiple output params and throw error accordingly.
    });
  }

  /**
   * Method to prepare the pres models for selection by MarksInfo
   * @param marks
   */
  private parseSelectionIds(marks: Array<Contract.MarkInfo>): SelectionModelsContainer {
    const ids: Array<string> = [];
    const selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();
    for (let i = 0; i < marks.length; i++) {
      const tupleId: number | undefined = marks[i].tupleId;
      if (tupleId !== undefined && tupleId !== null) {
        // If tuple id is provided use that instead of pair
        ids.push(tupleId.toString()); // collect the tuple ids
      } else {
        throw new TableauError(Contract.ErrorCodes.InternalError, 'tupleId parsing error');
      }
    }
    if (ids.length !== 0) {
      // tuple ids based selection
      const tupleSelectionModel: TupleSelectionModel = new TupleSelectionModel();
      tupleSelectionModel.selectionType = 'tuples';
      tupleSelectionModel.objectIds = ids;
      selectionModelContainer.selection = tupleSelectionModel;
    }
    return selectionModelContainer;
  }

  /**
   * Method to prepare the pres models for selection by values.
   *
   * Supports 3 types for selection:
   * 1) Hierarchical value based selection
   * 2) Range value based selection
   * 3) Dimension value based selection
   *
   * @param marks
   */
  private parseSelectionMarks(selectionCriterias: Array<Contract.SelectionCriteria>): SelectionModelsContainer {
    const selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();

    for (let i = 0; i < selectionCriterias.length; i++) {
      const st = selectionCriterias[i];
      if (!(st.fieldName && st.value !== undefined && st.value !== null)) {
        throw new TableauError(Contract.ErrorCodes.InternalError, 'Selection Criteria parsing error');
      }

      const catRegex = new RegExp('([[A-Za-z0-9]+]).*', 'g');
      const rangeOption = st.value as Contract.RangeValue;
      if (catRegex.test(st.fieldName)) {
        // Hierarchical value selection
        const hierModel: HierarchicalSelectionModel = this.addToParamsList(st.fieldName, st.value) as HierarchicalSelectionModel;
        selectionModelContainer.hierModelArr.push(hierModel);
      } else if (rangeOption.min !== undefined && rangeOption.max !== undefined) {
        // Range value selection
        const quantModel: RangeSelectionModel = this.addToRangeParamsList(st.fieldName, rangeOption);
        selectionModelContainer.quantModelArr.push(quantModel);
      } else {
        // Dimension value selection
        const dimModel: DimensionSelectionModel = this.addToParamsList(st.fieldName, st.value) as DimensionSelectionModel;
        selectionModelContainer.dimModelArr.push(dimModel);
      }
    }

    return selectionModelContainer;
  }

  /**
   * Method to transform the key value pair into value based pres model object.
   *
   * @param valueSelectionModel
   * @param fieldName
   * @param value
   */
  private addToParamsList(fieldName: string, value: Contract.CategoricalValue | Contract.RangeValue): ValueSelectionModel {
    const valueSelectionModel: ValueSelectionModel = new ValueSelectionModel();
    const markValues: Array<string> = [];

    if (value instanceof Array) {
      const valueArr: Array<string> = value;
      for (let i = 0; i < valueArr.length; i++) {
        markValues.push(Param.serializeParameterValue(valueArr[i]));
      }
    } else {
      markValues.push(Param.serializeParameterValue(value));
    }

    valueSelectionModel.qualifiedFieldCaption = fieldName;
    valueSelectionModel.selectValues = markValues;
    return valueSelectionModel;
  }

  /**
   * Method to transform the key value pair into range based selection pres model.
   *
   * TODO: Need to handle the parsing of date type values.
   *
   * @param valueSelectionModel
   * @param fieldName
   * @param value
   */
  private addToRangeParamsList(fieldName: string, value: Contract.RangeValue): RangeSelectionModel {
    const rangeSelectionModel: RangeSelectionModel = new RangeSelectionModel();
    rangeSelectionModel.qualifiedFieldCaption = fieldName;
    if (value.max !== undefined && value.max !== null) {
      rangeSelectionModel.maxValue = Param.serializeParameterValue(value.max);
    }
    if (value.min !== undefined && value.min !== null) {
      rangeSelectionModel.minValue = Param.serializeParameterValue(value.min);
    }
    rangeSelectionModel.included = this.validateNullOptionType(value.nullOption);
    return rangeSelectionModel;
  }

  /**
   * Method to validate the selection update type.
   *
   * @param selectionUpdateType
   */
  private validateSelectionUpdateType(selectionUpdateType: Contract.SelectionUpdateType): string {
    if (selectionUpdateType === Contract.SelectionUpdateType.Replace) {
      return SelectionUpdateTypeInternal.Replace;
    } else if (selectionUpdateType === Contract.SelectionUpdateType.Add) {
      return SelectionUpdateTypeInternal.Add;
    } else if (selectionUpdateType === Contract.SelectionUpdateType.Remove) {
      return SelectionUpdateTypeInternal.Remove;
    }
    return SelectionUpdateTypeInternal.Replace;
  }

  /**
   * Method to validate the include type for range selection.
   *
   * @param nullOption
   */
  private validateNullOptionType(nullOption: Contract.FilterNullOption | undefined): string {
    if (nullOption) {
      if (nullOption === Contract.FilterNullOption.NullValues) {
        return QuantitativeIncludedValues.IncludeNull;
      } else if (nullOption === Contract.FilterNullOption.NonNullValues) {
        return QuantitativeIncludedValues.IncludeNonNull;
      } else if (nullOption === Contract.FilterNullOption.AllValues) {
        return QuantitativeIncludedValues.IncludeAll;
      }
    }

    return QuantitativeIncludedValues.IncludeAll;
  }

  /**
   * Method to validate the select option.
   *
   * @param selectOption
   */
  private validateSelectOption(selectOption: Contract.SelectOptions): string {
    if (selectOption === Contract.SelectOptions.Simple) {
      return SelectOptionsInternal.Simple;
    } else if (selectOption === Contract.SelectOptions.Toggle) {
      return SelectOptionsInternal.Toggle;
    } else {
      throw new TableauError(Contract.ErrorCodes.InvalidParameter, 'Only simple and toggle select options are currently supported');
    }
  }
}
