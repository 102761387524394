const styles = {
  tableDataDragAndActionCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
  },
  tableDataNameCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'left',
    paddingX: 0,
  },
  tableDataNameBox: {
    display: 'flex', 
    flexDirection: 'column',
    marginLeft: '0.3rem',
  },
  tableDataArAmountCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'right',
  },
  tableDataTypography: {
    fontSize: '14px',
    color: 'inherit', 
  },
  nameAndTagContainer: {
    display: 'flex',
    alignItems: 'center'
  },
}
export default styles;