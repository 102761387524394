import { AlertColor, Box, IconButton, ListItem, ListItemText, Tooltip, Typography, } from "@mui/material"
import styles from "../styles";
import { IARCustomer, IARVendor } from "../../../../interfaces";
import PreviewIcon from '@mui/icons-material/Preview';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useState } from "react";
import { IParentCustomer, IToasterProps } from "../..";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import NewTag from "../../../../components/common/new-tag";
import CustomerVendorModal from "../../customer-vendor-modal";
import customerSchema from "../../../../schemas/customerSchema";
import { FormikHelpers } from "formik";
import axiosInstance from "../../../../service/axiosInstance";
import { arCustomersAPI } from "../../../../service/api";
import { PUT } from "../../../../utility/constants";

export interface IProps extends IToasterProps {
  item: IARCustomer;
  index: number;
  arCustomerList: IARCustomer[];
  setParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
  filteredParentCustomer?: IParentCustomer[];
  setFilteredParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
  getAllCustomers: () => void;
}

/**
 * This component render a Customer/Child Row component.
 * 
 * @param param IProps
 */
const CustomerRow: FC<IProps> = (props: IProps) => {
  const {
    canViewParentInformation,
    setToaster
  }                                  = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const {selectedClient}             = useContext(SelectedClientContext);
  const [open, setOpen]              = useState<boolean>(false);
  const [isEditting, setIsEditting]  = useState<boolean>(false);


  /**
   * This useCallback set the modal state to open.
   */
  const handleOpenModal   = useCallback(() => setOpen(true), []);

  const handleSaveCustomer = async (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    try {
      await axiosInstance.request({
        url: `${arCustomersAPI.MAIN_ENDPOINT}/${values.recordId}`,
        method: PUT,
        data: { ...values, isNew: false }
      });
      setToaster({ open: true, message: 'Changes saved', severity: 'success' });
    } catch (error) {
      console.log('SAVING ERROR: ', error);
      setToaster({ open: true, message: 'Error in saving customer!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
      handleClose();
      props.getAllCustomers();
    }
  }

  /**
   * This function checks if the component should have a dark or light background color.
   * 
   * @param index The index of the component
   * @returns A boolean that determine if the component's index is odd or not.
   */  
  const isOdd = (index: number) => index%2!==0;

  const updatedList = useMemo(() => {
    const removedSelf = props.arCustomerList.filter(value => value.recordId !== props.item.recordId);
    return removedSelf;
  }, [props.arCustomerList, props.item])

  const handleClose = () => {
    setOpen(false);
    setIsEditting(false);
  }

  return (
    <>
      <ListItem
        sx={{ bgcolor: isOdd(props.index) ? 'background.paper' : '#F7F7F7' }}
        key={props.index}
      >
          <Box>
            <Box sx={styles.newTagBox}>
              { props.item.isNew && (
                <Typography><NewTag expanding /></Typography>
              )}
            </Box>
          </Box>
          <Box sx={{...styles.halfWidth, pl: '1.55rem'}}>
            <ListItemText tabIndex={0} id={`${props.item.recordId}`} primary={props.item.custName}/>
          </Box>
          <Box tabIndex={0} sx={styles.halfWidth}>
            { props.item?.custSrcId ?? '-'}
          </Box>
          <Box sx={{...styles.smallWidth, ...(!canViewParentInformation && styles.invisible)}}>
            <Tooltip title='View Customer Details'>
              <IconButton size='small' onClick={handleOpenModal}>
                <PreviewIcon sx={styles.iconAction} />
              </IconButton>
            </Tooltip>
          </Box>
      </ListItem>
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={props.item}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveCustomer}
        onClose={handleClose}
        schema={customerSchema(updatedList, false, selectedClient?.parentClient)}
      />
    </>
  )
}

export default CustomerRow