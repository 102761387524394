import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { ActionModalProps, ActionPanelProps, IHeadCell, IToasterProps } from "..";
import { Box, Checkbox, Chip, CircularProgress, IconButton, Skeleton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { IARCustomer, IARVendor } from "../../../interfaces";
import PreviewIcon from '@mui/icons-material/Preview';
import styles from "./styles";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";
import { StyledTableCell } from "../../../components/skeleton";
import { formatDate, getUniqueKeys } from "../../../utility/helper";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import NewTag from "../../../components/common/new-tag";
import { DeleteOutlined, HistoryOutlined, Inventory2Outlined } from "@mui/icons-material";
import CustomerVendorModal from "../customer-vendor-modal";
import customerSchema from "../../../schemas/customerSchema";
import { FormikHelpers } from "formik";
import axiosInstance from "../../../service/axiosInstance";
import { arCustomersAPI, arVendorsAPI } from "../../../service/api";
import { AMERICAN_DATE_FORMAT, GET, PUT } from "../../../utility/constants";
import vendorSchema from "../../../schemas/vendorSchema";
import { IOption } from "../../../interfaces/comboBox";
export interface IMainRowProps extends IToasterProps {
  index               : number;
  type                : string;
  dataInfo            : IARCustomer | IARVendor;
  dataList            : Array<IARCustomer | IARVendor>;
  setDataList         : Dispatch<Array<IARCustomer | IARVendor>>;
  tableLabel          : string;
  fetchData           : () => void;
  headers             : IHeadCell[];
  setActionModal      : Dispatch<SetStateAction<ActionModalProps>>;
  actionPanel         : ActionPanelProps;
  setActionPanel      : Dispatch<SetStateAction<ActionPanelProps>>;
  archive             : boolean;
  selectedARCollateral: IOption | null;
}

/**
 * This component is used to display each customer or vendor on a row component.
 * 
 * @param props ICustSettingsRowProps
 * @returns A Row Component that display the customer's primary details.
 */
const MainRow: FC<IMainRowProps> = (props) => {
  const { 
    index,
    dataInfo, 
    dataList, 
    setDataList,
    type,
    archive,
    tableLabel,
    setActionModal,
    actionPanel,
    setActionPanel,
    selectedARCollateral
  }                                           = props;
  const {
    canViewCustomerInformation,
    setToaster
  }                                           = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const { selectedClient }                    = useContext(SelectedClientContext);
  const [rowCells, setRowCells]               = useState<string[]>([]);
  const [open, setOpen]                       = useState<boolean>(false);
  const [isEditting, setIsEditting]           = useState<boolean>(false);
  const [isLoading, setIsLoading]             = useState<boolean>(false);
  const [fullList, setFullList]               = useState<IARCustomer[] | IARVendor[]>([]);

  /**
   * This useEffect is used to set the row value.
   */
  useEffect(() => {
    const rowCells = props.headers.map((header) => {
      if (header.type === 'string') {
        return dataInfo[header.id] ?? '-';
      }
      else if (header.type === 'date') {
        return dataInfo[header.id] ? formatDate(dataInfo[header.id], AMERICAN_DATE_FORMAT) : '-';
      }
      else if (header.type === 'number') {
        return dataInfo[header.id] ? dataInfo[header.id].toString(): '-';
      }
      else {
        return '-';
      }
    });
  
    setRowCells(rowCells);
  }, [dataInfo]);

  /**
   * This component generates a tooltip if the custName is truncated.
   * 
   * @param custName The name of the customer.
   * @returns A Typhography component, with tooltip if the custName is truncated.
   */
  const  getCustName = (custName: string, parentTag?: React.ReactNode) => {
    if (custName.length >= 30) {
      return (
        <Tooltip title={`${custName}`}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={styles.custName}>{custName}</Typography>
            {parentTag}
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <Typography sx={styles.custName}>{custName}</Typography>
          {parentTag}
        </Box>
      );
    }
  };

  /**
   * This function is used to determine if the customer is a parent
   */
  const CheckIfParent = () => {
    const field = selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId';
    const customerList = props.dataList as IARCustomer[];
    const custInfo = props.dataInfo as IARCustomer;
    return customerList.some(cust => cust[field] === custInfo.custSrcId)
  };

  /**
   * This function is used to determine if row is a parent customer
   */
  const checkIfParentCustomer = (data: IARCustomer | IARVendor) => {
    if ('isUpcParent' in data || 'isCustParent' in data) {
      const custInfo = data as IARCustomer;
      return custInfo.isCustParent || custInfo.isUpcParent;
    }

    return false;
  }

  const updatedCustomerList = useMemo(() => {
    const removedSelf = (fullList as IARCustomer[]).filter((value: IARCustomer) => value.recordId !== dataInfo.recordId);
    return removedSelf;
  }, [fullList, dataInfo])

  const updatedVendorList = useMemo(() => {
    const removedSelf = (fullList as IARVendor[]).filter((value: IARVendor) => value.recordId !== dataInfo.recordId);
    return removedSelf;
  }, [fullList, dataInfo])

  const handleOpenModal = async () => {
    try {
      setIsLoading(true);

      const list = type === 'customer' ?
        await fetchCustomers(selectedClient?.recordId, selectedARCollateral?.recordId) :
        await fetchVendors(selectedClient?.recordId, selectedARCollateral?.recordId);
        
      setFullList(list);
      setOpen(true);
    } catch (error) {
      console.log('ERROR FETCHING FULL LIST: ', error);
      setToaster({ open: true, message: 'Failed to fetch list!', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const fetchCustomers = async (borrowerId?: number, arCollateralId?: number) => {
    if (selectedClient?.parentClient) {
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const customers: IARCustomer[] = custResponse.data.content;
      return customers;
    } else {
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const customers: IARCustomer[] = custResponse.data.content;
      return customers;
    }
  }

  const fetchVendors = async (borrowerId?: number, arCollateralId?: number) => {
    if (selectedClient?.parentClient) {
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      return vendors;
    } else {
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      return vendors;
    }
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditting(false);
  }

  const handleSaveCustomer = async (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    try {
      await axiosInstance.request({
        url: `${arCustomersAPI.MAIN_ENDPOINT}/${values.recordId}`,
        method: PUT,
        data: { ...values, isNew: false }
      });
      setToaster({ open: true, message: 'Changes saved', severity: 'success' });
    } catch (error) {
      console.log('SAVING ERROR: ', error);
      setToaster({ open: true, message: 'Error in saving customer!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
      handleClose();
      props.fetchData();
    }
  }

  const handleSaveVendor = async (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    try {
      await axiosInstance.request({
        url: `${arVendorsAPI.MAIN_ENDPOINT}/${values.recordId}`,
        method: PUT,
        data: { ...values, isNew: false }
      });
      setToaster({ open: true, message: 'Changes saved', severity: 'success' });
    } catch (error) {
      console.log('SAVING ERROR: ', error);
      setToaster({ open: true, message: 'Error in saving vendor!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
      handleClose();
      props.fetchData();
    }
  }

  const renderModal = (type: string) => (
    type === 'customer' ? (
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={props.dataInfo}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveCustomer}
        onClose={handleClose}
        schema={customerSchema(updatedCustomerList, false, selectedClient?.parentClient)}
      />
    ) : (
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={props.dataInfo}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveVendor}
        onClose={handleClose}
        schema={vendorSchema(updatedVendorList)}
      />
    )
  );

  const renderDeleteArchive = (canDelete: number, archive: boolean) => {
    if (archive) {
      return (
        <Tooltip title={actionPanel.open ? `Restore action is disabled when selecting ${type}/s` : `Restore ${tableLabel}`}>
          <span>
          <IconButton
            disabled={actionPanel.open}
            aria-label={`Restore ${tableLabel}`} 
            data-testid={`restore-${dataInfo.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'restore',
                open: true,
                title: `Restore ${tableLabel}`,
                description: `You are about to restore this ${type}. Are you sure?`,
                yesButtonText: 'Restore',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <HistoryOutlined />
          </IconButton>
          </span>
        </Tooltip>
      )
    }

    return (
      <>
      {isLoading ? 
        <Box sx={styles.isLoading}>
          <CircularProgress size={15} />
        </Box>
        :
        <Tooltip title={actionPanel.open ? `View action is disabled when selecting ${type}/s` : `View ${tableLabel} Details`}>
          <IconButton
            disabled={actionPanel.open}
            aria-label={`View ${tableLabel} details icon`}
            color='primary'
            size='small'
            onClick={handleOpenModal}
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>}
      {canDelete === 1 ? (
        <Tooltip title={actionPanel.open ? `Delete action is disabled when selecting ${type}/s` : `Delete ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open}
            aria-label={`Delete ${tableLabel}`}
            color='primary'
            size='small'
            data-testid={`delete-${dataInfo.recordId}`}
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'delete',
                open: true,
                title: `Delete ${tableLabel}`,
                description: `You are about to delete this ${type}. Are you sure?`,
                yesButtonText: 'Delete',
                noButtonText: 'Cancel',
                errorButton: true,
              });
            }}
          >
            <DeleteOutlined />
          </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title={actionPanel.open ? `Archive action is disabled when selecting ${type}/s` : `Archive ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open}
            aria-label={`Archive ${tableLabel}`} 
            data-testid={`archive-${dataInfo.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'archive',
                open: true,
                title: `Archive ${tableLabel}`,
                description: `You are about to archive this ${type}. Are you sure?`,
                yesButtonText: 'Archive',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <Inventory2Outlined />
          </IconButton>
          </span>
        </Tooltip>
      )}
      </>
    )
  }

  return (
    <>
      {rowCells[0] === undefined ?
      <TableRow>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
      </TableRow>
      : <TableRow sx={styles.tableRow}>
        <TableCell sx={styles.tableCellNewTag}>
          <Box sx={styles.newTagBox}>
            { props.dataInfo?.isNew && <Typography><NewTag expanding /></Typography> }
          </Box>
        </TableCell>
        <TableCell sx={styles.tableCellNewTag}>
          <Box sx={styles.newTagBox}>
            <Checkbox 
              data-testid={`select-${dataInfo.recordId}`}
              checked={dataInfo.selected}
              onClick={() => {
                const newDataList = [
                  ...dataList
                ];

                const findIndex = newDataList.findIndex((data) => data.recordId === dataInfo.recordId);

                newDataList[findIndex].selected = !newDataList[findIndex].selected;
                setDataList(newDataList);

                const selectedList = newDataList
                  .filter((data) => data.selected);

                setActionPanel({
                  restore: archive,
                  open: selectedList.length > 0,
                });
              }}
            />
          </Box>
        </TableCell>
        {rowCells.map((rowHeader, index) =>
          <TableCell 
            width={'20%'} 
            tabIndex={0} 
            key={getUniqueKeys(rowHeader)} 
            onClick={handleOpenModal} 
            sx={{
              ...styles.tableCell,
            }}
            >
            {getCustName(rowHeader, (index === 0 && checkIfParentCustomer(dataInfo) && <Chip size='small' sx={styles.parentIdentifierChip} />))}
          </TableCell>
        )}
        {
          canViewCustomerInformation && 
          <TableCell
            sx={{
              ...styles.tableCell,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            width={'10%'}>
            { renderDeleteArchive(dataInfo.canDelete!, archive) }
          </TableCell>
        }
      </TableRow>
      }
      { renderModal(type) }      
    </>
  );
};
export default MainRow;