import { Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { IARCustomer } from "../../../../../interfaces";
import { formatCurrency, formatDate, isOddNumber } from "../../../../../utility/helper";
import styles from "./styles";
import { DragIndicator } from "@mui/icons-material";
import { getCustNameAndCustSrcId } from "../..";
import { AMERICAN_DATE_FORMAT } from "../../../../../utility/constants";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import NewTag from "../../../../../components/common/new-tag";
import { OrphansSectionProps } from "..";
import { EditParentChildContext, IEditParentChildContext } from "../../../../../context/editParentChildContext";

export interface OrphanRowProps extends OrphansSectionProps {
  index: number;
  orphanCustomer: IARCustomer;
  isSelectedAll: boolean;
  setIsSelectedAll: Dispatch<SetStateAction<boolean>>;
  triggerSelectAll: boolean;
  lastRow: boolean;
  lastRowElementRef: (node: any) => void;
}

const OrphanRow = (props: OrphanRowProps) => {
  const {
    index,
    orphanCustomer,
    isSelectedAll,
    setIsSelectedAll,
    triggerSelectAll,
    lastRow,
    lastRowElementRef,
  } = props;

  // Contexts
  const {
    filteredOrphansList,
    selectedParentAndChild,
    setSelectedParentAndChild,
    vendorConfirmModalOpen,
    lastChildConfirmModalOpen,
    setLastChildConfirmModalOpen,
    childRemoveConfirmModalOpen,
    setChildRemoveConfirmModalOpen,
    createParentModalOpen,
    selectedCustomers,
    setSelectedCustomers,
    triggerResetSearch,
    triggerResetSelection,
    isDragging,
    setIsDragging,
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  // States
  const [isSelected, setIsSelected] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIsSelected(isSelectedAll);
  }, [triggerSelectAll]);

  useEffect(() => {
    setIsSelected(false);
  }, [triggerResetSearch, triggerResetSelection]);

  // Functions
  const handleOnDrop = () => {
    if (selectedParentAndChild === null) return;
    const { selectedParent } = selectedParentAndChild;

    if (selectedParent !== null) {
      if (selectedParent.childCustomers.length < 2) {
        setLastChildConfirmModalOpen(true);
      } else {
        setChildRemoveConfirmModalOpen(true);
      }
    } else {
      return;
    }
  }

  const handleSelection = (addOnly?: boolean) => {
    const existingIndex = selectedCustomers.findIndex(
      customer => customer.custSrcId === orphanCustomer.custSrcId
    );
    const isAlreadySelected = existingIndex !== -1;
  
    if ((addOnly || !isSelected) && !isAlreadySelected) {
      const newSelected = [...selectedCustomers, orphanCustomer];
      setSelectedCustomers(newSelected);
      return newSelected;
    }
  
    if (!addOnly && isSelected && isAlreadySelected) {
      const updatedCustomers = selectedCustomers.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedCustomers(updatedCustomers);
      return updatedCustomers;
    }
  
    return selectedCustomers;
  };

  // Rendering the component
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        bgcolor: isOddNumber(index) ?
          'background.paper' :
          '#F7F7F7'
      }}
      onDragOver={(event) => {
        // Prevent ghost image to bounce back
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleOnDrop();
      }}
      data-testid={`orphan-list-item-${index}`}
      ref={lastRow ? lastRowElementRef : undefined}
    >
      <TableCell sx={styles.tableDataDragAndActionCell}>
        <Box sx={styles.tableDataDragContainer}>
          <Tooltip
            title='Drag and drop to associate with a parent'
            PopperProps={{
              sx: isDragging ? styles.noTooltip : null
            }}
          >
            <IconButton
              draggable
              edge='end'
              data-testid={`orphan-drag-icon-${index}`}
              aria-label='Drag and drop icon'
              onDragStart={(event) => {
                setIsDragging(true);
                setIsSelected(true);

                // On drag start, the current orphan if not previously selected,
                // will not be present in the selectedCustomers
                const newSelectedCustomers = handleSelection(true);
                setIsSelectedAll(newSelectedCustomers.length === filteredOrphansList.length);

                setSelectedParentAndChild({
                  selectedParent: null,
                  selectedChildren: newSelectedCustomers,
                });

                // Create ghost images
                const dragGhostContainer = document.createElement('div');
                Object.assign(dragGhostContainer.style, styles.ghostImageContainer);

                // Display first 3 images
                const maxVisible = 3;
                newSelectedCustomers
                  .slice(0, maxVisible)
                  .forEach((customer) => {
                    const dragGhost = document.createElement('div');
                    dragGhost.textContent = customer.custName!;
                    Object.assign(dragGhost.style, styles.ghostImage);
                    dragGhostContainer.appendChild(dragGhost);
                  });
                
                // Add `n more customers` ghost image
                const remainingCount = newSelectedCustomers.length - maxVisible;
                if (remainingCount > 0) {
                  const moreGhost = document.createElement('div');
                  moreGhost.textContent = `+${remainingCount} more customers`;
                  Object.assign(moreGhost.style, styles.ghostImage);
                  dragGhostContainer.appendChild(moreGhost);
                }
                
                document.body.appendChild(dragGhostContainer);

                event.dataTransfer.setDragImage(dragGhostContainer, -20, 25);

                setTimeout(() => {
                  document.body.removeChild(dragGhostContainer);
                }, 0);
              }}
              onDragEnd={() => {
                setIsDragging(false);
                if (!(
                  vendorConfirmModalOpen ||
                  lastChildConfirmModalOpen ||
                  childRemoveConfirmModalOpen ||
                  createParentModalOpen
                )) {
                  setSelectedParentAndChild(null);
                }
              }}
              sx={styles.dragButton} 
            >
              <DragIndicator/>
            </IconButton>
          </Tooltip>
          <Checkbox
            id='checkbox'
            data-testid='select-orphan-checkbox'
            aria-label='Checkbox'
            aria-labelledby='checkbox'
            edge='end'
            inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
            checked={isSelected}
            disableRipple
            onClick={() => {
              setIsSelected(!isSelected);
              const newSelectedCustomers = handleSelection();
              setIsSelectedAll(newSelectedCustomers.length === filteredOrphansList.length);
            }}
            sx={styles.checkbox}
          />
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataNameCell}>
        <Box sx={styles.nameAndTagContainer}>
          <NewTag additionalStyles={orphanCustomer.isNew ? undefined : { visibility: 'hidden'}}/>
          <Box sx={styles.tableDataNameBox}>
            {getCustNameAndCustSrcId(orphanCustomer)}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableDataArAmountCell}>
        <Typography tabIndex={0} sx={styles.tableDataTypography}>
          {orphanCustomer.arAmount ?
            formatCurrency(orphanCustomer.arAmount) :
            '-'}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableDataDateCell}>
        {formatDate(orphanCustomer.createdAt!, AMERICAN_DATE_FORMAT)}
      </TableCell>
    </TableRow>
  )
}

export default OrphanRow;