import { useCallback } from "react";
import { IParentWithChild, ISelectedParentAndChild } from "../../interfaces/editParentChildInterface";
import { IARCustomer } from "../../interfaces";

const useManageParentChild = () => {
  const replaceChild = useCallback(
    (
      list: IParentWithChild[],
      newParent: IParentWithChild,
      selectedParentAndChild: ISelectedParentAndChild
    ) => {
      const { selectedParent, selectedChildren } = selectedParentAndChild;
      const selectedChildrenRecordIds = selectedChildren.map(
        (child) => child.recordId
      );

      return list.map((parent) => {
        if (parent.custSrcId === selectedParent?.custSrcId) {
          return {
            ...parent,
            childCustomers: parent.childCustomers.filter(
              (child) => !selectedChildrenRecordIds.includes(child.recordId)
            ),
          };
        }

        if (parent.custSrcId === newParent.custSrcId) {
          return {
            ...parent,
            childCustomers: [...parent.childCustomers, ...selectedChildren],
          };
        }

        return parent;
      });
    },
    []
  );

  const removeChild = useCallback(
    (
      list: IParentWithChild[],
      selectedParentAndChild: ISelectedParentAndChild
    ) => {
      const { selectedParent, selectedChildren } = selectedParentAndChild;
      const selectedChildrenRecordIds = selectedChildren.map(
        (child) => child.recordId
      );

      return list.map((parent) => {
        if (parent.custSrcId === selectedParent?.custSrcId) {
          return {
            ...parent,
            childCustomers: parent.childCustomers.filter(
              (child) => !selectedChildrenRecordIds.includes(child.recordId)
            ),
          };
        }

        return parent;
      });
    },
    []
  );

  const addChild = useCallback(
    (
      list: IParentWithChild[],
      newParent: IParentWithChild,
      selectedParentAndChild: ISelectedParentAndChild
    ) => {
      const { selectedChildren } = selectedParentAndChild;

      return list.map((parent) => {
        if (parent.custSrcId === newParent.custSrcId) {
          return {
            ...parent,
            childCustomers: [...parent.childCustomers, ...selectedChildren],
          };
        }

        return parent;
      });
    },
    []
  );

  const removeOrphans = useCallback(
    (
      list: IARCustomer[],
      selectedParentAndChild: ISelectedParentAndChild
    ) => {
      const { selectedChildren } = selectedParentAndChild;
      const selectedChildrenRecordIds = selectedChildren.map(
        (child) => child.recordId
      );

      return list.filter(
        (orphan) => !selectedChildrenRecordIds.includes(orphan.recordId)
      );
    },
    []
  );

  const renameParent = useCallback(
    (
      list: IParentWithChild[],
      renamedParent: IParentWithChild,
      selectedParent: IParentWithChild | null
    ) => {
      return list.map((parent) => {
        if (parent.custSrcId === selectedParent?.custSrcId) {
          return {
            ...parent,
            custName: renamedParent.custName,
            childCustomers: parent.childCustomers.map((child) => ({
              ...child,
              upcParentCustName: renamedParent.custName,
            })),
          };
        }
        return parent;
      });
    },
    []
  );

  const clearNewCustomers = useCallback(
    (
      list: IParentWithChild[],
    ) => {
      return list.map((parent) => ({
        ...parent,
        childCustomers: parent.childCustomers.map((child) => ({
          ...child,
          isNew: false,
        })),
      }));
    },
    []
  );

  return {
    replaceChild,
    removeChild,
    addChild,
    removeOrphans,
    renameParent,
    clearNewCustomers
  };
};

export default useManageParentChild;