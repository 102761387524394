import colors from "../../../../../utility/colors";

const styles = {
  parentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5ch'
  },
  custName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '37ch'
  },
  custSrcId: {
    fontSize: '12px',
    color: colors.SECONDARY_TEXT
  },
  parentRow: {
    cursor: 'pointer',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    }
  },
  parentSelectAndActionCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
  },
  parentNameCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'left',
    paddingX: 0,
  },
  parentArAmountCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'right',
  },
  arrowContainer: {
    display: 'flex',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  childrenHeaderSelectAndActionCell: {
    borderBottom: 'none',
    backgroundColor: colors.NEUTRAL_LIGHT,
    textAlign: 'center',
  },
  childrenHeaderNameCell: {
    textAlign: 'left',
    borderBottom: 'none',
    backgroundColor: colors.NEUTRAL_LIGHT,
    paddingX: 0,
    paddingLeft: '2.2rem',
  },
  childrenHeaderArAmountCell: {
    borderBottom: 'none',
    textAlign: 'right',
    backgroundColor: colors.NEUTRAL_LIGHT,
  },
  childrenHeaderTypography: {
    fontWeight: 'normal',
    fontSize: '14px',
    whiteSpace: 'nowrap'
  },
  nameAndTagContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  tableDataNameBox: {
    display: 'flex', 
    flexDirection: 'column',
    marginLeft: '0.3rem',
  },
  parentIdentifierChip: {
    marginLeft: '0.5rem',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  parentIdentifierChipHidden: {
    visibility: 'hidden',
    marginLeft: '0.5rem',
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
  },
  dropSectionCell: {
    padding: 0,
    borderBottom: 0,
  },
  dropSection: {
    my: '10px', 
    display: 'flex', 
    justifyContent: 'center', 
    py: '10px'
  },
  dropSectionActive: {
    border: `2px dashed ${colors.PRIMARY}`,
    color: colors.PRIMARY,
  },
  dropSectionVerbiage: {
    fontSize: '0.9rem'
  }
}
export default styles;